import React, { useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { RetakePromptsWrapper, formTimeString } from "mixins/helperVideoRecording";

import { AnswerRetake, WallClock } from "mixins/svgIcons";
import { FaRedo } from "react-icons/fa";
import "./styles.scss";
import { getErrorMessage } from "store/modules/сandidates/selectors";
import { store } from "store";
import Cookies from "universal-cookie";
import { getIsStorageSupported } from "mixins/helperCandidate";

const ButtonsCaption = ({
  t,
  initialElapsingTime,
  cashedElapsingTime,
  max_retakes: maxRetakes,
  attempt_number: attemptNumber
}) => {
  const isRetakesButtonHidden = maxRetakes === 0 || maxRetakes === null;
  const attempts = useMemo(() => {
    if (maxRetakes === 0 || maxRetakes === attemptNumber) return 1;

    if (maxRetakes === null) return null;

    return (maxRetakes - attemptNumber) + 1;
  }, [maxRetakes, attemptNumber]);

  const retakes = attemptNumber > 1 ? attemptNumber - 1 : 0;
  const shouldShowNoRetakes = (maxRetakes === 0 || attempts < 1) && maxRetakes !== null;

  const errorMessage = getErrorMessage(store.getState());
  const cookies = new Cookies();

  const hasRefreshed = JSON.parse(
    getIsStorageSupported() ? window.sessionStorage.getItem("isRetriedAndReloaded") : "false" || "false"
  );

  const message = {
    subject: t("errors.errorFallback.email.subject", { error: "Error: Failed to save answer" }),
    text: t("errors.errorFallback.email.body")
  };

  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (getIsStorageSupported() && errorMessage?.length) {
        window.sessionStorage.setItem("isRetriedAndReloaded", true);
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [cookies, errorMessage]);

  return (
    <>
      <p className="button__caption">
        <span className="text-align-left col-6">
          <WallClock svgIconClass="question-create__icon" />

          <span>
            {cashedElapsingTime
            ? `${t("candidate.videoCreate.answerLength")}
                 ${formTimeString(initialElapsingTime - cashedElapsingTime)}`
            : `${t("candidate.videoCreate.answerTime")}
                 ${formTimeString(initialElapsingTime)}`}
          </span>
        </span>
        <span className="text-align-right attempts-text col-6">
          <RetakePromptsWrapper
            fallbackComponent={(
              <>
                {(!isRetakesButtonHidden && (
                <>
                  <i className="fa fa-repeat x-lg mr4 ml4 grey">
                    <FaRedo />
                  </i>
                  <span>
                    {t("candidate.videoCreate.answerRetake", {
                      value: retakes,
                      total: maxRetakes
                    })}
                  </span>
                </>
              )) ||
              (maxRetakes === 0 && (
                <>
                  <i className="fa fa-repeat x-lg mr4 ml4 grey">
                    <FaRedo />
                  </i>
                  <span>{t("candidate.videoCreate.noRetakes")}</span>
                </>
              ))}
              </>
          )}
          >
            {(!isRetakesButtonHidden && attempts >= 1 && (
            <>
              <AnswerRetake fill={attempts > 1 ? "#212B46" : "#D11F00"} />
              <span style={attempts <= 1 ? { color: "#D11F00", marginLeft: 4 } : {}}>
                {t(`candidate.videoCreate.${attempts > 1 ? "answerAttempts" : "finalAnswerAttempt"}`, {
                  value: attempts,
                  total: maxRetakes >= 1 ? maxRetakes + 1 : maxRetakes
                })}
              </span>
            </>
          )) ||
          (shouldShowNoRetakes && (
            <>
              <i className="fa fa-repeat x-lg mr4 ml4 grey">
                <FaRedo />
              </i>
              <span>{t("candidate.videoCreate.noRetakes")}</span>
            </>
          ))}
          </RetakePromptsWrapper>
        </span>
      </p>

      {errorMessage ? <p className="input-error-message sentry-unmask">{t(errorMessage)}</p> : null}
      {errorMessage && hasRefreshed ? (
        <button className="button__without-styles contact-support sentry-unmask" type="button" onClick={openBeacon}>
          {t("link.needSupport")}
        </button>
      ) : null}
    </>
  );
};

ButtonsCaption.defaultProps = {
  max_retakes: undefined,
  attempt_number: undefined,
  initialElapsingTime: undefined,
  cashedElapsingTime: undefined
};

ButtonsCaption.propTypes = {
  t: PropTypes.func.isRequired,
  initialElapsingTime: PropTypes.number,
  cashedElapsingTime: PropTypes.number,
  max_retakes: PropTypes.number,
  attempt_number: PropTypes.number
};

export default withTranslation()(ButtonsCaption);
