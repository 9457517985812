import React, { useState, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik } from "formik";
import classNames from "classnames";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import pick from "lodash/pick";

import { handleClassName, stringToPhoneNumber } from "mixins/helpers";
import { initialStateVideoAnswersConfirm, schemaVideoAnswersConfirm } from "mixins/helperCandidate";
import { getCountryByPhone, getCountryByISO } from "mixins/helperGeolocation";
import { Profile, Mail, Flag } from "mixins/svgIcons";

const Details = ({
  t,
  show,
  proceed,
  dismiss,
  confirmation: {
    phone = "",
    email = "",
    full_name: fullName = "",
    location: {
      country_code: countryCode = ""
    }
  },
  removeModal,
  buttonColor
}) => {
  const memoizedDefaultCountry = useMemo(() => {
    let defaultCountry = {};

    if (phone) {
      defaultCountry = getCountryByPhone(phone);
    } else if (countryCode) {
      defaultCountry = getCountryByISO(countryCode);
    }

    return defaultCountry;
  }, [countryCode, phone]);

  const [country, setCountry] = useState(memoizedDefaultCountry);

  const onChangePhoneNumber = useCallback((e, callback) => {
    let { value = "" } = e?.target;
    const beginning = value?.length >= 3 ? value.slice(0, 3) : value;

    if (beginning.match(/00[0-9]/)) {
      value = value.replace("00", "+");
    }

    const currentCountry = getCountryByPhone(value) || {};

    setCountry(currentCountry);

    if (e?.target) {
      let newPhoneNumber = stringToPhoneNumber(value);
      if (currentCountry?.Dial?.length > 0) newPhoneNumber = `+${newPhoneNumber}`;
      e.target.value = newPhoneNumber;
    }

    if (typeof callback === "function") callback(e);
  }, []);

  const memoizedConfirmation = useMemo(() => {
    const phoneCodes = country?.Dial || [];

    if (!phone && phoneCodes.length > 0) {
      [phone] = phoneCodes;
    }

    const beginning = phone?.length >= 2 ? phone.slice(0, 2) : phone;
    const initialValue = {
      phone: `+${stringToPhoneNumber(phone)}`,
      email,
      full_name: fullName
    };

    if (beginning === "00") {
      initialValue.phone = phone.replace("00", "+");

      const currentCountry = getCountryByPhone(initialValue.phone);
      setCountry(currentCountry);
    }

    return initialValue;
  }, []);

  return (
    <Modal
      dialogClassName="candidate-details"
      show={show}
      onHide={() => {
        dismiss();
        removeModal();
      }}
    >
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialStateVideoAnswersConfirm,
            ...memoizedConfirmation
          }}
          validationSchema={schemaVideoAnswersConfirm()}
          onSubmit={values => {
            proceed(pick(values, ["email", "phone", "full_name"]));
            removeModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            isValidating,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form className="form__candidate-details" onSubmit={handleSubmit}>
              <h3 className="form__candidate-details-title">
                {t("modals.candidateDetails.header")}
              </h3>
              <p className="form__candidate-details-subtitle">
                {t("modals.candidateDetails.text")}
              </p>
              <div className="input">
                <label htmlFor="candidate-name" className="label">
                  {t("input.fullNameLabel")}
                </label>

                <div className="input-wrapper">
                  <Profile svgIconClass="input-icon" />
                  <input
                    id="candidate-name"
                    type="text"
                    name="full_name"
                    placeholder={t("input.fullNamePlaceholder")}
                    className={handleClassName(
                      errors.full_name,
                      touched.full_name
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    disabled={!!email}
                  />

                  {errors.full_name && touched.full_name && (
                    <p className="input-error-message">
                      {t("input.fullNameError")}
                    </p>
                  )}
                </div>
              </div>
              <div className="input">
                <label htmlFor="candidate-email" className="label">
                  {t("input.emailLabel")}
                </label>

                <div className="input-wrapper">
                  <Mail svgIconClass="input-icon" />
                  <input
                    id="candidate-email"
                    type="text"
                    name="email"
                    placeholder={t("input.emailPlaceholder")}
                    className={handleClassName(errors.email, touched.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    disabled={!!email}
                  />

                  {errors.email && touched.email && (
                    <p className="input-error-message">{t("input.emailError")}</p>
                  )}
                </div>
              </div>
              <div className="input">
                <label htmlFor="candidate-phone" className="label">
                  {t("input.phoneLabel")}
                </label>

                <div className="input-wrapper">
                  <Flag
                    svgIconClass={classNames({
                      "input-icon flag": true,
                      international: !country?.Unicode
                    })}
                    flag={country?.Unicode}
                    textParams={{
                      x: 0,
                      y: 11,
                      fontSize: country?.Unicode ? 16 : 13
                    }}
                  />
                  <input
                    id="candidate-phone"
                    type="tel"
                    name="phone"
                    placeholder={t("input.phonePlaceholder")}
                    className={handleClassName(errors.phone, touched.phone)}
                    onBlur={handleBlur}
                    onChange={e => onChangePhoneNumber(e, handleChange)}
                    onKeyDown={e => onChangePhoneNumber(e, handleChange)}
                    value={values.phone}
                    autoComplete="tel"
                  />

                  {errors.phone && touched.phone && (
                    <p className="input-error-message">{t("input.phoneError")}</p>
                  )}
                </div>
              </div>

              <div className="form__candidate-details-button">
                <button
                  disabled={isValidating}
                  type="submit"
                  style={{ background: buttonColor }}
                  className="
                    n-button__large
                    n-bg-purple-100
                    n-white
                  "
                >
                  {t("button.submit")}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

Details.defaultProps = {
  show: undefined,
  proceed: undefined,
  dismiss: undefined,
  removeModal: undefined,
  confirmation: {},
  buttonColor: undefined
};

Details.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  dismiss: PropTypes.func,
  removeModal: PropTypes.func,
  confirmation: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    location: PropTypes.shape({
      country_code: PropTypes.string
    })
  }),
  buttonColor: PropTypes.string
};

export default confirmable(withTranslation()(Details));
