import { takeEvery, select, put } from "redux-saga/effects";

import {
  isCommentsLoading,
  sendComment,
  sendCommentResponse,
  sendCommentError,
  updateComment,
  updateCommentResponse,
  updateCommentError,
  deleteComment,
  deleteCommentResponse,
  deleteCommentError,
} from "store/modules/jobs/actions";

import { getToken } from "store/modules/auth/selectors";

import {
  postRequestWithToken,
  putRequestWithToken,
  deleteRequestWithToken,
} from "./api";

export function* sendCommentWorker({ payload: { userId, text } = {} }) {
  try {
    yield put(isCommentsLoading(true));
    const token = yield select(getToken);

    const response = yield postRequestWithToken({
      url: `/api/job/candidate/${userId}/comment/`,
      token,
      payload: { text },
    });

    yield put(sendCommentResponse(response.data));
  } catch (error) {
    yield put(sendCommentError("Problem with posting comment"));
    console.error("Could post comments", error);
  } finally {
    yield put(isCommentsLoading(false));
  }
}

export function* updateCommentWorker({
  payload: { userId, commentId, text } = {},
}) {
  try {
    yield put(isCommentsLoading(true));
    const token = yield select(getToken);

    const response = yield putRequestWithToken({
      url: `/api/job/candidate/${userId}/comment/${commentId}`,
      token,
      payload: { text },
    });

    yield put(updateCommentResponse(response.data));
  } catch (error) {
    yield put(updateCommentError("Problem with posting comment"));
    console.error("Could post comments", error);
  } finally {
    yield put(isCommentsLoading(false));
  }
}

export function* deleteCommentWorker({ payload: { userId, commentId } = {} }) {
  try {
    yield put(isCommentsLoading(true));
    const token = yield select(getToken);

    yield deleteRequestWithToken({
      url: `/api/job/candidate/${userId}/comment/${commentId}`,
      token,
    });

    yield put(deleteCommentResponse({ key: commentId }));
  } catch (error) {
    yield put(deleteCommentError("Problem with posting comment"));
    console.error("Could post comments", error);
  } finally {
    yield put(isCommentsLoading(false));
  }
}

export function* createCommonWatcher() {
  yield takeEvery(sendComment, sendCommentWorker);
  yield takeEvery(updateComment, updateCommentWorker);
  yield takeEvery(deleteComment, deleteCommentWorker);
}

export default createCommonWatcher;
