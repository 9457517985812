import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { defaultTimeFormat } from "configs/dates";
import { getTextUserLocation } from "mixins/helperGeolocation";
import CANDIDATE_STATUSES from "configs/candidates";

import { isMobile } from "react-device-detect";
import Copy from "components/Tables/Jobs/Buttons/Copy";
import { copyToClipboard } from "mixins/helpers";
import { CopyTable } from "mixins/svgIcons";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { Clock, Location, Mail, Phone, Moon } from "./svgIcons";
import "./styles.scss";

const PHONE = "phone";
const EMAIL = "email";

const Contacts = ({
  t,
  jobCandidateInfo: {
    email = "",
    phone = "",
    location = {},
    utc_minutes_delta: utcMinutesDelta = null,
    status = "",
    anonymized_at: anonymizedAt
  }
}) => {
  const {
    city,
    country_name: countryName,
    google_map_link: googleMapLink
  } = location;
  const userLocation = getTextUserLocation({ city, country_name: countryName }, t);
  const candidateTime = utcMinutesDelta === null ? moment() : moment().utcOffset(utcMinutesDelta);

  const [hoveredItem, setHoveredItem] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);

  const handleCopy = (e, val, key) => {
    e.preventDefault();
    e.stopPropagation();

    setShowTooltip(key);
    copyToClipboard(val);

    setTimeout(() => {
      setShowTooltip(null);
    }, 3000);
  };

  const checkIfIsCopied = useCallback(val => showTooltip === val, [showTooltip]);
  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());

  return (
    <div className="candidate-overview__header-box-contact">
      <SkeletonWrapper
        skeletonProps={{
          count: 4,
          enableAnimation: !anonymizedAt,
          height: 16
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: window.innerWidth <= 475 ? 180 : 300
          }
        }}
        anonymizedDate={anonymizedAt}
        isLoading={isJobCandidateLoading}
      >
        <>
          <p className="candidate-overview__header-box-contact-block">
            <a
              className="n-font-small n-grey-100"
              href={`tel:${phone}`}
              onMouseEnter={() => setHoveredItem(PHONE)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Phone />
              {phone}

              {
                phone?.length > 0 && (
                  <Copy
                    className="not-clickable-icon"
                    handleCopy={e => handleCopy(e, phone, PHONE)}
                    isCopied={checkIfIsCopied(PHONE)}
                    isShowTooltip={checkIfIsCopied(PHONE)}
                    successStyles={{ marginLeft: 5 }}
                    copyBtn={<CopyTable
                      style={{ marginLeft: 5, display: hoveredItem === PHONE || isMobile ? "inline-block" : "none" }}
                      svgIconClass="not-clickable-icon"
                    />}
                  />
                )
              }
            </a>
          </p>
          <p className="candidate-overview__header-box-contact-block">
            <a
              className="n-font-small n-grey-100"
              href={`mailto:${email}`}
              rel="noopener noreferrer"
              target="_blank"
              onMouseEnter={() => setHoveredItem(EMAIL)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Mail />
              {email}

              {
                email?.length > 0 && (
                  <Copy
                    handleCopy={e => handleCopy(e, email, EMAIL)}
                    isCopied={checkIfIsCopied(EMAIL)}
                    isShowTooltip={checkIfIsCopied(EMAIL)}
                    successStyles={{ marginLeft: 5 }}
                    copyBtn={<CopyTable
                      style={{ marginLeft: 5, display: hoveredItem === EMAIL || isMobile ? "inline-block" : "none" }}
                      svgIconClass="not-clickable-icon"
                    />}
                  />
                )
              }
            </a>
          </p>
          {status !== CANDIDATE_STATUSES.INVITED ? (
            <>
              <p className="candidate-overview__header-box-contact-block">
                {googleMapLink ? (
                  <a
                    href={googleMapLink}
                    className="n-font-small n-grey-100"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Location />
                    {userLocation}
                  </a>
                ) : (
                  <span
                    className="n-font-small n-grey-100"
                  >
                    <Location />
                    {userLocation}
                  </span>
                )}
              </p>
              <p className="candidate-overview__header-box-contact-block">
                <span className="n-font-small n-grey-100">
                  <Clock />
                  {t("job.candidate.local")}
                  {defaultTimeFormat(candidateTime)}
                  {(candidateTime.hours() < 9 ||
                    candidateTime.hours() >= 18) && (
                    <Moon svgIconClass="n-icon__medium left" />
                  )}
                </span>
              </p>
            </>
          ) : null}
        </>
      </SkeletonWrapper>
    </div>
  );
};

Contacts.defaultProps = {
};

Contacts.propTypes = {
  t: PropTypes.func.isRequired,
  jobCandidateInfo: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    utc_minutes_delta: PropTypes.number,
    location: PropTypes.shape({
      city: PropTypes.string
    }),
    status: PropTypes.string,
    anonymized_at: PropTypes.string
  }).isRequired
};

export default withTranslation()(Contacts);
