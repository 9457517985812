import { handleActions, combineActions } from "redux-actions";

import {
  isPublicInfoLoading,
  isShareLinkSending,
  shareCandidatePublicResponse,
  shareCandidatePublicError,
  shareCandidatesListPublicResponse,
  shareCandidatesListPublicError,
  fetchPublicUserInfoResponse,
  fetchPublicUserInfoError,
  fetchPublicBulkInfoResponse,
  fetchPublicBulkInfoError,
  clearSharableLink,
  clearErrors,
  setCandidateCompanyInfo
} from "./actions";

const defaultState = {
  isShareLinkSending: false,
  error: ""
};

export default handleActions(
  {
    [isPublicInfoLoading]: (state, { payload = {} }) => ({
      ...state,
      isPublicInfoLoading: payload
    }),
    [isShareLinkSending]: (state, { payload = {} }) => ({
      ...state,
      isShareLinkSending: payload
    }),
    [shareCandidatePublicResponse]: (
      state,
      { payload: {
        share_link: sharableLink = "",
        showcase_link_opened_amount: showcaseLinkOpenedAmount,
        is_showcase_link_enabled: isShowcaseLinkEnabled
      } }
    ) => ({
      ...state,
      sharableLink,
      showcaseLinkOpenedAmount,
      isShowcaseLinkEnabled
    }),
    [shareCandidatesListPublicResponse]: (
      state,
      { payload: { bulk_showcase_link, key } }
    ) => ({
      ...state,
      bulkShowcaseLink: bulk_showcase_link,
      bulkKey: key
    }),
    [fetchPublicUserInfoResponse]: (state, { payload = {} }) => ({
      ...state,
      publicCandidate: payload,
      error: null
    }),
    [fetchPublicBulkInfoResponse]: (state, { payload = [] }) => ({
      ...state,
      publicBulk: payload,
      error: null
    }),
    [combineActions(
      shareCandidatePublicError,
      fetchPublicUserInfoError,
      shareCandidatesListPublicError,
      fetchPublicBulkInfoError
    )]: (
      state,
      { payload = {} }
    ) => ({
      ...state,
      error: payload
    }),
    [clearSharableLink]: state => ({
      ...state,
      sharableLink: "",
      bulkShowcaseLink: "",
      bulkKey: ""
    }),
    [clearErrors]: state => ({
      ...state,
      error: ""
    }),
    [setCandidateCompanyInfo]: (state, { payload = {} }) => ({
      ...state,
      currentCompany: payload
    })
  },
  defaultState
);
