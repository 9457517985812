/* eslint-disable */

import React, { useEffect } from "react";
import NiceInputPassword from "react-nice-input-password";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Formik } from "formik";
import classNames from "classnames";

import withHooks from "./withHooks";

import SocialsLogin from "containers/Auth/SocialsLoginContainer";

import Message from "components/Common/Message";
import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";

import PageWrapper from "hoc/AuthWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import { Profile, Mail, Key, Eye } from "mixins/svgIcons";

import "./styles.scss";
import { useComponentWillMount } from "hooks/useComponentWillMount";

const { Rewardful } = window;

const SignUp = ({
  t,
  location: { search },
  isEmailLocked,
  signUpConfig,
  signUpPredefindConfig,
  signUpFormSchema,
  recaptchaChangedHandler,
  onTabListener,
  onSubmitHandler,
  setSignUpInfo,
  showHideFields,
  showPassword,
  passwordFields,
  refRecaptcha,
  error,
  code
}) => {
  return (
  <PageWrapper header={<Header />} footer={<Footer isPublic />}>
    <div className="form form__sign-up">
      <Formik
        enableReinitialize
        initialValues={signUpPredefindConfig || signUpConfig()}
        validationSchema={signUpFormSchema()}
        onSubmit={values => {
          recaptchaChangedHandler();
          setSignUpInfo(values);
          // eslint-disable-next-line
          setSignUpInfo({...values, referral: Rewardful?.referral}); 
        }}
        render={fk => (
          <form
            autoComplete="off"
            className="form__inner"
            onSubmit={fk.handleSubmit}
          >
            <header className={error ? "form__title_error" : "form__title"}>
              <h2 className="form__title_main">{t("signUp.header")}</h2>
              <p>{t("signUp.subHeader")}</p>
            </header>

            {error && <Message error message={error} />}

            <div className="input">
              <label htmlFor="fullName" className="label">
                {t("input.fullNameLabel")}
              </label>

              <div className="input-wrapper">
                <Profile svgIconClass="input-icon" />

                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  className={`input-element ${
                    fk.errors.fullName && fk.touched.fullName ? "invalid" : ""
                  }`}
                  placeholder={t("input.fullNamePlaceholder")}
                  value={fk.values.fullName}
                  onBlur={fk.handleBlur}
                  onChange={fk.handleChange}
                />

                {fk.errors.fullName && fk.touched.fullName && (
                  <p className="input-error-message signup-error">{t(fk.errors.fullName)}</p>
                )}
              </div>
            </div>

            <div className="input">
              <label htmlFor="email" className="label">
                {t("input.workEmailLabel")}
              </label>

              <div className="input-wrapper">
                <Mail svgIconClass="input-icon" />

                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  className={`input-element ${
                    fk.errors.email && fk.touched.email ? "invalid" : ""
                  }`}
                  placeholder={t("input.emailPlaceholder")}
                  disabled={isEmailLocked}
                  value={fk.values.email}
                  onBlur={fk.handleBlur}
                  onChange={fk.handleChange}
                  onKeyDown={onTabListener}
                />

                {fk.errors.email && fk.touched.email && (
                  <p className="input-error-message">{t(fk.errors.email)}</p>
                )}
              </div>
            </div>

            <div className="input">
              <label htmlFor="password" className="label">
                {t("input.passwordLabel")}
              </label>

              <div className="input-wrapper" onClick={showHideFields}>
                <div className="input-icon-wrapper">
                  <Key svgIconClass="input-icon" />
                </div>

                <NiceInputPassword
                  name="password"
                  type="text"
                  label=""
                  placeholder={t("input.passwordPlaceholder")}
                  className="input-element-nice-password"
                  value={fk.values.password.value}
                  securityLevels={[
                    {
                      descriptionLabel: t("validation.oneNumber"),
                      validator: /.*[0-9].*/
                    },
                    {
                      descriptionLabel: t("validation.oneLetter"),
                      validator: /.*[a-zA-Z].*/
                    },
                    {
                      descriptionLabel: t("validation.oneUpperCaseLetter"),
                      validator: /.*[A-Z].*/
                    },
                    {
                      descriptionLabel: t("validation.minLength"),
                      validator: value => value?.length >= 12
                    }
                  ]}
                  showSecurityLevelBar={passwordFields.bar}
                  showSecurityLevelDescription={passwordFields.bar}
                  onBlur={() => fk.setFieldTouched("password", true)}
                  onChange={({ name, value, isValid }) =>
                    fk.setFieldValue(name, { value, isValid })
                  }
                />

                <button
                  type="button"
                  className="button__show-password"
                  onClick={showPassword}
                >
                  <Eye svgIconClass="input-icon right" />
                </button>
              </div>
            </div>

            <button
              type="submit"
              className={classNames(
                "button",
                "button__submit",
                "button__purple",
                "button__purple_shadow"
              )}
              disabled={!(Object.keys(fk.errors).length === 0)}
            >
              {t("button.signUp")}
            </button>

            <div className="form__link">
              <Link to={`/sign-in${search}`}>{t("link.signIn")}</Link>
            </div>
          </form>
        )}
      />

      <ReCAPTCHA
        size="invisible"
        ref={refRecaptcha}
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
        onChange={onSubmitHandler}
      />

      <SocialsLogin code={code} />
    </div>
  </PageWrapper>
);
              }

SignUp.defaultProps = {
  passwordValidationSchema: undefined,
  signUpConfig: undefined,
  signUpPredefindConfig: undefined,
  signUpFormSchema: undefined,
  recaptchaChangedHandler: undefined,
  onTabListener: undefined,
  onSubmitHandler: undefined,
  setSignUpInfo: undefined,
  recaptcha: undefined,
  error: undefined
};

SignUp.propTypes = {
  t: PropTypes.func.isRequired,
  passwordValidationSchema: PropTypes.arrayOf(PropTypes.shape({})),
  signUpConfig: PropTypes.func,
  signUpPredefindConfig: PropTypes.shape({}),
  signUpFormSchema: PropTypes.func,
  recaptchaChangedHandler: PropTypes.func,
  onTabListener: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  setSignUpInfo: PropTypes.func,
  recaptcha: PropTypes.shape({}),
  error: PropTypes.string,
  code: PropTypes.string.isRequired
};

export default withLoaderScreen(withTranslation()(withHooks(withRouter(SignUp))));
