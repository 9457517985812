import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Formik, Field, Form } from "formik";

import { Phone, Agency, MailBlack } from "mixins/svgIcons";

import { setInitialInfo, editFormSchema, editFormOwnerSchema } from "mixins/helperProfile";

const EditForm = ({ t, updateProfileInfo, hideEditProfile, profileInfo, isOwner }) => {
  const initialFormState = setInitialInfo(profileInfo);

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={isOwner ? editFormOwnerSchema() : editFormSchema()}
      onSubmit={values => {
        updateProfileInfo({
          agency_name: values.agency,
          phone: values.phone
        });
        hideEditProfile();
      }}
      render={({ errors, touched, isSubmitting }) => (
        <Form className="edit-from">
          <Field
            type="email"
            name="email"
            render={({ field }) => (
              <div className="edit-profile__field">
                <MailBlack />
                <input
                  {...field}
                  disabled
                  title={field.value}
                  placeholder="Email"
                  className={classNames(
                    "edit-profile__input",
                    "disabled",
                    touched.email && errors.email && "error"
                  )}
                />
                {errors.email && touched.email ? (
                  <div className="edit-profile__input-error">
                    {t(errors.email)}
                  </div>
                ) : null}
              </div>
            )}
          />

          <Field
            type="tel"
            name="phone"
            render={({ field }) => (
              <div className="edit-profile__field">
                <Phone />
                <input
                  {...field}
                  type="tel"
                  placeholder={t("input.phonePlaceholder")}
                  className={classNames(
                    "edit-profile__input",
                    touched.phone && errors.phone && "error"
                  )}
                />
                {errors.phone && touched.phone ? (
                  <div className="edit-profile__input-error">
                    {t(errors.phone)}
                  </div>
                ) : null}
              </div>
            )}
          />

          {isOwner && (
            <Field
              type="text"
              name="agency"
              render={({ field }) => (
                <div className="edit-profile__field last">
                  <Agency />
                  <input
                    {...field}
                    type="text"
                    placeholder={t("input.agencyPlaceholder")}
                    className={classNames(
                      "edit-profile__input",
                      touched.agency && errors.agency && "error"
                    )}
                  />
                  {errors.agency && touched.agency ? (
                    <div className="edit-profile__input-error">
                      {t(errors.agency)}
                    </div>
                  ) : null}
                </div>
              )}
            />
          )}

          <div className="button__group">
            <button
              type="button"
              onClick={hideEditProfile}
              className="button button__edit-form"
            >
              {t("button.cancel")}
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="button button__edit-form button__edit-form_fill"
            >
              {t("button.submit")}
            </button>
          </div>
        </Form>
      )}
    />
  );
};

EditForm.defaultProps = {
  hideEditProfile: undefined,
  updateProfileInfo: undefined,
  profileInfo: undefined,
  isOwner: undefined
};

EditForm.propTypes = {
  t: PropTypes.func.isRequired,
  hideEditProfile: PropTypes.func,
  updateProfileInfo: PropTypes.func,
  profileInfo: PropTypes.shape({
    agency: PropTypes.string,
    agency_name: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    worker_count: PropTypes.number
  }),
  isOwner: PropTypes.bool
};

export default withTranslation()(EditForm);
