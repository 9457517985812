import { handleActions, combineActions } from "redux-actions";

import {
  fetchJobsStatsResponse,
  fetchJobsStatsError,
  fetchIndustriesResponse,
  fetchCurrentJobResponse
} from "store/modules/jobs/actions";
import {
  fetchCandidatesStatsResponse,
  fetchCandidatesStatsError
} from "store/modules/сandidates/actions";
import { removeDuplicates } from "mixins/helpers";
import {
  fetchCompaniesForTableResponse,
  fetchCurrentCompanyResponse,
  fetchCurrentCompanyError,
  editCompanyError,
  setCurrentDateRange,
  createCompanyResponse,
  createCompanyError,
  deleteCompanyResponse,
  deleteCompanyError,
  isCompaniesLoading,
  clearCompanyError,
  editCompanyResponse,
  clearCompanies
} from "./actions";

const defaultState = {
  companyJobs: [],
  currentDateRange: {
    label: "Select ...",
    value: ""
  },
  owners: [],
  isOwnersLoading: false
};

export default handleActions(
  {
    [isCompaniesLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [fetchCompaniesForTableResponse]: (state, { payload }) => {
      const companies = removeDuplicates([...(state?.tableData?.results || []), ...payload.results || []], "key");

      return {
        ...state,
        tableData: {
          ...payload,
          results: companies
        }
      };
    },
    [fetchCurrentCompanyResponse]: (state, { payload }) => ({
      ...state,
      currentCompany: payload
    }),
    [combineActions(
      fetchCurrentCompanyError,
      fetchCandidatesStatsError,
      fetchJobsStatsError,
      editCompanyError,
      deleteCompanyError,
      createCompanyError,
      clearCompanyError
    )]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [clearCompanyError]: state => ({
      ...state,
      error: null
    }),
    [fetchCandidatesStatsResponse]: (state, { payload }) => ({
      ...state,
      companyCandidates: payload
    }),
    [fetchJobsStatsResponse]: (state, { payload }) => ({
      ...state,
      companyJobs: payload
    }),
    [fetchIndustriesResponse]: (state, { payload }) => ({
      ...state,
      industriesOptions: payload
    }),
    [setCurrentDateRange]: (state, { payload }) => ({
      ...state,
      currentDateRange: payload
    }),
    [fetchCurrentJobResponse]: (state, { payload }) => ({
      ...state,
      companyJobs:
        state.companyJobs &&
        state.companyJobs.map(item => {
          if (item.key === payload.key) {
            return {
              ...item,
              deleted: payload.delete,
              deadline: payload.deadline
            };
          }

          return item;
        })
    }),
    [deleteCompanyResponse]: (state, { payload }) => ({
      ...state,
      tableData: state?.tableData?.results?.filter(item => item.key !== payload)
    }),
    [clearCompanies]: state => ({
      ...state,
      tableData: {}
    }),
    [createCompanyResponse]: (state, { payload }) => ({
      ...state,
      tableData: {
        ...state.tableData,
        count: state.tableData?.count + 1,
        results: removeDuplicates([...(state.tableData.results || []), payload], "key")
      }
    }),
    [editCompanyResponse]: (state, { payload }) => ({
      ...state,
      tableData: {
        ...state.tableData,
        results: state.tableData.results.map(item => {
          if (item.key === payload.key) {
            return payload;
          }

          return item;
        })
      }
    })
  },
  defaultState
);
