/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { convertToPlain } from "mixins/helpers";
import { isIOS } from "react-device-detect";
import { getProfileInfo } from "store/modules/profile/selectors";
import { connect } from "react-redux";
import {
  ALL_IDENTITY_VERIFICATION_TYPES,
  AUDIO_TYPE,
  INITIAL_TYPE
} from "configs/jobs/constants";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import VideoItem from "./Video";
import FileItem from "./File";
import TextItem from "./Text";
import ContentExpired from "./ContentExpired";
import { Download } from "./svgIcons";
import IDVItem from "./IDV";
import UnsafeModal from "./File/UnsafeModal";
import MultipleChoiceThumbnail from "./MultipleChoice/MultipleChoiceThumbnail";
import "./styles.scss";

const List = ({
  t,
  answers,
  currentQuestion,
  setCurrentQuestion,
  buttonColor,
  downloadAll,
  idvCheckData,
  shouldShowDownloadAll = true,
  isMaliciousFileMuted = false,
  isRecruiter
}) => {
  const ref = useRef(null);
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);

  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());
  const isShowcaseLoading = isPublicInfoLoading(store.getState());

  const buttonDisplay = () => (
    <button
      className="button__without-styles video__preview-item-suptitle download"
      style={{
        cursor: shouldShowDownloadAll ? "pointer" : "not-allowed"
      }}
      type="button"
      onClick={() => {
        if (shouldShowDownloadAll && !isMaliciousFileMuted) {
          return setShowUnsafeModal(true);
        }

        if (shouldShowDownloadAll) {
          downloadAll();
        }
      }}
    >
      {t("job.candidate.download.downloadAll")}
      <Download />
    </button>
  );

  return (
    <>
      {showUnsafeModal && (
        <UnsafeModal
          handleCloseModal={() => setShowUnsafeModal(false)}
          onDownload={() => {
            downloadAll();
            setShowUnsafeModal(false);
          }}
        />
      )}
      <div className="video__preview-item-suptitle-container">
        <p className="video__preview-item-suptitle answer">
          {t("candidate.common.answers", {
            current: currentQuestion?.questionNumber,
            total: answers.length
          })}
        </p>
        {downloadAll && !isIOS ? (
          <>
            {shouldShowDownloadAll ? (
              buttonDisplay()
            ) : (
              <OverlayTrigger
                ref={ref}
                placement="bottom"
                delay={{ show: 250, hide: 100 }}
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false
                    }
                  }
                }}
                overlay={props => (
                  <Tooltip className="tooltip-bottom__custom" {...props}>
                    {t("job.candidate.download.disabledTooltip")}
                  </Tooltip>
                )}
              >
                {buttonDisplay()}
              </OverlayTrigger>
            )}
          </>
        ) : null}
      </div>
      <div className="preview__list-scroll">
        <div className="preview__list">
          <SkeletonWrapper
            skeletonProps={{
              count: 3,
              enableAnimation: true,
              height: 80,
              borderRadius: 20
            }}
            skeletonWrapperProps={{
              style: {
                display: "block",
                lineHeight: 3,
                width: "100%"
              }
            }}
            isLoading={isJobCandidateLoading || isShowcaseLoading}
          >
            {answers?.map((answer, index) => {
              const isCurrent = currentQuestion?.key === answer?.key;
              const newCurrentQuestion = { ...answer, questionNumber: index + 1 };

              const handleSetCurrentQuestion = () =>
                (isCurrent ? {} : setCurrentQuestion(newCurrentQuestion));

              return answer?.is_media_deleted && answer?.status !== "unsafe" ? (
                <ContentExpired
                  questionText={convertToPlain(answer?.question?.html_text)}
                  questionNumber={index + 1}
                  setCurrentQuestion={handleSetCurrentQuestion}
                  isCurrent={isCurrent}
                  buttonColor={buttonColor}
                />
              ) : answer?.question?.answer_type === "file" ? (
                <FileItem
                  questionText={convertToPlain(answer?.question?.html_text)}
                  questionNumber={index + 1}
                  answer={answer}
                  setCurrentQuestion={handleSetCurrentQuestion}
                  isCurrent={isCurrent}
                  buttonColor={buttonColor}
                  status={answer?.status}
                />
              ) : (answer?.question?.answer_type === "multiple_choice" || answer?.question?.answer_type === "checkboxes") ? (
                <MultipleChoiceThumbnail
                  questionText={convertToPlain(answer?.question?.html_text)}
                  questionNumber={index + 1}
                  answer={answer}
                  setCurrentQuestion={handleSetCurrentQuestion}
                  isCurrent={isCurrent}
                  buttonColor={buttonColor}
                  status={answer?.status}
                  isRadio={answer?.question?.answer_type === "multiple_choice"}
                  isRecruiter={isRecruiter}
                />
              ) : answer?.question?.answer_type === "text" ? (
                <TextItem
                  questionText={convertToPlain(answer?.question?.html_text)}
                  questionNumber={index + 1}
                  answer={answer}
                  setCurrentQuestion={handleSetCurrentQuestion}
                  isCurrent={isCurrent}
                  buttonColor={buttonColor}
                />
              ) : ALL_IDENTITY_VERIFICATION_TYPES?.includes(
                  answer?.question?.answer_type
                ) ? (
                  <IDVItem
                    questionText={convertToPlain(answer?.question?.html_text)}
                    questionNumber={index + 1}
                    answer={answer}
                    setCurrentQuestion={handleSetCurrentQuestion}
                    isCurrent={isCurrent}
                    buttonColor={buttonColor}
                    idvCheckData={idvCheckData}
                    question={answer?.question}
                  />
              ) : [INITIAL_TYPE, AUDIO_TYPE]?.includes(
                  answer?.question?.answer_type
                ) ? (
                  <VideoItem
                    key={answer?.question?.key}
                    source={
                      answer?.question?.answer_type === AUDIO_TYPE
                        ? {
                            src: `${answer?.remote_link}${
                              !isIOS ? `#${answer?.updated_at}` : ""
                            }`,
                            type: "audio/mp3"
                          }
                        : {
                            src: `${answer?.remote_link}#t=0.001${
                              !isIOS ? `?${answer?.updated_at}` : ""
                            }`,
                            type: "video/mp4"
                          }
                    }
                    questionText={convertToPlain(answer?.question?.html_text)}
                    questionNumber={index + 1}
                    onlyAudio={answer?.question?.answer_type === AUDIO_TYPE}
                    setCurrentQuestion={handleSetCurrentQuestion}
                    isCurrent={isCurrent}
                    buttonColor={buttonColor}
                    duration={answer?.media_duration}
                  />
              ) : null;
            })}
          </SkeletonWrapper>
        </div>
      </div>
    </>
  );
};

List.defaultProps = {
  answers: undefined,
  downloadAll: undefined,
  buttonColor: undefined
};

List.propTypes = {
  t: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
  currentQuestion: PropTypes.shape({
    questionNumber: PropTypes.string,
    key: PropTypes.string
  }).isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  downloadAll: PropTypes.func,
  idvCheckData: PropTypes.shape({}).isRequired,
  shouldShowDownloadAll: PropTypes.bool.isRequired,
  isMaliciousFileMuted: PropTypes.bool.isRequired,
  isRecruiter: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isMaliciousFileMuted:
    getProfileInfo(state)?.is_malicious_file_message_muted || false
});

export default connect(mapStateToProps)(withTranslation()(List));
