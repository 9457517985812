import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { withTranslation } from "react-i18next";

import { Question } from "mixins/svgIcons";
import animationData from "assets/images/candidate/error.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const ContentExpired = ({
  t,
  questionText,
  questionNumber,
  isCurrent,
  setCurrentQuestion,
  buttonColor
}) => (
  <div
    role="presentation"
    className={`preview__list-item ${isCurrent && "current"}`}
    onClick={setCurrentQuestion}
    style={isCurrent && buttonColor ? { background: `${buttonColor}20` } : null}
  >
    <div className="preview__list-item-type expired">
      <Lottie options={defaultOptions} height={100} width={150} />
    </div>
    <div className="preview__list-item-info">
      <p className="preview__list-item-info-subtitle n-grey-100 n-font-small">
        <Question svgIconClass="preview__list-item-info-icon" />
        <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
      </p>

      <p
        className="preview__list-item-info-title n-grey-300 n-font-small"
        title={questionText}
      >
        {questionText}
      </p>
    </div>
  </div>
);

ContentExpired.defaultProps = {
  buttonColor: ""
};

ContentExpired.propTypes = {
  t: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string
};

export default withTranslation()(ContentExpired);
