/* eslint-disable camelcase */
import * as Yup from "yup";

export const signUpConfig = ({
  fullName = "",
  email = "",
  password = { value: "", isValid: false }
} = {}) => ({
  fullName,
  email,
  password,
  errors: {},
  touched: {}
});

export const signUpFormSchema = () =>
  Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .test("fullName", "errors.validNameMinWords", value => value?.includes(" "))
      .min(6, "errors.validNameMinLength")
      .required("errors.required"),
    email: Yup.string()
      .email("errors.validEmail")
      .required("errors.required"),
    password: Yup.mixed().test(({ isValid }) => isValid)
  });
