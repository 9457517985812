import { connect } from 'react-redux';

import {
  signInWithLinkedIn,
  signInWithGoogle,
} from 'store/modules/auth/actions';

import {
  getToken,
  getIsLoading,
} from 'store/modules/auth/selectors';

import SocialsLogin from 'components/SocialsLogin';

const mapStateToProps = state => ({
  isAuthenticated: Boolean(getToken(state)),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  signInWithLinkedIn: payload => dispatch(signInWithLinkedIn(payload)),
  signInWithGoogle: payload => dispatch(signInWithGoogle(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialsLogin);
