/* eslint-disable camelcase */
import { handleActions, combineActions } from "redux-actions";

import { uniqBy } from "lodash";
import { removeDuplicates } from "mixins/helpers";
import {
  isJobsLoading,
  isCandidatesStatsLoading,
  isNextCandidatesLoading,
  isJobStatsLoading,
  fetchCandidatesListResponse,
  fetchCandidatesListError,
  fetchNextCandidatesListResponse,
  fetchNextCandidatesListError,
  fetchJobCandidatesStatsResponse,
  fetchJobCandidatesStatsError,
  fetchJobCandidatesStatsFilterResponse,
  clearErrors,
  deleteCandidateFromInterviewersList,
  fetchCandidatesByStatusList,
  fetchCandidatesByStatusListResponse,
  fetchCandidatesByStatusListError,
  setSelectedCandidates,
  setSelectedCandidatesByStatusResponse,
  setSelectedCandidatesLoading,
  setSelectAll,
  setExcludedCandidates,
  fetchCandidatesByStatusListReset,
  setTotalSelectedCount,
  removeExcludedCandidates
} from "./actions";

const defaultState = {
  isLoading: false,
  isCandidatesStatsLoading: false,
  isJobStatsLoading: false,
  interviewersList: {},
  interviewersProgress: {},
  selectAll: {
    accepted: false,
    rejected: false,
    invited: false,
    received: false
  },
  excludedCandidates: [],
  totalSelectedCount: null
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [isCandidatesStatsLoading]: (state, { payload }) => ({
      ...state,
      isCandidatesStatsLoading: payload
    }),
    [isNextCandidatesLoading]: (state, { payload }) => ({
      ...state,
      isNextCandidatesLoading: payload
    }),
    [isJobStatsLoading]: (state, { payload }) => ({
      ...state,
      isJobStatsLoading: payload
    }),
    [fetchCandidatesListResponse]: (state, { payload: {
      results,
      next
    } = {} }) => ({
      ...state,
      interviewersList: results,
      nextCandidates: next
    }),
    [combineActions(fetchCandidatesByStatusList, fetchCandidatesByStatusListReset)]: state => ({
      ...state,
      interviewersByStatusList: {}
    }),
    [fetchCandidatesByStatusListResponse]: (state, { payload = {} }) => {
      const results = removeDuplicates([...state?.interviewersByStatusList?.results ?? [], ...payload?.results ?? []], "key");

      return {
        ...state,
        interviewersByStatusList: {
          next: payload?.results?.length === 0 ? null : payload?.next,
          results
        }
      };
    },
    [setSelectedCandidates]: (state, { payload = {} }) => ({
      ...state,
      selectedCandidates: payload
    }),
    [setSelectedCandidatesByStatusResponse]: (state, { payload = {} }) => ({
      ...state,
      selectedCandidates:
        uniqBy([...new Set([...state.selectedCandidates,
          ...payload?.results?.map(({ key, status }) => ({
            id: key,
            status
          }))])], "id"),
      next: payload?.next ?? null
    }),
    [setSelectAll]: (state, { payload }) => {
      if (typeof payload === "object") {
        return {
          ...state,
          selectAll: {
            ...state.selectAll,
            ...payload
          }
        };
      }
      return {
        ...state,
        selectAll: {
          ...state.selectAll,
          [payload]: true
        }
      };
    },
    [setExcludedCandidates]: (state, { payload }) => {
      if (payload?.length === 0) {
        return {
          ...state,
          excludedCandidates: []
        };
      }
      return {
        ...state,
        excludedCandidates:
            uniqBy([...new Set([...(state?.excludedCandidates || []),
              ...payload?.map(({ id, status }) => ({
                id,
                status
              }))])], "id")
      };
    },
    [removeExcludedCandidates]: (state, { payload }) => ({
      ...state,
      excludedCandidates: state?.excludedCandidates?.filter(a => a.id !== payload.key)
    }),
    [setTotalSelectedCount]: (state, { payload }) => {
      const selected = Object.keys(state?.selectAll);
      let newState = state;

      selected.forEach(item => {
        const excludedCandidatesByStatus = state?.excludedCandidates?.filter(can =>
          can?.status?.toLowerCase() !== item);

        if (state?.selectAll?.[item] === true) {
          newState = {
            ...newState,
            totalSelectedCount: {
              ...newState.totalSelectedCount,
              [item]: (newState?.interviewersFilter?.[item]) - excludedCandidatesByStatus?.length
            }
          };
        } else if (payload) {
          const selectedCandidatesByStatus = payload?.selectedItems
            ?.filter(candidate => candidate?.status?.toLowerCase() === item);

          newState = {
            ...newState,
            totalSelectedCount: {
              ...newState.totalSelectedCount,
              [item]: selectedCandidatesByStatus?.length
            }
          };
        }
      });

      return newState;
    },
    [setSelectedCandidatesLoading]: (state, { payload }) => ({
      ...state,
      isSelectedCandidatesLoading: payload
    }),
    [fetchNextCandidatesListResponse]: (state, { payload = {} }) => ({
      ...state,
      nextCandidates: payload?.next,
      interviewersList: {
        accepted: [...state.interviewersList.accepted, ...payload?.results?.accepted],
        invited: [...state.interviewersList.invited, ...payload?.results?.invited],
        rejected: [...state.interviewersList.rejected, ...payload?.results?.rejected],
        to_review: [...state.interviewersList.to_review, ...payload?.results?.to_review]
      }
    }),
    [combineActions(
      fetchJobCandidatesStatsResponse,
      fetchJobCandidatesStatsFilterResponse
    )]: (state, { payload = {} }) => ({
      ...state,
      interviewersProgress: {
        accepted: payload.accepted_cnt,
        invited: payload.invited_cnt,
        rejected: payload.rejected_cnt,
        to_review: payload.to_review_cnt
      }
    }),
    [fetchJobCandidatesStatsFilterResponse]: (state, { payload = {} }) => ({
      ...state,
      interviewersFilter: {
        accepted: payload.accepted_cnt,
        invited: payload.invited_cnt,
        rejected: payload.rejected_cnt,
        to_review: payload.to_review_cnt
      }
    }),
    [deleteCandidateFromInterviewersList]: (state, { payload: userId }) => {
      const {
        accepted = [],
        invited = [],
        to_review = [],
        rejected = []
      } = state?.interviewersList || {};

      return ({
        ...state,
        interviewersList: {
          accepted: accepted.filter(i => i.key !== userId),
          invited: invited.filter(i => i.key !== userId),
          to_review: to_review.filter(i => i.key !== userId),
          rejected: rejected.filter(i => i.key !== userId)
        }
      });
    },
    [combineActions(
      fetchCandidatesListError,
      fetchJobCandidatesStatsError,
      fetchNextCandidatesListError,
      fetchCandidatesByStatusListError
    )]: (
      state,
      { payload }
    ) => ({
      ...state,
      error: payload
    }),
    [clearErrors]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
);
