import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Default from "../svgIcons";

const Header = ({ children, isDefault, t }) => (
  <header className="company-card-header">
    <p>{children}</p>
    {isDefault ? (
      <span className="company-default">
        {t("company.default")}
        <span className="company-default-icon"><Default /></span>
      </span>
    ) : null}
  </header>
);

Header.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isDefault: PropTypes.bool.isRequired
};

export default withTranslation()(Header);
