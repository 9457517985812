import { createAction } from "redux-actions";

export const isLoading = createAction("IS_LOADING");

export const signIn = createAction("SIGN_IN_START");
export const signInResponse = createAction("SIGN_IN_RESPONSE");
export const signInError = createAction("SIGN_IN_ERROR");

export const mfaSignin = createAction("MFA_SIGN_IN_START");
export const mfaSigninResponse = createAction("MFA_SIGN_IN_RESPONSE");
export const mfaSigninError = createAction("MFA_SIGN_IN_ERROR");

export const confirmEmail = createAction("CONFIRM_START");
export const confirmEmailResponse = createAction("CONFIRM_RESPONSE");
export const confirmEmailError = createAction("CONFIRM_ERROR");

export const signInWithLinkedIn = createAction("SIGN_IN_LINKED_START");
export const signInWithLinkedInResponse = createAction(
  "SIGN_IN_LINKED_RESPONSE"
);
export const signInWithGoogle = createAction("SIGN_IN_GOOGLE_START");
export const signInWithGoogleResponse = createAction("SIGN_IN_GOOGLE_RESPONSE");

export const signUp = createAction("SIGN_UP_START");
export const signUpResponse = createAction("SIGN_UP_RESPONSE");
export const signUpError = createAction("SIGN_UP_ERROR");

export const resetSendEmail = createAction("RESET_SEND_EMAIL_START");
export const resetEmailIsSend = createAction("RESET_EMAIL_IS_SEND");

export const resetPasswordGetEmail = createAction("RESET_PASSWORD_GET_EMAIL");
export const resetPasswordGetEmailResponse = createAction(
  "RESET_PASSWORD_GET_EMAIL_RESPONSE"
);
export const resetPasswordGetEmailError = createAction(
  "RESET_PASSWORD_GET_EMAIL_ERROR"
);

export const saveToken = createAction("SAVE_TOKEN");

export const resetPassword = createAction("RESET_PASSWORD_START");
export const setResetEmail = createAction("SET_RESET_EMAIL");
export const resetPasswordError = createAction("RESET_PASSWORD_ERROR");

export const clearErrors = createAction("CLEAR_ERRORS");
export const clearConfirms = createAction("CLEAR_CONFIRMS");
export const clearEmails = createAction("CLEAR_EMAILS");

export const keppLogedIn = createAction("KEEP_LOGED_IN");
export const logOut = createAction("LOG_OUT");
export const logOutSuccess = createAction("LOG_OUT_SUCCESS");

export const setSignInPayload = createAction("SET_SIGNIN_PAYLOAD");
