import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./modules/auth/authReducers";
import jobs from "./modules/jobs/jobsReducer";
import companies from "./modules/companies/companiesReducer";
import users from "./modules/users/usersReducer";
import jobStatus from "./modules/jobs/jobStatus";
import jobsSetup from "./modules/jobs/setupReducer";
import jobsQuestions from "./modules/jobs/questionsReducer";
import jobsPreview from "./modules/jobs/previewReducer";
import jobsOverview from "./modules/jobs/overviewReducer";
import jobsCandidate from "./modules/jobs/candidateReducer";
import profile from "./modules/profile/profileReducers";
import candidates from "./modules/сandidates/candidatesReducers";
import dashboard from "./modules/dashboard/dashboardReducer";
import publicPage from "./modules/public/publicReducer";
import modals from "./modules/modals/modalsReducer";
import language from "./modules/language/languageReducer";
import common from "./modules/common/commonReducer";
import notifications from "./modules/notifications/notificationsReducers";
import widgets from "./modules/widgets/widgetsReducer";
import libraries from "./modules/libraries/reducer";
import scorecards from "./modules/scorecards/reducer";
import lever from "./modules/lever/reducer";
import people from "./modules/people/reducer";
import idv from "./modules/idv/reducers";
import marketplace from "./modules/marketplace/marketplaceReducers";

export default history => (state, action) => {
  const {
    language: currentLanguage,
    notifications: currentNotifications
  } = state;

  const defaultReducer = {
    language: currentLanguage,
    notifications: currentNotifications
  };

  const newState = action.type === "LOG_OUT" ? defaultReducer : state;

  return combineReducers({
    auth,
    jobs,
    jobStatus,
    jobsSetup,
    jobsQuestions,
    jobsPreview,
    jobsOverview,
    jobsCandidate,
    companies,
    users,
    profile,
    candidates,
    dashboard,
    publicPage,
    modals,
    language,
    common,
    notifications,
    router: connectRouter(history),
    widgets,
    libraries,
    lever,
    people,
    idv,
    marketplace,
    scorecards
  })(newState, action);
};
