/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { isIOS, isMobile } from "react-device-detect";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js";

import Message from "components/Common/Message";
import {
  canPlayExtension,
  customTimeFormat
} from "mixins/helperVideoRecording";

import "./styles.scss";
import { connect } from "react-redux";
import { getCandidateVolume } from "store/modules/сandidates/selectors";
import { updateVideoPreference } from "store/modules/сandidates/actions";
import { store } from "store";
import getValidVolume from "utils/number";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { removeDuplicates } from "mixins/helpers";
import { updateProfileInfo } from "store/modules/profile/actions";
import { getProfileInfo } from "store/modules/profile/selectors";
import { isCandidateRoute } from "mixins/helperCandidate";
import * as Sentry from "@sentry/browser";
import { Replay, Play } from "./svgIcons";

const overrideNative = true;

const VideoPlayer = ({
  className,
  sources,
  downloadSource,
  withDownload,
  questionKey,
  questionNumber,
  style,
  onReady,
  withError,
  mediaExtension,
  onlyAudio,
  isCandidate,
  settings,
  setSettings,
  currentTime,
  setCurrentTime,
  withNext,
  cancelButton,
  handleNextQuestion,
  volume,
  currentQuestion: {
    is_reloaded: isReloaded = false,
    is_muted: isMuted = false
  } = {},
  videoErrors,
  setVideoErrors,
  handleGoToScorecard,
  showScorecardButton,
  playbackSpeed,
  updateFirebaseData = () => {},
  isFirebaseInitialized = false,
  ...rest
}) => {
  const [isVisibleReplay, setVisibleReplay] = useState(false);
  const [animated, setAnimated] = useState(true);
  const videoId = `video_js_${questionKey || questionNumber}`;
  const playerRef = useRef(null);
  const animationRef = useRef(null);
  const videoRef = useRef();
  const storeVolume = getValidVolume(volume);
  const { t } = useTranslation();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isVideoPortrait, setIsVideoPortrait] = useState(!!onlyAudio);

  let player;

  useEffect(() => {
    const animation = animationRef.current;
    if (animation) animation.onanimationend = handleNextQuestion;
    return () => {
      if (animation) animation.onanimationend = null;
    };
  }, [animationRef.current]);

  const canPlay = useMemo(() => {
    if (!withError || !mediaExtension) return "supported";
    return canPlayExtension(mediaExtension.toLowerCase());
  }, [mediaExtension]);

  const handleReplay = () => {
    if (playerRef.current) {
      playerRef.current.play();
      setAnimated(true);
    }
  };
  const handleCancel = () => setAnimated(false);

  const onVolumeChange = () => {
    const video = document.querySelector("video");

    store.dispatch(
      updateVideoPreference(
        video.muted ? 0 : video.volume === 0 ? 1 : video.volume
      )
    );
  };

  const onRateChange = useCallback(() => {
    const video = document.querySelector("video");

    store.dispatch(
      updateProfileInfo({
        preferred_playback_speed: video.playbackRate
      })
    );
  }, []);

  useEffect(() => {
    const video = document.querySelector("video");

    video.muted = isIOS ? true : storeVolume === 0;
    video.volume = storeVolume > 1 ? 1 : parseFloat(storeVolume);

    video.addEventListener("volumechange", onVolumeChange);

    return () => {
      video.removeEventListener("volumechange", onVolumeChange);
    };
  }, [storeVolume]);

  useEffect(() => {
    const video = document.querySelector("video");
    video.addEventListener("ratechange", onRateChange);

    return () => {
      video.removeEventListener("ratechange", onRateChange);
    };
  }, [playbackSpeed]);

  const isCurrentQuestionError = useMemo(() => {
    const currentVideoErrors = videoErrors?.filter(
      item => typeof item?.error !== "undefined"
    );

    if (currentVideoErrors?.length <= 0) return false;

    const hasError = currentVideoErrors?.find(
      item => item?.questionId === questionNumber
    );

    return {
      hasError: hasError?.error !== "" && typeof hasError?.error !== "undefined",
      message: hasError?.error,
      isMediaUploadInterrupted: hasError?.isMediaUploadInterrupted
    };
  }, [videoErrors, questionNumber]);

  const getMediaHttpStatus = useCallback(async vidPlayer => {
    try {
      setHasLoaded(false);
      let currentError = `${vidPlayer?.error()?.message || ""} ${t("errors.pleaseRefresh")}`;
      let isMediaUploadInterrupted = false;

      if (sources?.[0]?.src?.includes("null#t=")) {
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`,
                isMediaUploadInterrupted: true
              }
            ],
            "questionId"
          ));
      }

      const response = await fetch(sources?.[0].src);

      if ([404, 403].includes(response?.status)) {
        currentError = `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`;
        isMediaUploadInterrupted = true;
      }

      if ([200, 201, 206].includes(response?.status)) {
        setHasLoaded(true);
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: currentError,
                isMediaUploadInterrupted: false,
                contactSupport: true
              }
            ],
            "questionId"
          ));
      }

      if (typeof setVideoErrors === "function" && ![200, 201, 206]?.includes(response?.status)) {
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: String(currentError || ""),
                isMediaUploadInterrupted,
                contactSupport: ![404, 403].includes(response?.status) && !isMediaUploadInterrupted
              }
            ],
            "questionId"
          ));
      }
    } catch (error) {
      console.log({ error });
      Sentry.captureException(error);

      setVideoErrors(prevErrors =>
        removeDuplicates(
          [
            ...(prevErrors ?? []),
            {
              questionId: questionNumber,
              error: "Unable to load the media. Please refresh or contact support.",
              isMediaUploadInterrupted: false,
              contactSupport: false
            }
          ],
          "questionId"
        ));
    }
  }, [questionNumber]);

  useEffect(() => {
    let isMounted = true;

    if (window.videojs && isMounted) {
      isMounted = false;

      window.videojs.options.vhs.overrideNative = overrideNative;
      window.videojs.options.html5.vhs = {
        overrideNative
      };
      window.videojs.options.html5.nativeAudioTracks = !overrideNative;
      window.videojs.options.html5.nativeVideoTracks = !overrideNative;
      window.videojs.options.html5.nativeTextTracks = !overrideNative;

      player = window.videojs(
        videoId,
        {
          plugins: {
            ...(withDownload
              ? {
                  vjsdownload: {
                    beforeElement: "playbackRateMenuButton",
                    textControl: "Download video",
                    name: "downloadButton",
                    downloadURL: downloadSource?.[0]?.src
                  }
                }
              : null),
            ...(onlyAudio
              ? {
                  wavesurfer: {
                    cursorColor: "#1E384B",
                    progressColor: "#1e384b",
                    waveColor: "#CAD0D6",
                    barGap: 3,
                    barHeight: 1.3,
                    barMinHeight: 1,
                    barRadius: 0,
                    barWidth: 3,
                    cursorWidth: 1,
                    height: 200,
                    backend: "MediaElement",
                    plugins: [
                      CursorPlugin.create({
                        showTime: true,
                        opacity: 1,
                        customShowTimeStyle: {
                          "background-color": "#000",
                          color: "#fff",
                          padding: "2px",
                          "font-size": "10px"
                        },
                        formatTimeCallback: customTimeFormat
                      })
                    ]
                  }
                }
              : null)
          },
          ...(withDownload
            ? {
                html5: {
                  vhs: {
                    overrideNative
                  },
                  nativeVideoTracks: !overrideNative,
                  nativeAudioTracks: !overrideNative,
                  nativeTextTracks: !overrideNative
                }
              }
            : null)
        },
        () => {
          window.videojs.setFormatTime(customTimeFormat);
          player.on("loadeddata", () => {
            if (setSettings) {
              player.volume(settings.volume);
              player.playbackRate(playbackSpeed);
            }

            if (player.duration() === Infinity) {
              player.currentTime(6000);
              player.one("timeupdate", () => {
                player.currentTime(
                  setCurrentTime
                    ? currentTime === player.duration()
                      ? 0
                      : currentTime
                    : 0
                );

                if (setCurrentTime) {
                  player.on("timeupdate", () => {
                    setCurrentTime(player.currentTime());
                  });
                }
              });
            } else if (setCurrentTime) {
              if (currentTime) {
                player.currentTime(
                  currentTime === player.duration() ? 0 : currentTime
                );
              }

              player.on("timeupdate", () => {
                setCurrentTime(player.currentTime());
              });
            }
          });

          if (isIOS) {
            player.one("loadedmetadata", () => {
              setHasLoaded(true);
            });

            player.one("timeupdate", () => {
              if (player.currentTime() >= 0.001) {
                player.pause();
                player.currentTime(0);

                if (isIOS) {
                  const video = document.querySelector("video");
                  video.muted = storeVolume === 0;
                }

                player.off("timeupdate");
              }
            });
          }

          if (onlyAudio) {
            if (player.autoplay()) player.autoplay("any");
            player.src(sources?.[0]);
          }

          const handleUpdate = () => {
            if (setSettings) {
              setSettings({
                volume: player.volume(),
                playbackRate: player.playbackRate()
              });
            }

            const playbackRatesMenu = player.controlBar.getChild("playbackRateMenuButton");

            if (playbackRatesMenu) {
              playbackRatesMenu.update();
            }
          };

          player.on("volumechange", handleUpdate);
          player.on("ratechange", handleUpdate);

          if (withNext) {
            player.on("play", () => {
              setVisibleReplay(false);
            });
            player.on("ended", () => {
              setVisibleReplay(true);
            });
          }

          player.on("canplay", () => {
            if (sources?.[0]?.type?.includes("application/x-mpegURL") ||
              sources?.[0]?.type?.includes("audio/mp3")) setHasLoaded(true);
          });

          player.on("canplaythrough", () => {
            if (sources?.[0]?.type?.includes("video/mp4")) setHasLoaded(true);
          });
        }
      );

      playerRef.current = player;

      player.on("loadedmetadata", () => {
        if (typeof onReady === "function") onReady();

        if (!onlyAudio) {
          const isPortrait = player.videoHeight() > player.videoWidth();
          setIsVideoPortrait(isPortrait);
        }
      });

      if (isMobile) {
        player.controlBar.volumePanel.volumeControl.volumeBar.hide();
        player.on("touchstart", function (e) {
          if (e.target.nodeName === "VIDEO") {
            if (player.paused()) {
              this.play();
            } else {
              this.pause();
            }
          }
        });
      }

      player.on("error", () => {
        try {
          const currentError = player.error();

          if (!isCurrentQuestionError?.hasError) {
            if (currentError?.code === 4 && !isReloaded) {
              setHasLoaded(false);
              return getMediaHttpStatus(player);
            }

            const errorMessage = isReloaded
              ? `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`
              : `${currentError?.message || ""} ${t("errors.pleaseRefresh")}`;

            return setVideoErrors(prevErrors =>
              removeDuplicates(
                [
                  ...(prevErrors ?? []),
                  {
                    questionId: questionNumber,
                    error: errorMessage,
                    contactSupport: !isReloaded
                  }
                ],
                "questionId"
              ));
          }
        } catch (errorMess) {
          console.log({ playerErrors: errorMess });
        } finally {
          setHasLoaded(true);
        }
      });

      return () => {
        isMounted = true;
        if (player) {
          player.dispose();
        }
      };
    }
  }, []);

  const message = {
    subject: t("errors.errorFallback.email.subject", { error: "Error: Failed to load media" }),
    text: t("errors.errorFallback.email.body")
  };

  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };

  const shouldShowErrorMessage = isReloaded || (
    isCurrentQuestionError?.hasError &&
    (isCurrentQuestionError?.isMediaUploadInterrupted || hasLoaded)
  );

  useEffect(() => {
    if (isFirebaseInitialized && typeof updateFirebaseData === "function" && !shouldShowErrorMessage) {
      const video = document.querySelector("video");

      video.addEventListener("playing", () => updateFirebaseData({ is_played: true, questionId: questionKey }));

      return () => {
        video.removeEventListener("playing", updateFirebaseData);
      };
    }
  }, [isFirebaseInitialized, shouldShowErrorMessage]);

  return (
    <div
      className={`videojs__player-container ${withNext &&
        isVisibleReplay &&
        "ended"}`}
    >
      {isCandidate || onlyAudio || canPlay ? null : (
        <Message error message={withError} />
      )}
      <div data-vjs-player type="button" style={style}>
        {isMuted &&
          process.env.REACT_APP_DETECT_MUTE !== "false" && (
            <div className="no-audio-wrapper">
              <div className="no-audio-text" />
              {t("candidate.videoCreate.noAudio")}
            </div>
          )}

        {shouldShowErrorMessage && (
          <div className="video-custom-error-wrapper">
            <span className="video-custom-error">
              {
                videoErrors?.find(item => item?.questionId === questionNumber)
                  ?.error || `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`
              }

              {
                videoErrors?.find(item => item?.questionId === questionNumber)?.contactSupport && (
                  <button className="button__without-styles contact-support" type="button" onClick={openBeacon}>
                    {t("link.needSupport")}
                  </button>
                )
              }
            </span>
          </div>
        )}

        {!hasLoaded && (!isCurrentQuestionError?.hasError || !isCurrentQuestionError) && (
          <div
            className="processing-wrapper"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              background: "black",
              opacity: 0.7,
              zIndex: 2,
              top: 0
            }}
          >
            <BeatLoader color="#BBC2C9" size={10} margin={1} loading />
          </div>
        )}
        <video
          id={videoId}
          className={`video-js vjs-theme-forest video__preview-item-player ${className} ${isMobile &&
            "video-js-mobile"} ${onlyAudio ? "audio-js" : ""} ${
            isCandidate ? (onlyAudio ? "candidate-audio" : "candidate") : ""
          }`}
          controls
          preload="auto"
          width="640"
          height="264"
          data-setup={JSON.stringify(rest)}
          ref={videoRef}
          style={hasLoaded && !isVideoPortrait ? { backgroundColor: "transparent" } : {}}
        >
          {onlyAudio
            ? null
            : sources?.map(source => (
              <source key={source.src} src={source.src} type={source.type} />
              ))}
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            {" "}
            <a
              href="http://videojs.com/html5-video-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
      <div className="videojs__replay-container">
        <button
          className="button__without-styles videojs__replay-button"
          onClick={handleReplay}
          type="button"
        >
          <Replay />
        </button>
        {
          showScorecardButton &&
          <button
            className={`button__without-styles videojs__replay-next ${animated &&
            "animated"}`}
            onClick={() => handleGoToScorecard()}
            type="button"
            ref={animationRef}
          >
            <p className="n-font-medium n-font-medium-weight n-grey-60">
              {t("scorecard.completeScorecard")}
            </p>
          </button>
        }
        {handleNextQuestion && !showScorecardButton ? (
          <button
            className={`button__without-styles videojs__replay-next ${animated &&
              "animated"}`}
            onClick={handleNextQuestion}
            type="button"
            ref={animationRef}
          >
            <Play />
            <p className="n-font-medium n-font-medium-weight n-grey-60">
              {withNext}
            </p>
          </button>
        ) : null}
        {animated ? (
          <button
            className="button__without-styles videojs__replay-cancel n-white n-font-small n-font-regular"
            onClick={handleCancel}
            type="button"
          >
            {cancelButton}
          </button>
        ) : (
          <div className="button__without-styles videojs__replay-cancel" />
        )}
      </div>
    </div>
  );
};

VideoPlayer.defaultProps = {
  style: null,
  onReady: null,
  settings: {},
  setSettings: null,
  currentTime: 0,
  setCurrentTime: null,
  withNext: false,
  cancelButton: "",
  handleNextQuestion: null,
  sources: undefined,
  downloadSource: undefined,
  videoErrors: undefined,
  setVideoErrors: undefined,
  withError: false,
  updateFirebaseData: undefined,
  isFirebaseInitialized: false
};

VideoPlayer.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  downloadSource: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  withDownload: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  onReady: PropTypes.func,
  style: PropTypes.node,
  withError: PropTypes.string,
  mediaExtension: PropTypes.string.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  isCandidate: PropTypes.bool.isRequired,
  withNext: PropTypes.bool,
  cancelButton: PropTypes.string,
  settings: PropTypes.shape({
    volume: PropTypes.number,
    playbackRate: PropTypes.number
  }),
  setSettings: PropTypes.func,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  handleNextQuestion: PropTypes.func,
  currentQuestion: PropTypes.shape({
    is_muted: PropTypes.bool,
    is_reloaded: PropTypes.bool,
    remote_link: PropTypes.string
  }).isRequired,
  videoErrors: PropTypes.arrayOf(PropTypes.shape({})),
  setVideoErrors: PropTypes.func,
  showScorecardButton: PropTypes.bool.isRequired,
  handleGoToScorecard: PropTypes.func.isRequired,
  playbackSpeed: PropTypes.number.isRequired,
  updateFirebaseData: PropTypes.func,
  isFirebaseInitialized: PropTypes.bool
};

const mapStateToProps = state => ({
  volume: getCandidateVolume(state),
  playbackSpeed: isCandidateRoute ? 1 : getProfileInfo(state)?.preferred_playback_speed ?? 1
});

export default connect(mapStateToProps)(VideoPlayer);
