import { connect } from "react-redux";

import { signUp, confirmEmail, clearErrors } from "store/modules/auth/actions";

import { getIsLoading, getSessionError } from "store/modules/auth/selectors";
import { setLoadingScreen } from "store/modules/widgets/actions";

import SignUp from "views/Auth/SignUp";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  error: getSessionError(state),
  isLoadingScreen: state.widgets.isLoading
});

const mapDispatchToProps = dispatch => ({
  signUp: payload => dispatch(signUp(payload)),
  confirmEmail: payload => dispatch(confirmEmail(payload)),
  clearErrors: () => dispatch(clearErrors()),
  setLoadingScreen: payload => dispatch(setLoadingScreen(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
