/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
import React, { useCallback, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import ReactHtmlParser from "react-html-parser";

import TooltipSuccess from "components/Common/Tooltip/Success";
import Switcher from "components/Switcher";
import { copyTextFromButton } from "mixins/helpers";
import { Close, Question, SmallEye } from "mixins/svgIcons";
import { IDV_TYPES } from "configs/jobs/constants";

const Share = ({
  t,
  name = "",
  sharableLink = "",
  clearSharableLink,
  show,
  dismiss,
  answers,
  setAvailableAnswer,
  setAvailableLink,
  showcaseLinkOpenedAmount,
  isShowcaseLinkEnabled
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tootipText, setTooltipText] = useState("");

  const fieldWithInput = useRef(null);
  const buttonCopy = useRef(null);

  const handleClose = () => {
    clearSharableLink();
    dismiss();
  };

  const copyToClipboard = useCallback(() => {
    setTooltipText(t("modals.showCase.successTip"));
    setShowTooltip(true);
    copyTextFromButton(fieldWithInput);
  }, []);

  const handleShowTooltip = useCallback(() => {
    setTooltipText(isMobile ? t("modals.showCase.successTip") : t("modals.showCase.copyTip"));
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <Modal dialogClassName="share" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="share__block">
          <button type="button" className="share__close" onClick={handleClose}>
            <Close fill="#212B46" />
          </button>

          <h1 className="share__title n-font-giant n-font-semi-bold">
            {`
              ${t("modals.showCase.header")}
              ${name}
            `}
          </h1>
          <p className="share__text n-font-small n-black-100">
            {t("modals.showCase.textGenerate")}
          </p>
        </div>

        <div className="share-input__wrapper">
          <input
            id="share-input"
            type="text"
            name="email"
            className="share-input__field n-font-medium n-black-100"
            readOnly
            ref={fieldWithInput}
            value={sharableLink}
          />

          <button
            type="button"
            ref={buttonCopy}
            onClick={copyToClipboard}
            onMouseEnter={handleShowTooltip}
            onMouseLeave={handleHideTooltip}
            className="
              n-button__medium
              n-bg-purple-100
              n-white
            "
          >
            {t("button.copy")}
          </button>

          <TooltipSuccess
            position="bottom"
            message={tootipText}
            target={buttonCopy.current}
            show={showTooltip}
          />
        </div>

        <div className="share__question-item">
          <Col md={6} className="share__row">
            <p className="share__info n-font-small n-black-50">{t("modals.showCase.linkEnabled")}</p>
            <Switcher
              checked={isShowcaseLinkEnabled}
              handleOn={() => setAvailableLink(false)}
              handleOff={() => setAvailableLink(true)}
            />
          </Col>
          <Col md={6} className="share__row">
            <SmallEye width="20" height="15" fill="#9095A3" />
            <p className="share__info n-font-small n-black-50">{`${showcaseLinkOpenedAmount} ${t("modals.showCase.views")}`}</p>
          </Col>
        </div>

        <div className="share__question">
          {answers.map(({
            question: { text, answer_type },
            is_showcase_available: isAvailable,
            key
          }, index) => (
            <div className="share__question-item" key={key}>
              <Col md={11}>
                <p className="n-font-small n-black-50">
                  <Question svgIconClass="share__question-icon" />
                  <span>{`${t("candidate.common.question")} ${index + 1}`}</span>
                </p>
                <p className="share__question-text n-font-small n-black-100">{ReactHtmlParser(text)}</p>
              </Col>
              <Switcher
                checked={IDV_TYPES?.map(a => a.value).includes(answer_type) ? false : isAvailable}
                disabled={IDV_TYPES?.map(a => a.value).includes(answer_type)}
                handleOn={() => setAvailableAnswer({
                  available: false,
                  answerId: key
                })}
                handleOff={() => setAvailableAnswer({
                  available: true,
                  answerId: key
                })}
              />
            </div>
          ))}
        </div>

        <div className="share__bottom">
          <p className="share__text n-font-small n-black-50">
            {t("modals.showCase.textContent")}
          </p>
          <p className="share__text n-font-small n-black-50">
            {t("modals.showCase.sharing")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

Share.defaultProps = {
  name: undefined,
  sharableLink: undefined,
  show: undefined,
  dismiss: undefined,
  clearSharableLink: undefined,
  answers: [],
  setAvailableAnswer: undefined,
  setAvailableLink: undefined,
  showcaseLinkOpenedAmount: 0,
  isShowcaseLinkEnabled: false
};

Share.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  sharableLink: PropTypes.string,
  show: PropTypes.bool,
  dismiss: PropTypes.func,
  clearSharableLink: PropTypes.func,
  setAvailableAnswer: PropTypes.func,
  setAvailableLink: PropTypes.func,
  showcaseLinkOpenedAmount: PropTypes.number,
  isShowcaseLinkEnabled: PropTypes.bool,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      is_showcase_available: PropTypes.bool,
      question: PropTypes.shape({
        text: PropTypes.string
      })
    })
  )
};

export default confirmable(withTranslation()(Share));
