/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

import Table from "components/Common/Table";
import TooltipInfo from "components/Common/Tooltip/Info";
import { DATE_FORMATS, defaultTimeFormat } from "configs/dates";
import withLoader from "hoc/withLoader";
import { Avatar, Lock } from "mixins/svgIcons";
import CANDIDATE_STATUSES from "configs/candidates";
import { getDiffDateToday } from "mixins/helperDateConversion";

import InterviewScorecardPill from "views/Jobs/Candidate/InterviewScorecardPill";
import { getRatingToString } from "mixins/helperCandidate";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { Up, Down } from "./svgIcons";
import "./styles.scss";

export const HeaderComponent = ({
  title,
  ordering,
  onChangeOrdering,
  desc,
  asc
}) => (
  <>
    <span>{title}</span>
    <button
      type="button"
      className="button__without-styles people__table-button"
      onClick={() => onChangeOrdering(`${desc ? "-" : ""}${ordering}`)}
    >
      <Up />
    </button>
    <button
      type="button"
      className="button__without-styles people__table-button"
      onClick={() => onChangeOrdering(`${asc ? "-" : ""}${ordering}`)}
    >
      <Down />
    </button>
  </>
);

HeaderComponent.defaultProps = {
  asc: undefined,
  desc: undefined
};

HeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.bool,
  asc: PropTypes.bool,
  ordering: PropTypes.string.isRequired,
  onChangeOrdering: PropTypes.func.isRequired
};

const People = ({
  t,
  currentUser: { key },
  fetchPeople,
  fetchLatestPeople,
  peopleData,
  dataPagination: { next, count },
  isLoadingLatest,
  isLoading,
  isAbiltyСreate,
  filter,
  clearFilter,
  updated,
  setUpdated,
  ordering,
  setOrdering,
  history: vHistory
}) => {
  const firstUpdate = useRef(true);

  const fetchData = (currentUserKey, newFilter, newOrdering) => {
    if (currentUserKey) {
      fetchPeople({
        key: currentUserKey,
        filter: newFilter,
        ordering: newOrdering,
        withFilterUpdate: false
      });
    }
  };

  const fetchDataDebounce = useCallback(
    debounce((...args) => {
      fetchData(...args);
    }, 500),
    []
  );

  useEffect(() => {
    clearFilter();
  }, [key]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      fetchData(key, filter, false);
      return;
    }
    if (updated) {
      setUpdated(false);
    } else {
      fetchDataDebounce(key, filter, false);
    }
  }, [key, filter]);

  const handleBillingModal = () =>
    (!isAbiltyСreate
      ? false
      : window.open(
          "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_response",
          "_blank"
        ));
  const handleChangeOrdering = newOrdering => {
    if (newOrdering !== ordering) {
      fetchData(key, filter, newOrdering, false);
      setOrdering(newOrdering);
    }
  };

  return (
    <Table
      t={t}
      next={next}
      fetchLatestItems={fetchLatestPeople}
      isLoading={isLoadingLatest}
      isLoadingTable={isLoading}
      count={count}
      header={[
        {
          accessor: false,
          width: 70,
          Cell: ({
            original: {
              avatar_image_remote_link: avatarImage,
              anonymized_at: anonymizedAt,
              is_locked: isLocked,
              status
            }
          }) =>
            (isLocked && status !== CANDIDATE_STATUSES.INVITED ? (
              <TooltipInfo
                prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                position="right"
                message={t("plan.unlock")}
                element={
                  <div className="people__table-avatar people__table-avatar-empty">
                    <Lock />
                  </div>
                }
              />
            ) : (
              <SkeletonWrapper
                skeletonProps={{
                  circle: true,
                  width: 40,
                  height: 40,
                  enableAnimation: !anonymizedAt
                }}
                anonymizedDate={anonymizedAt}
                isLoading={false}
              >
                <>
                  {
                    avatarImage ? (
                      <img
                        src={avatarImage}
                        className="people__table-avatar"
                        alt="avatar"
                        onMouseEnter={event => {
                          const img = event.target;
                          if (img?.src) img.src = avatarImage;
                        }}
                        onMouseLeave={event => {
                          const img = event.target;
                          if (img?.src) img.src = avatarImage;
                        }}
                      />
                    ) : (
                      <div className="people__table-avatar people__table-avatar-empty">
                        <Avatar svgIconClass="n-icon__small right" />
                      </div>
                    )
                  }
                </>
              </SkeletonWrapper>
            ))
        },
        {
          Header: (
            <HeaderComponent
              title={t("people.table.name")}
              ordering="name,-submitted_at,job_title"
              asc
              onChangeOrdering={handleChangeOrdering}
            />
          ),
          accessor: "name",
          headerClassName: "left-align-column",
          minWidth: 200,
          Cell: ({
            original: {
              name,
              email,
              key: candidateKey,
              job_key: jobKey,
              is_locked: isLocked,
              status,
              anonymized_at: anonymizedAt
            }
          }) => (
            <SkeletonWrapper
              skeletonProps={{
                count: 2,
                enableAnimation: !anonymizedAt,
                height: 16
              }}
              skeletonWrapperProps={{
                style: {
                  display: "block",
                  lineHeight: 1.4,
                  width: 170
                }
              }}
              anonymizedDate={anonymizedAt}
              isLoading={false}
            >
              <Link
                className="people__table-link"
                to={{ pathname: `/jobs/candidate/${candidateKey}/${jobKey}` }}
                onClick={event => {
                  if (isLocked && status !== CANDIDATE_STATUSES.INVITED) {
                    event.preventDefault();
                    handleBillingModal();
                  }
                }}
                disabled={isLocked && status !== CANDIDATE_STATUSES.INVITED}
              >
                <>
                  <p className="people__table-text n-font-small n-font-medium-weight n-grey-60">
                    {name}
                  </p>
                  <p className="people__table-text n-font-extra-small n-font-regular n-black-50 without-hover">
                    {email}
                  </p>
                </>
              </Link>
            </SkeletonWrapper>
          ),
          style: { lineHeight: 26 }
        },
        {
          Header: (
            <HeaderComponent
              title={t("people.table.interview")}
              ordering="job_title,-submitted_at"
              asc
              onChangeOrdering={handleChangeOrdering}
            />
          ),
          accessor: "job_title",
          headerClassName: "left-align-column",
          minWidth: 200,
          Cell: ({
            original: {
              company_name: companyName,
              job_title: jobTitle,
              anonymized_at: anonymizedAt
            }
          }) => (
            <SkeletonWrapper
              skeletonProps={{
                count: 2,
                enableAnimation: !anonymizedAt,
                height: 16
              }}
              skeletonWrapperProps={{
                style: {
                  display: "block",
                  lineHeight: 1.4,
                  width: 170
                }
              }}
              anonymizedDate={anonymizedAt}
              isLoading={false}
            >
              <>
                <p className="people__table-text n-font-small n-font-medium-weight n-grey-60">
                  {jobTitle}
                </p>
                <p className="people__table-text n-font-extra-small n-font-regular n-black-50">
                  {companyName}
                </p>
              </>
            </SkeletonWrapper>
          ),
          style: { lineHeight: 26 }
        },
        {
          Header: (
            <HeaderComponent
              title={t("people.table.submitted")}
              ordering="submitted_at,name"
              desc
              onChangeOrdering={handleChangeOrdering}
            />
          ),
          accessor: "submitted_at",
          headerClassName: "left-align-column",
          minWidth: 150,
          Cell: ({
            original: {
              passed_subscription_at: passedSubscriptionAt,
              anonymized_at: anonymizedAt
            }
          }) =>
            (
              <SkeletonWrapper
                skeletonProps={{
                  count: 2,
                  enableAnimation: !anonymizedAt,
                  height: 16
                }}
                skeletonWrapperProps={{
                  style: {
                    display: "block",
                    lineHeight: 1.4,
                    width: 90
                  }
                }}
                anonymizedDate={anonymizedAt}
                isLoading={false}
              >
                <>
                  {
                    passedSubscriptionAt ? (
                      <>
                        <p className="people__table-text n-font-small n-font-medium-weight n-grey-60">
                          {moment(passedSubscriptionAt).format(
                            DATE_FORMATS.SLASH_SEPARATOR
                          )}
                        </p>
                        <p className="people__table-text n-font-extra-small n-font-regular n-black-50">
                          {defaultTimeFormat(moment(passedSubscriptionAt))}
                        </p>
                      </>
                    ) : null
                  }
                </>
              </SkeletonWrapper>
            ),
          style: { lineHeight: 26 }
        },
        {
          Header: (
            <HeaderComponent
              title={t("people.table.status")}
              ordering="status,-submitted_at"
              asc
              onChangeOrdering={handleChangeOrdering}
            />
          ),
          accessor: "status",
          headerClassName: "left-align-column",
          minWidth: 150,
          Cell: ({
            original: {
              status,
              stage_changed_at: stageChangedAt,
              is_expired: isExpired,
              expired_at: expiredAt,
              anonymized_at: anonymizedAt
            }
          }) => {
            const updateAt = isExpired ? expiredAt : stageChangedAt;
            const message = getDiffDateToday(t, updateAt, vHistory);
            const currentStatus = isExpired ? "Expired" : status;

            return (
              <SkeletonWrapper
                skeletonProps={{
                  count: 1,
                  enableAnimation: !anonymizedAt,
                  borderRadius: 12.5,
                  height: 24,
                  width: 90
                }}
                skeletonWrapperProps={{
                  style: {
                    display: "block",
                    lineHeight: 1.4
                  }
                }}
                anonymizedDate={anonymizedAt}
                isLoading={false}
              >
                <TooltipInfo
                  prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                  position="right"
                  message={message}
                >
                  <div className="people__table-status">
                    <p
                      className={`people__table-status-title n-font-extra-small n-font-regular ${currentStatus}`}
                    >
                      {t(`people.table.${currentStatus}`)}
                    </p>
                  </div>
                </TooltipInfo>
              </SkeletonWrapper>
            );
          }
        },
        {
          Header: (
            <HeaderComponent
              title={t("people.table.rating")}
              ordering="avg_rank,-submitted_at"
              desc
              onChangeOrdering={handleChangeOrdering}
            />
          ),
          accessor: "rounded_avg_rank",
          headerClassName: "left-align-column",
          minWidth: window.innerWidth >= 820 ? 140 : 150,
          Cell: ({ original: {
            rounded_avg_rank: avgRank,
            ranks_amount: ranksAmount,
            anonymized_at: anonymizedAt
          } }) => {
            const score = Math.floor(avgRank);
            const rating = getRatingToString({
              t,
              rating: score,
              isActive: true
            });

            return (
              <SkeletonWrapper
                skeletonProps={{
                  count: 1,
                  enableAnimation: !anonymizedAt,
                  borderRadius: 12.5,
                  height: 24,
                  width: 90
                }}
                skeletonWrapperProps={{
                  style: {
                    display: "block",
                    lineHeight: 1.4
                  }
                }}
                anonymizedDate={anonymizedAt}
                isLoading={false}
              >
                <TooltipInfo
                  prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                  position="left"
                  message={rating?.untranslated_label ? t(`scorecard.people.rating.${rating?.untranslated_label ?? ""}`) : null}
                  disabled={!rating?.untranslated_label}
                >
                  <div className="people__table-rating">
                    <div className="people__table-rating-stars">
                      <InterviewScorecardPill score={score} total={ranksAmount} fontColor="#595959" fontSize={12} />
                    </div>
                  </div>
                </TooltipInfo>
              </SkeletonWrapper>
            );
          }
        }
      ]}
      body={peopleData}
    />
  );
};

People.defaultProps = {
  filter: {
    title: "",
    companies: [],
    jobs: [],
    owners: [],
    ratings: [],
    statuses: []
  },
  original: {},
  history: undefined
};

People.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    plan_name: PropTypes.string
  }).isRequired,
  original: PropTypes.shape({
    avatar_image_remote_link: PropTypes.string,
    anonymized_at: PropTypes.string,
    rounded_avg_rank: PropTypes.number,
    company_name: PropTypes.string,
    email: PropTypes.string,
    job_title: PropTypes.string,
    job_key: PropTypes.string,
    is_locked: PropTypes.bool,
    key: PropTypes.string,
    name: PropTypes.string,
    passed_subscription_at: PropTypes.string,
    ranks_amount: PropTypes.number,
    status: PropTypes.string,
    avatar: PropTypes.string,
    stage_changed_at: PropTypes.string,
    expired_at: PropTypes.string,
    is_expired: PropTypes.string
  }),
  peopleData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataPagination: PropTypes.shape({
    next: PropTypes.string,
    count: PropTypes.number
  }).isRequired,
  fetchPeople: PropTypes.func.isRequired,
  fetchLatestPeople: PropTypes.func.isRequired,
  isLoadingLatest: PropTypes.bool.isRequired,
  isAbiltyСreate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }),
  clearFilter: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  setUpdated: PropTypes.func.isRequired,
  ordering: PropTypes.string.isRequired,
  setOrdering: PropTypes.func.isRequired,
  history: PropTypes.shape({})
};

export default withTranslation()(withLoader(People));
