/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { isIOS, isMobile, osVersion } from "react-device-detect";

import VideoItem from "components/Video/Item";
import FileItemWithUpload from "components/UploadFile/ItemWithUpload";
import FileItem from "components/UploadFile/Item";
import TextItem from "components/TextQuestion/Item";
import ContentExpired from "components/ContentExpired";
import AnswerList from "components/Answer/List";
import { isCandidateCanRetakeVideo, shouldLoadM3u8 } from "mixins/helperVideoRecording";

import "./styles.scss";
import IDVCheckItem from "components/IDVCheck";
import {
  ALL_IDENTITY_VERIFICATION_TYPES,
  ANSWER_STATUS,
  FILE_TYPE,
  INITIAL_TYPE
} from "configs/jobs/constants";
import { nanoid } from "nanoid";
import { getIsCurrentCandidateScored, getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";
import MultipleChoicePreview from "components/MultipleChoiceQuestion/item/MultipleChoicePreview";
import AnswerHeader from "components/Common/AnswerHeader";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { isPublicInfoLoading } from "store/modules/public/selectors";

const Interview = ({
  userId,
  jobId,
  answers,
  changeCandidateFile,
  changeCandidateFileError,
  error,
  withUpload,
  buttonColor,
  companyInfo,
  history,
  retakeAnswerAfterFileUpload,
  withError,
  withButton,
  downloadAll,
  idvCheckData,
  realtimeAnswers,
  isRecruiter = false,
  handleGoToScorecard = () => { },
  updateFirebaseData = () => { },
  isFirebaseInitialized = false
}) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    answers?.[0]?.key
  );
  const [settings, setSettings] = useState({
    volume: 1,
    playbackRate: 1
  });
  const [times, setTimes] = useState({});
  const [autoplay, setAutoplay] = useState(false);
  const [videoErrors, setVideoErrors] = useState([]);
  const handleQuestionChange = question => {
    setAutoplay(true);
    setSelectedQuestionId(question.key);
  };

  const path = window.location.pathname;
  const isShowcase = path.includes("bulk-public-candidates") || path.includes("public-candidate");
  const isCandidateScored = getIsCurrentCandidateScored(store.getState());

  const currentQuestion = useMemo(() => {
    let index = 0;

    if (answers?.length) {
      index = answers.findIndex(a => a.key === selectedQuestionId);
    }

    if (index < 0) index = 0;

    return {
      ...answers[index],
      questionNumber: index + 1
    };
  }, [selectedQuestionId, answers]);

  const nextQuestion = useMemo(() => answers[currentQuestion?.questionNumber], [
    answers,
    currentQuestion
  ]);

  const isCurrentIndexLastVideoOrAudio = useMemo(() => {
    const filteredAnswers = answers.filter(
      a =>
        a.question?.answer_type === "audio" ||
        a.question?.answer_type === "video"
    );

    return filteredAnswers[filteredAnswers.length - 1]?.key === currentQuestion?.key;
  }, [answers, currentQuestion.key]);

  const showScorecardButton = useMemo(() => isCurrentIndexLastVideoOrAudio &&
     !isCandidateScored && isRecruiter && !isShowcase,
     [isCandidateScored, isCurrentIndexLastVideoOrAudio, isRecruiter, isShowcase]);

  const handleNextQuestion = nextQuestion
    ? () =>
        handleQuestionChange({
          ...nextQuestion,
          questionNumber: currentQuestion?.questionNumber + 1
        })
    : null;

  const getShowRetake = answer => {
    const {
      question: { max_retakes: maxRetakes } = {},
      attempt_number: attemptNumber
    } = answer || {};

    return isCandidateCanRetakeVideo(attemptNumber, maxRetakes);
  };

  // eslint-disable-next-line react/prop-types
  const shouldShowDownloadAll = useMemo(() => {
    let isValid = true;
    Object.keys(realtimeAnswers || {}).map(key => {
      const apiAnswer = answers?.find(a => a.key === key);

      if (
        [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(apiAnswer?.status) ||
        [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(realtimeAnswers?.[key]?.status) ||
        (ANSWER_STATUS.received && apiAnswer?.is_skipped) ||
        ((realtimeAnswers?.[key]?.status === ANSWER_STATUS.received ||
          realtimeAnswers?.[key]?.status === ANSWER_STATUS.unsafe) &&
          apiAnswer?.question?.answer_type === FILE_TYPE)
      ) {
        return;
      }

      isValid = false;
    });

    return isValid;
  }, [answers, realtimeAnswers]);

  const m3u8 = {
    src:
      currentQuestion?.playlist_remote_link &&
      `${currentQuestion?.playlist_remote_link}#t=0.001?${currentQuestion?.updated_at}`,
    type: "application/x-mpegURL"
  };

  const mp4 = {
    src: `${currentQuestion?.remote_link}#t=0.001?${currentQuestion?.updated_at}`,
    type: "video/mp4"
  };

  let videoSource = [m3u8, mp4];

  if (shouldLoadM3u8 && currentQuestion?.playlist_remote_link) videoSource = [m3u8];

  // No support for m3u8 on iOS 17.4
  if ((isIOS && parseFloat(osVersion) >= 17.4 && parseFloat(osVersion) <= 17.5 && shouldLoadM3u8) ||
    (shouldLoadM3u8 && !currentQuestion?.playlist_remote_link)) videoSource = [mp4];

  const downloadSource = [
    {
      src: `${currentQuestion?.remote_link}#t=0.001?${currentQuestion?.updated_at}`,
      type: "video/mp4"
    }
  ];

  const audioSource = [
    {
      src: `${currentQuestion?.remote_link}#${
        currentQuestion?.updated_at
      }${nanoid()}`,
      type: "audio/mp3"
    }
  ];

  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());
  const isShowcaseLoading = isPublicInfoLoading(store.getState());

  return (
    <Row className="candidate-overview__row preview-container">
      <Col md={7}>
        <div
          className="preview-item candidate-overview__interview-item"
          key={currentQuestion?.key}
        >
          {currentQuestion?.is_media_deleted &&
          currentQuestion?.status !== "unsafe" ? (
            <ContentExpired
              size={isMobile ? "" : "big"}
              textSize="small question-ellipsis"
              questionText={currentQuestion?.question?.text}
              questionNumber={currentQuestion?.questionNumber}
              thinkingTime={currentQuestion?.question?.thinking_time}
              spentTime={currentQuestion?.spent_time}
              currentQuestion={currentQuestion}
            />
          ) : currentQuestion?.question?.answer_type === "file" ? (
            withUpload ? (
              <FileItemWithUpload
                size="big"
                question={currentQuestion?.question}
                questionNumber={currentQuestion?.questionNumber}
                answer={currentQuestion}
                jobId={jobId}
                userId={userId}
                changeCandidateFile={changeCandidateFile}
                changeCandidateFileError={changeCandidateFileError}
                error={error}
                textSize="small question-ellipsis"
                thinkingTime={currentQuestion?.question?.thinking_time}
                spentTime={currentQuestion?.spent_time}
                currentQuestion={currentQuestion}
                isRecruiter={isRecruiter}
              />
            ) : (
              <>
                <FileItem
                  size="big"
                  textSize="small question-ellipsis"
                  withButton={withButton}
                  question={currentQuestion?.question}
                  questionNumber={currentQuestion?.questionNumber}
                  answer={currentQuestion}
                  thinkingTime={currentQuestion?.question?.thinking_time}
                  spentTime={currentQuestion?.spent_time}
                  jobId={jobId}
                  userId={userId}
                  history={history}
                  retakeAnswerAfterFileUpload={retakeAnswerAfterFileUpload}
                  memorizedCompanyInfo={companyInfo}
                  currentQuestion={currentQuestion}
                  status={realtimeAnswers?.[currentQuestion?.key]?.status}
                  isRecruiter={isRecruiter}
                />
              </>
            )
          ) : (currentQuestion?.question?.answer_type === "multiple_choice" || currentQuestion?.question?.answer_type === "checkboxes") ? (
            <MultipleChoicePreview
              size="big"
              textSize="small question-ellipsis"
              withButton={withButton}
              question={currentQuestion?.question}
              questionNumber={currentQuestion?.questionNumber}
              answer={currentQuestion}
              thinkingTime={currentQuestion?.question?.thinking_time}
              spentTime={currentQuestion?.spent_time}
              jobId={jobId}
              userId={userId}
              history={history}
              retakeAnswerAfterFileUpload={retakeAnswerAfterFileUpload}
              memorizedCompanyInfo={companyInfo}
              currentQuestion={currentQuestion}
              isRecruiter={isRecruiter}
            />
          )
          : currentQuestion?.question?.answer_type === "text" ? (
            <TextItem
              size="big"
              textSize="small question-ellipsis"
              withButton={withButton}
              question={currentQuestion?.question}
              questionNumber={currentQuestion?.questionNumber}
              answer={currentQuestion}
              thinkingTime={currentQuestion?.question?.thinking_time}
              spentTime={currentQuestion?.spent_time}
              jobId={jobId}
              userId={userId}
              history={history}
              retakeAnswerAfterFileUpload={retakeAnswerAfterFileUpload}
              memorizedCompanyInfo={companyInfo}
              currentQuestion={currentQuestion}
            />
          ) : ALL_IDENTITY_VERIFICATION_TYPES?.includes(
              currentQuestion?.question?.answer_type
            ) ? (
              <IDVCheckItem
                size="big"
                textSize="small question-ellipsis"
                withButton={withButton}
                question={currentQuestion?.question}
                questionNumber={currentQuestion?.questionNumber}
                answer={currentQuestion}
                thinkingTime={currentQuestion?.question?.thinking_time}
                spentTime={currentQuestion?.spent_time}
                jobId={jobId}
                userId={userId}
                history={history}
                retakeAnswerAfterFileUpload={retakeAnswerAfterFileUpload}
                memorizedCompanyInfo={companyInfo}
                idvCheckData={idvCheckData}
                currentQuestion={currentQuestion}
              />
          ) : [INITIAL_TYPE, "audio"]?.includes(
              currentQuestion?.question?.answer_type
            ) ? (
              <VideoItem
                handleGoToScorecard={handleGoToScorecard}
                key={currentQuestion?.key}
                size="small question-ellipsis"
                withButton={withButton}
                sources={
                  currentQuestion?.question?.answer_type === "audio" ? audioSource : videoSource
                }
                downloadSource={currentQuestion?.question?.answer_type === "audio" ? audioSource : downloadSource}
                questionText={currentQuestion?.question?.text}
                currentQuestion={currentQuestion}
                questionNumber={currentQuestion?.questionNumber}
                onlyAudio={currentQuestion?.question?.answer_type === "audio"}
                question={currentQuestion?.question}
                isCandidate
                settings={settings}
                setSettings={setSettings}
                currentTime={times[currentQuestion?.key]}
                setCurrentTime={time =>
                  setTimes({ ...times, [currentQuestion?.key]: time })
                }
                autoplay={autoplay}
                thinkingTime={currentQuestion?.question?.thinking_time}
                spentTime={currentQuestion?.spent_time}
                withDownload={withUpload}
                history={history}
                jobId={jobId}
                userId={userId}
                retakeAnswerAfterFileUpload={retakeAnswerAfterFileUpload}
                memorizedCompanyInfo={companyInfo}
                mediaExtension={currentQuestion?.media_extension}
                showRetake={getShowRetake(currentQuestion)}
                withError={withError}
                handleNextQuestion={handleNextQuestion}
                // eslint-disable-next-line react/prop-types
                status={realtimeAnswers?.[currentQuestion?.key]?.status}
                videoErrors={videoErrors}
                setVideoErrors={setVideoErrors}
                showScorecardButton={showScorecardButton}
                updateFirebaseData={updateFirebaseData}
                isFirebaseInitialized={isFirebaseInitialized}
              />
          ) : (
            <div className="video__preview-item">
              <AnswerHeader
                questionNumber=""
                thinkingTime=""
                spentTime=""
                textSize=""
                questionTextProps=""
                questionText=""
                currentQuestion={{}}
              />

              <SkeletonWrapper
                skeletonProps={{
                  count: 1,
                  enableAnimation: true,
                  height: "30vh",
                  borderRadius: 20
                }}
                skeletonWrapperProps={{
                  style: {
                    display: "block",
                    lineHeight: 1.5,
                    marginBottom: "0.5rem",
                    width: "100%"
                  }
                }}
                isLoading={isJobCandidateLoading || isShowcaseLoading}
              >
                <div />
              </SkeletonWrapper>

            </div>
          )}
        </div>
      </Col>
      <Col md={5}>
        <AnswerList
          answers={answers}
          currentQuestion={currentQuestion}
          setCurrentQuestion={handleQuestionChange}
          buttonColor={buttonColor}
          downloadAll={downloadAll}
          idvCheckData={idvCheckData}
          shouldShowDownloadAll={shouldShowDownloadAll}
          isRecruiter={isRecruiter}
        />
      </Col>
    </Row>
  );
};

Interview.defaultProps = {
  withUpload: true,
  buttonColor: null,
  withButton: false,
  downloadAll: null,
  realtimeAnswers: undefined,
  isRecruiter: false,
  companyInfo: undefined,
  history: undefined,
  retakeAnswerAfterFileUpload: undefined,
  withError: undefined,
  changeCandidateFile: undefined,
  changeCandidateFileError: undefined,
  error: undefined,
  updateFirebaseData: undefined,
  isFirebaseInitialized: false
};

Interview.propTypes = {
  userId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.shape({
        key: PropTypes.string
      }),
      remote_link: PropTypes.string,
      key: PropTypes.string,
      status: PropTypes.string
    })
  ).isRequired,
  changeCandidateFile: PropTypes.func,
  changeCandidateFileError: PropTypes.func,
  error: PropTypes.string,
  withUpload: PropTypes.bool,
  buttonColor: PropTypes.string,
  companyInfo: PropTypes.string,
  history: PropTypes.shape({}),
  retakeAnswerAfterFileUpload: PropTypes.func,
  withError: PropTypes.string,
  withButton: PropTypes.bool,
  downloadAll: PropTypes.func,
  idvCheckData: PropTypes.shape({
    isRecruiter: PropTypes.string,
    isLoading: PropTypes.bool,
    is_idv_skipped: PropTypes.bool
  }).isRequired,
  realtimeAnswers: PropTypes.shape({}),
  isRecruiter: PropTypes.bool,
  handleGoToScorecard: PropTypes.func.isRequired,
  updateFirebaseData: PropTypes.func,
  isFirebaseInitialized: PropTypes.bool
};

export default Interview;
