/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { UploadIcon, EditPencil } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";
import { useObject } from "react-firebase-hooks/database";

import { ref } from "firebase/database";
import StartFirebase from "configs/firebase";
import { get } from "lodash";
import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import { connect } from "react-redux";
import { getProfileInfo } from "store/modules/profile/selectors";
import { store } from "store";
import { fetchCandidateAnswer } from "store/modules/jobs/actions";
import { ClipLoader } from "react-spinners";
import { ANSWER_STATUS } from "configs/jobs/constants";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import Preview from "../Preview";
import Input from "../Input";
import Dropzone from "../Dropzone";
import InputName from "../InputName";
import formatBytes from "../helpers";

const Item = ({
  t,
  question,
  questionNumber,
  size,
  textSize,
  answer,
  questionTextProps,
  jobId,
  userId,
  changeCandidateFile,
  error,
  changeCandidateFileError,
  thinkingTime,
  spentTime,
  currentQuestion,
  isRecruiter = false,
  isMaliciousFileMuted
}) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [status, setStatus] = useState(null);
  const [fileSize, setSize] = useState(answer.size);
  const [name, setName] = useState(answer.friendly_name);
  const [mediaExtension, setExtension] = useState(answer.media_extension);
  const [isEdit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!isEdit);
  const [db, setDB] = useState();
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);

  const [snapshot] = useObject(
    db && userId ? ref(db, `Candidates/${userId}`) : null
  );

  const uploadStatus = useMemo(
    () => get(snapshot?.val(), `answers[${answer.key}].status`, ""),
    [answer.key, snapshot]
  );

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  const handleSave = newName => {
    setName(newName);
    toggleEdit();
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = answer.remote_link;
    a.download = `${name}.${mediaExtension}`;
    document.body.appendChild(a);
    a.click();
    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }
  };

  const handleClickDownload = () => {
    if (isMaliciousFileMuted) {
      return handleDownload();
    }
    return setShowUnsafeModal(true);
  };

  const saveFile = callback => {
    if (!file) return;

    const dataToUpload = {
      blob: file,
      jobId,
      userId,
      mediaExtension,
      name,
      size: fileSize,
      questionId: question.key,
      questionNumber,
      isRecruiter,
      callback: () => {
        setUploadProgress(null);
        callback();
      },
      onUploadProgress: percentage => setUploadProgress(percentage)
    };

    changeCandidateFile(dataToUpload);
  };

  const handleRemove = () => {
    setSize(answer.size);
    setName(answer.friendly_name);
    setExtension(answer.media_extension);
    setFile(null);
    setEdit(false);
    changeCandidateFileError("");
  };

  useEffect(() => {
    changeCandidateFileError("");
  }, []);

  useEffect(() => {
    const isReady = answer.key && userId !== "" && jobId !== "";

    if (
      isRecruiter &&
      isReady &&
      uploadStatus === ANSWER_STATUS.processed &&
      answer.remote_link === null
    ) {
      store.dispatch(
        fetchCandidateAnswer({ userId, jobId, answerId: answer.key })
      );
    }
  }, [uploadStatus, isRecruiter, answer, jobId, userId]);

  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());

  if (uploadStatus === "unsafe") {
    return (
      <div className="video__preview-item">
        <AnswerHeader
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={question?.text}
          currentQuestion={currentQuestion}
        />

        <SkeletonWrapper
          skeletonProps={{
            count: 1,
            enableAnimation: true,
            height: "30vh",
            borderRadius: 20
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isJobCandidateLoading}
        >
          <div className={`dzu-dropzone ${size}`}>
            <div className="dzu-dropzone__unsafe">
              <div className={`${size} dzu-dropzone__unsafe-text-container`}>
                <div className="dzu-dropzone__unsafe-text">
                  {t("candidate.videoCreate.mediaUnsafe")}
                </div>
                <div className="dzu-dropzone__unsafe-content">
                  <div className="dzu-dropzone__unsafe-text">
                    {t("notFound.contact")}
                  </div>
                  <div className="dzu-dropzone__unsafe-email">
                    support@willo.video
                  </div>
                  <div className="dzu-dropzone__unsafe-text">
                    {t("notFound.moreInformation")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SkeletonWrapper>
      </div>
    );
  }

  if (uploadStatus === "received") {
    return (
      <div className="video__preview-item">
        <AnswerHeader
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={question?.text}
          currentQuestion={currentQuestion}
        />

        <SkeletonWrapper
          skeletonProps={{
            count: 1,
            enableAnimation: true,
            height: "30vh",
            borderRadius: 20
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isJobCandidateLoading}
        >
          <div className={`dzu-dropzone ${size}`}>
            <div className="dzu-dropzone__unsafe">
              <div className="dzu-dropzone__unsafe-text-container">
                <div className="dzu-dropzone__unsafe-text">
                  <strong>{t("unsafeNotice.scanning")}</strong>
                </div>
              </div>
            </div>
          </div>
        </SkeletonWrapper>
      </div>
    );
  }

  return (
    <div className="video__preview-item">
      {showUnsafeModal && (
        <UnsafeModal
          onDownload={handleDownload}
          handleCloseModal={() => setShowUnsafeModal(false)}
        />
      )}
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={question?.text}
        currentQuestion={currentQuestion}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading}
      >
        <Dropzone
          setName={setName}
          setFile={setFile}
          setExtension={setExtension}
          setSize={setSize}
          setStatus={setStatus}
          classNames={{
            dropzone: `dzu-dropzone upload ${size}`
          }}
          InputComponent={props => (
            <Input
              inputFileName={`file${questionNumber}`}
              {...props}
              ButtonComponent={
                <div className={`dzu-dropzone ${size}`}>
                  <div className="dzu-previewContainer">
                    <div className="dzu-previewFileNameContainer">
                      <div className="dzu-iconFile">
                        <UploadIcon
                          svgIconClass="not-clickable-icon"
                          width="40"
                          height="30"
                          fill="#595959"
                        />
                      </div>
                      <div className="dzu-previewFileNameIcon">
                        <span className="dzu-previewFileName">{`${name}.${mediaExtension}`}</span>
                      </div>
                    </div>
                    <div className="dzu-removeButtonContainer">
                      {answer.remote_link ? (
                        <>
                          <a
                            className="dzu-removeButton view"
                            onClick={handleClickDownload}
                          >
                            {t("button.view")}
                          </a>
                          <span className="dzu-span">
                            {t("candidate.videoCreate.or")}
                          </span>
                          <label
                            className="dzu-removeButton view"
                            htmlFor={`file${questionNumber}`}
                          >
                            {t("button.change")}
                          </label>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 10
                          }}
                        >
                          <ClipLoader
                            color="#BBC2C9"
                            size={18}
                            margin={1}
                            loading
                            style={{
                              marginRight: 5
                            }}
                          />
                          &nbsp;
                          {" "}
                          {t("job.candidate.overview.idv.check.processing")}
                        </div>
                      )}
                    </div>
                    <span className="dzu-message">
                      {t("candidate.videoCreate.pleaseNote")}
                    </span>
                    <div className={`dzu-percentContainer ${size}`}>
                      <span className="dzu-percent">
                        {formatBytes(fileSize || 0)}
                      </span>
                    </div>
                  </div>
                </div>
              }
            />
          )}
          PreviewComponent={props => (
            <Preview
              onSave={saveFile}
              onRemove={handleRemove}
              isEdit={isEdit}
              uploadProgress={uploadProgress}
              error={error ? t(error) : ""}
              {...props}
            >
              {isEdit ? (
                <InputName
                  name={name}
                  mediaExtension={mediaExtension}
                  onSave={handleSave}
                />
              ) : (
                <>
                  <span className="dzu-previewFileName">{`${name}.${mediaExtension}`}</span>
                  {status !== "preparing" &&
                    status !== "getting_upload_params" &&
                    status !== "uploading" &&
                    uploadProgress === null && (
                      <button
                        type="button"
                        onClick={toggleEdit}
                        className="button__delete"
                      >
                        <EditPencil />
                      </button>
                    )}
                </>
              )}
            </Preview>
          )}
        />
      </SkeletonWrapper>
    </div>
  );
};

Item.defaultProps = {
  jobId: "",
  userId: "",
  question: {},
  questionNumber: "",
  size: "",
  textSize: "",
  answer: {},
  questionTextProps: "",
  error: "",
  thinkingTime: 0,
  spentTime: 0,
  isRecruiter: false,
  changeCandidateFile: undefined
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  jobId: PropTypes.string,
  userId: PropTypes.string,
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  size: PropTypes.string,
  textSize: PropTypes.string,
  answer: PropTypes.shape({
    key: PropTypes.string,
    friendly_name: PropTypes.string,
    media_extension: PropTypes.string,
    size: PropTypes.number,
    remote_link: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  changeCandidateFile: PropTypes.func,
  error: PropTypes.string,
  changeCandidateFileError: PropTypes.func.isRequired,
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired,
  isRecruiter: PropTypes.bool,
  isMaliciousFileMuted: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isMaliciousFileMuted:
    getProfileInfo(state)?.is_malicious_file_message_muted || false
});

export default connect(mapStateToProps)(withTranslation()(Item));
