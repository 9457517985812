import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";

import { Close, DeleteIconConfirmation } from "mixins/svgIcons";

const ModalConfirm = ({
  t,
  show,
  proceed,
  dismiss,
  cancel,
  removeModal,
  title,
  confirmation
}) => (
  <Modal
    dialogClassName="confirm"
    show={show}
    onHide={() => {
      dismiss();
      removeModal();
    }}
  >
    <Modal.Header>
      <button
        type="button"
        className="button__without-styles close"
        onClick={() => {
          dismiss();
          removeModal();
        }}
      >
        <Close />
      </button>
      <h4 className="n-font-small n-font-medium-weight n-black-80">{title}</h4>
    </Modal.Header>

    <Modal.Body>
      <div className="confirm__icon">
        <DeleteIconConfirmation />
      </div>
      {confirmation}
    </Modal.Body>

    <Modal.Footer>
      <div className="button__group_center">
        <button
          type="button"
          className="
            n-button__small-border
            n-border-black-100
            n-black-100
          "
          onClick={() => {
            cancel();
            removeModal();
          }}
        >
          {t("button.cancel")}
        </button>
        <button
          type="button"
          className="
            n-button__small
            n-bg-red-100
            n-white
          "
          onClick={() => {
            proceed();
            removeModal();
          }}
        >
          {t("button.delete")}
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

ModalConfirm.defaultProps = {
  proceed: undefined,
  cancel: undefined,
  dismiss: undefined,
  removeModal: undefined,
  title: undefined,
  confirmation: undefined
};

ModalConfirm.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  removeModal: PropTypes.func,
  title: PropTypes.string,
  confirmation: PropTypes.string
};

export default confirmable(withTranslation()(ModalConfirm));
