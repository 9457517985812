/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import { history, store } from "store";
import CANDIDATE_STATUSES from "configs/candidates";
import Header from "containers/Common/HeaderContainer";
import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import { filterAnswers } from "mixins/helperCandidate";
import ArrowButtons from "components/Common/ArrowButtons";
import Interview from "components/Candidates/Interview";
import useActiveTabEffect from "hooks/useActiveTabEffect";
import {
  handleExpirationDate,
  handleExpirationLeft,
  handleExpirationWithToday
} from "mixins/helperJobCandidate";

import useAutoFetch from "hooks/useAutoFetch";
import { isNull } from "lodash";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import StartFirebase from "configs/firebase";
import ContentExpired from "components/ContentExpired";
import { fetchCandidatesByStatusListReset } from "store/modules/jobs/actions";
import { fetchCandidateScoreByCardIdResponse, shouldShowScorecard } from "store/modules/scorecards/action";
import { getShouldShowScorecard } from "store/modules/scorecards/selectors";
import Overview from "./Overview";
import Contacts from "./Contacts";
import Availability from "./Availability";
import Activites from "./Activites";
import Buttons from "./Buttons";
import "./styles.scss";

const Candidate = ({
  t,
  match: {
    params: { userId = null, jobId = null }
  },
  location: { search = "" },
  jobCandidateInfo,
  jobCandidateInfo: {
    is_locked: isLocked = false,
    key = "",
    name = "",
    email = "",
    status = "",
    availabilities = [],
    answers_days_lifetime: answersDaysLifetime = 180,
    utc_minutes_delta: utcMinutesDelta = null,
    is_expired: isExpired,
    anonymized_at: anonymizedAt
  },
  currentUser,
  jobCurrent,
  isAdmin,
  profileInfo,
  sharableLink,
  showcaseLinkOpenedAmount = 0,
  isShowcaseLinkEnabled,
  history: vHistory,
  answers,
  comments,
  shareCandidatePublic,
  clearSharableLink,
  updateCandidateStatus,
  sendReminderCandidate,
  sendComment,
  updateComment,
  deleteComment,
  setAvailableAnswer,
  setAvailableLink,
  changeCandidateFile,
  changeCandidateFileError,
  deleteCandidate,
  error = "",
  interviewersList,
  fetchCandidateInformation,
  downloadAll,
  idvCheckData,
  fetchCandidateIdv,
  isJobLoading,
  isLoadingScreen
}) => {
  const query = new URLSearchParams(search);
  const backPath = query.get("back");
  const contentRef = useRef(null);
  const shouldRedirectToScorecard = getShouldShowScorecard(store.getState()) ?? false;
  const [isDeleteButtonVisible, setDeleteButtonVisible] = useState(true);
  const [activeTab, setActiveTab] = useState(() => shouldRedirectToScorecard ? "scorecard" : "comments");

  const fileteredAnswers = useMemo(() => filterAnswers(answers || []), [
    answers
  ]);

  const sortedAvailabilities = useMemo(
    () =>
      availabilities ? availabilities.sort((a, b) => moment(a).diff(b)) : null,
    [availabilities]
  );

  const sortedActivities = useMemo(() => {
    const commentActivities = comments.map(item => ({
      ...item,
      action_type: "Comment"
    }));
    const activities = [...vHistory, ...commentActivities].sort((a, b) =>
      moment(b.created_at).diff(a.created_at)
    );
    return activities;
  }, [vHistory, comments]);

  const [db, setDB] = useState();
  const [snapshot] = useObject(
    db && userId ? ref(db, `Candidates/${userId}`) : null
  );

  useActiveTabEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    if (userId === key && isLocked && status !== CANDIDATE_STATUSES.INVITED) {
      history.push(`/jobs/overview/${jobId}`);
    }
  }, [isLocked, key]);

  useActiveTabEffect(() => {
    fetchCandidateInformation({ userId, jobId, backPath });
  }, [fetchCandidateInformation, jobId, userId]);

  const createdAt = fileteredAnswers[0] && fileteredAnswers[0].created_at;
  const expirationDate =
    createdAt && handleExpirationDate(createdAt, answersDaysLifetime);
  const expirationLeft =
    createdAt && handleExpirationLeft(createdAt, answersDaysLifetime);
  const isExpirationDayNotToday =
    createdAt && handleExpirationWithToday(createdAt, answersDaysLifetime);

  const handleHideDelete = useCallback(() => {
    setDeleteButtonVisible(false);
    setTimeout(() => setDeleteButtonVisible(true), 5000);
  }, []);

  const handleDelete = useCallback(
    () => deleteCandidate({ userId, jobId, agency: currentUser.key }),
    [deleteCandidate, userId, jobId, currentUser.key]
  );

  const handleGoToScorecard = async () => {
    setActiveTab("scorecard");
    setTimeout(() => {
      const scorecardTab = document.getElementById("scorecard-tabs");

      if (scorecardTab) {
        scorecardTab.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }

      return Promise.resolve(null);
    }, 200);
  };

  useEffect(() => {
    if (shouldRedirectToScorecard && !isJobLoading && !isLoadingScreen) {
      setTimeout(() => {
        handleGoToScorecard().then(() => store.dispatch(shouldShowScorecard(false)));
      }, 500);
    }
  }, [shouldRedirectToScorecard, isJobLoading, isLoadingScreen]);

  const handleSetSelected = id => {
    history.push(`/jobs/candidate/${id}/${jobId}`);
    store.dispatch(fetchCandidateScoreByCardIdResponse({}));
  };

  const currentIndex = interviewersList.findIndex(
    ({ key: interviewerKey }) => interviewerKey === userId
  );

  const nextIndex = Math.max(
    Math.min(currentIndex + 1, interviewersList.length - 1),
    0
  );

  const handleMoveToNext = [-1, interviewersList.length - 1].includes(
    currentIndex
  )
    ? null
    : () => handleSetSelected(interviewersList[nextIndex].key);

  const handleDownload = () => downloadAll(userId);

  const handleMoveToNextAfterAccept = () =>
    handleSetSelected(interviewersList[currentIndex + 1].key);

  const isDocumentProcessing =
    isNull(idvCheckData?.document?.document_type) ||
    isNull(idvCheckData?.document?.image_id);

  const isIdentityProcessing = isNull(idvCheckData?.identity?.image_id);

  useAutoFetch(() => {
    if (
      (isDocumentProcessing || isIdentityProcessing) &&
      !idvCheckData?.error &&
      userId !== "" &&
      jobId !== ""
    ) {
      fetchCandidateIdv({ userId, jobId });
    }
  }, 60000);

  useEffect(
    () => () => {
      store.dispatch(fetchCandidatesByStatusListReset());
    },
    []
  );

  return (
    <PageWrapper header={<Header />}>
      <div className="candidate-overview">
        <Container>
          <Col md={12}>
            <header className="candidate-overview__header" ref={contentRef}>
              {userId && (
                <ArrowButtons
                  currentIndex={currentIndex}
                  nextIndex={nextIndex}
                  items={interviewersList}
                  setSelected={handleSetSelected}
                />
              )}
              <Row className="candidate-overview__row" noGutters>
                <Col md={7}>
                  <Overview
                    userId={userId}
                    jobCandidateInfo={jobCandidateInfo}
                    jobCurrent={jobCurrent}
                    history={vHistory}
                    isJobLoading={isJobLoading}
                    handleGoToScorecard={handleGoToScorecard}
                  />
                </Col>
                <Col md={5}>
                  <Contacts
                    jobCandidateInfo={jobCandidateInfo}
                  />
                </Col>
              </Row>
            </header>

            {
              !anonymizedAt && (
                <Buttons
                  userId={userId}
                  jobId={jobId}
                  jobCandidateInfo={jobCandidateInfo}
                  currentUser={currentUser}
                  jobCurrent={jobCurrent}
                  isAdmin={isAdmin}
                  profileInfo={profileInfo}
                  sharableLink={sharableLink}
                  showcaseLinkOpenedAmount={showcaseLinkOpenedAmount}
                  isShowcaseLinkEnabled={isShowcaseLinkEnabled}
                  answers={fileteredAnswers}
                  shareCandidatePublic={shareCandidatePublic}
                  clearSharableLink={clearSharableLink}
                  updateCandidateStatus={updateCandidateStatus}
                  sendReminderCandidate={sendReminderCandidate}
                  setAvailableAnswer={setAvailableAnswer}
                  setAvailableLink={setAvailableLink}
                  deleteCandidate={deleteCandidate}
                  contentRef={contentRef}
                  moveToNext={handleMoveToNext}
                  handleMoveToNextAfterAccept={handleMoveToNextAfterAccept}
                  hasReminder={sortedActivities.some(
                    ({ action_type: actionType }) => actionType === "Reminder"
                  )}
                  handleGoToScorecard={handleGoToScorecard}
                />
              )
            }

            {
              anonymizedAt ? (
                <div style={{ marginBottom: 50, marginTop: 50 }}>
                  <ContentExpired
                    setCurrentQuestion={() => {}}
                    isCurrent
                    noHeader
                    size="big"
                  />
                </div>
              ) : (
                <>
                  {status !== CANDIDATE_STATUSES.INVITED ? (
                    <Tabs
                      defaultActiveKey="interview"
                      className="candidate-overview__tabs n-font-small"
                    >
                      <Tab eventKey="interview" title="Interview">
                        {
                          isExpired ? (
                            <div style={{ marginBottom: 50 }}>
                              <ContentExpired
                                setCurrentQuestion={() => {}}
                                isCurrent
                                noHeader
                                size="big"
                              />
                            </div>
                          ) : (
                            <Interview
                              userId={userId}
                              jobId={jobId}
                              answers={fileteredAnswers}
                              changeCandidateFile={changeCandidateFile}
                              changeCandidateFileError={changeCandidateFileError}
                              error={error}
                              downloadAll={handleDownload}
                              idvCheckData={idvCheckData}
                              realtimeAnswers={snapshot?.val()?.answers}
                              isRecruiter
                              handleGoToScorecard={handleGoToScorecard}
                            />
                          )
                        }
                        {status !== CANDIDATE_STATUSES.INVITED && (
                        <>
                          <Row>
                            <Col md={7}>
                              <Activites
                                activities={sortedActivities}
                                userId={userId}
                                sendComment={sendComment}
                                updateComment={updateComment}
                                deleteComment={deleteComment}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                profileEmail={profileInfo.email}
                                jobId={jobId}
                                key={userId}
                              />
                            </Col>
                          </Row>
                          <Col md={{ span: 6, offset: 6 }}>
                            <Row className="candidate-overview__delete">
                              {fileteredAnswers.length > 0 &&
                                (isExpirationDayNotToday ? (
                                  <p className="n-font-small n-grey-100">
                                    {`${t(
                                      "job.candidate.autoDeleted"
                                    )} ${expirationLeft} (${expirationDate})`}
                                  </p>
                                ) : (
                                  <p className="n-font-small n-grey-100">
                                    {`${t(
                                      "job.candidate.expiredVideo"
                                    )} (${expirationDate})`}
                                  </p>
                                ))}
                              {isAdmin ||
                              profileInfo.email === jobCurrent.created_by_email ? (
                                <button
                                  className="n-button__small n-white n-font-semi-bold"
                                  type="button"
                                  onClick={
                                    isDeleteButtonVisible
                                      ? handleHideDelete
                                      : handleDelete
                                  }
                                >
                                  {t(
                                    isDeleteButtonVisible
                                      ? "button.delete"
                                      : "job.candidate.really"
                                  )}
                                </button>
                              ) : null}
                            </Row>
                          </Col>
                        </>
                      )}
                      </Tab>
                      <Tab eventKey="availability" title="Availability">
                        <Availability
                          candidateName={name}
                          candidateEmail={email}
                          calendarTitle={`${jobCurrent.company?.name ||
                          ""}, ${jobCurrent.title || ""} - ${name}`}
                          availabilities={sortedAvailabilities}
                          utcMinutesDelta={utcMinutesDelta}
                        />
                      </Tab>
                    </Tabs>
                ) : (
                  <Col md={7}>
                    <Activites
                      key={userId}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      activities={sortedActivities}
                      userId={userId}
                      sendComment={sendComment}
                      updateComment={updateComment}
                      deleteComment={deleteComment}
                      profileEmail={profileInfo.email}
                      isInvited
                      jobId={jobId}
                    />
                  </Col>
                )}
                </>
              )
            }
          </Col>
        </Container>
      </div>
    </PageWrapper>
  );
};

Candidate.defaultProps = {
  showcaseLinkOpenedAmount: undefined,
  isShowcaseLinkEnabled: undefined,
  error: undefined,
  deleteCandidate: undefined,
  changeCandidateFile: undefined,
  isLoadingScreen: true,
  isJobLoading: true
};

Candidate.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      jobId: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  jobCandidateInfo: PropTypes.shape({
    key: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    availabilities: PropTypes.arrayOf(PropTypes.string),
    utc_minutes_delta: PropTypes.number,
    status: PropTypes.string,
    is_locked: PropTypes.bool,
    answers_days_lifetime: PropTypes.number,
    is_expired: PropTypes.bool,
    anonymized_at: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  jobCurrent: PropTypes.shape({
    created_by_email: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    })
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  profileInfo: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  sharableLink: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shareCandidatePublic: PropTypes.func.isRequired,
  clearSharableLink: PropTypes.func.isRequired,
  fetchCandidateInformation: PropTypes.func.isRequired,
  updateCandidateStatus: PropTypes.func.isRequired,
  sendComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deleteCandidate: PropTypes.func,
  setAvailableAnswer: PropTypes.func.isRequired,
  setAvailableLink: PropTypes.func.isRequired,
  sendReminderCandidate: PropTypes.func.isRequired,
  showcaseLinkOpenedAmount: PropTypes.number,
  isShowcaseLinkEnabled: PropTypes.bool,
  changeCandidateFile: PropTypes.func,
  changeCandidateFileError: PropTypes.func.isRequired,
  error: PropTypes.string,
  interviewersList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string
    })
  ).isRequired,
  downloadAll: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    error: PropTypes.string,
    length: PropTypes.number,
    document_type: PropTypes.string,
    issuing_country: PropTypes.string,
    share_code: PropTypes.string,
    share_code_skipped: PropTypes.bool,
    status: PropTypes.string,
    document: PropTypes.shape({
      status: PropTypes.string,
      issuing_country: PropTypes.string,
      document_type: PropTypes.string,
      image_id: PropTypes.string
    }),
    identity: PropTypes.shape({
      status: PropTypes.string,
      created_at: PropTypes.string,
      image_id: PropTypes.string
    }),
    right_to_work: PropTypes.shape({
      status: PropTypes.string,
      share_code: PropTypes.string,
      share_code_skipped: PropTypes.bool,
      requirements_met: PropTypes.string
    }),
    represent_as: PropTypes.string,
    check_type: PropTypes.string,
    documentImage: PropTypes.string,
    identityImage: PropTypes.string,
    is_idv_skipped: PropTypes.bool
  }).isRequired,
  fetchCandidateIdv: PropTypes.func.isRequired,
  isJobLoading: PropTypes.bool,
  isLoadingScreen: PropTypes.bool
};

export default withLoaderScreen(withTranslation()(Candidate));
