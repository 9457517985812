import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

export default (rootReducer, rootSaga, history) => {
  const middleware = [];
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddlewareWithHistory = routerMiddleware(history);

  // console.log({ history, routerMiddlewareWithHistory });

  middleware.push(sagaMiddleware);
  middleware.push(routerMiddlewareWithHistory);
  middleware.push(() => next => action => {
    if (
      action.type === "persist/REHYDRATE" &&
      action.payload &&
      action.payload.auth
    ) {
      if (action.payload.auth.logedIn === false || !action.payload.auth.token) {
        action.payload.auth.isAuthenticated = {};
        action.payload.auth.session = {};
      }
    }

    if (action.type === "persist/REHYDRATE") {
      // console.log("REHYDRATE/persist");
      // console.log({ action, next });
    }

    return next(action);
  });

  const persistConfig = {
    key: "root",
    storage,
    whitelist: [
      "auth",
      "jobs",
      "jobStatus",
      "jobsSetup",
      "jobsQuestions",
      "jobsPreview",
      "jobsCandidate",
      "candidates",
      "companies",
      "users",
      "widgets",
      "language",
      "profile",
      "notifications",
      "libraries",
      "scorecards"
    ]
    // stateReconciler: hardSet
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // enable debugging on testing envs
  const args = [
    persistedReducer,
    {},
    ["production"]?.includes(process.env.REACT_APP_ENV)
      ? applyMiddleware(...middleware)
      : composeWithDevTools(applyMiddleware(...middleware))
  ];

  const store = createStore(...args);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
