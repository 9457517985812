/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { select, call, put, takeEvery } from "redux-saga/effects";

import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";

import { getIntegrations,
  getIntegrationsResponse,
  getIntegrationsError,
  connectKomboResponse,
  connectKombo,
  activateKomboConnect,
  connectKomboError,
  activateKomboConnectError,
  activateKomboConnectResponse,
  generateNewKey,
  generateNewKeyError,
  generateNewKeyLoading,
  generateNewKeyResponse,
  isMarketplaceLoading,
  populateMarketplaceData,
  getWebhooksLogs,
  getWebhooksLogsLoading,
  getWebhooksLogsResponse,
  getWebhooksLogsError,
  deleteWebhookLog,
  deleteWebhookLogLoading,
  deleteWebhookLogResponse,
  deleteWebhookLogError,
  getPaginatedWebhooksLogs,
  getPaginatedWebhooksLogsLoading,
  getPaginatedWebhooksLogsResponse,
  getPaginatedWebhooksLogsError,
  setMarketplaceIcons,
  fetchMarketplaceIcons } from "store/modules/marketplace/actions";

import { logErrors } from "mixins/helpers";
import {
  getAgencyIdvUsageWorker, getIdvPaygPriceWorker
} from "./idv";

import { deleteRequestWithToken, getFullUrlRequestWithToken, getRequestWithToken, postRequestWithToken } from "./api";
import { fetchLeverConfigurationWorker } from "./lever";


function* fetchMarketplaceIntegrationsWorker({ payload: { queryParams = "", cancelToken = null } }) {
  try {
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const { status, data } = yield call(getRequestWithToken, {
      url: `/api/marketplace/services/agency/${agencyKey}/${String(queryParams)}`,
      token,
      cancelTokenSource: cancelToken
    });

    if (status === 200) {
      yield put(getIntegrationsResponse(data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getIntegrationsError(error));
  } finally {
    yield put(isMarketplaceLoading(false));
  }
}

function* connectKomboWorker({ payload: { serviceKey, agencyKey } }) {
  try {
    const token = yield select(getToken);

    const {
      data
    } = yield postRequestWithToken({
      url: "/api/marketplace/integration/init/",
      token,
      payload: {
        integration_service: serviceKey,
        agency: agencyKey
      }
    });

    if (data?.link) {
      yield put(connectKomboResponse({ link: data.link, integrationKey: serviceKey }));
    }
  } catch (error) {
    logErrors(error);
    yield put(connectKomboError({ error, integrationKey: serviceKey }));
  }
}

export function* activateKomboConnectWorker({
  payload: { serviceKey, agencyKey, activationToken }
}) {
  try {
    const token = yield select(getToken);

    const { status } = yield postRequestWithToken({
      url: "/api/marketplace/integration/activate/",
      token,
      payload: {
        integration_service: serviceKey,
        agency: agencyKey,
        token: activationToken
      }
    });

    if (status === 200) {
      yield put(activateKomboConnectResponse({ integrationKey: serviceKey }));
    }
  } catch (error) {
    logErrors(error);
    yield put(activateKomboConnectError(error));
  }
}

function* generateNewKeyWorker({ payload: { agencyKey } }) {
  try {
    yield put(generateNewKeyLoading(true));
    const token = yield select(getToken);

    const { status, data } = yield postRequestWithToken({
      url: `/api/agency/${agencyKey}/api-key/cycle/`,
      token,
      payload: {
        agency: agencyKey
      }
    });

    if ([200, 201].includes(status)) {
      yield put(generateNewKeyResponse({ newKey: data.api_key }));
    }
  } catch (error) {
    logErrors(error);
    yield put(generateNewKeyError(error));
  } finally {
    yield put(generateNewKeyLoading(false));
  }
}

function* fetchWebhooksLogsWorker({ payload: { agencyKey } }) {
  try {
    yield put(getWebhooksLogsLoading(true));
    const token = yield select(getToken);

    const { status, data } = yield getRequestWithToken({
      url: `/api/marketplace/integration/open-api/agency/${agencyKey}/webhooks/`,
      token
    });

    if ([200, 201].includes(status)) {
      yield put(getWebhooksLogsResponse(data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getWebhooksLogsError(error));
  } finally {
    yield put(getWebhooksLogsLoading(false));
  }
}

function* deleteWebhookLogWorker({ payload: { agencyKey, webhookKey } }) {
  try {
    yield put(deleteWebhookLogLoading(true));
    const token = yield select(getToken);

    const { status } = yield deleteRequestWithToken({
      url: `/api/marketplace/integration/open-api/agency/${agencyKey}/webhooks/${webhookKey}/`,
      token
    });

    if ([200, 201, 204].includes(status)) {
      yield put(deleteWebhookLogResponse({ webhookKey }));
    }
  } catch (error) {
    logErrors(error);
    yield put(deleteWebhookLogError(error));
  }
}

function* fetchMarketplaceDataWorker() {
  try {
    yield call(getAgencyIdvUsageWorker);
    yield call(fetchLeverConfigurationWorker, { payload: true });
    yield call(getIdvPaygPriceWorker, { payload: { cancelToken: null } });
  } catch (error) {
    logErrors(error);
  }
}

function* fetchPaginatedWebhooksLogsWorker({ payload: { url, cancelToken = null } }) {
  try {
    yield put(getPaginatedWebhooksLogsLoading(true));
    const token = yield select(getToken);

    const { status, data } = yield getFullUrlRequestWithToken({
      fullUrl: url,
      token,
      cancelTokenSource: cancelToken
    });

    if ([201, 200].includes(status)) {
      yield put(getPaginatedWebhooksLogsResponse({ ...data }));
    }
  } catch (error) {
    logErrors(error);
    yield put(getPaginatedWebhooksLogsError(error));
  }
}
function* fetchMarketplaceIconsWorker() {
  try {
    const token = yield select(getToken);

    const { data } = yield getRequestWithToken({
      url: "/api/marketplace/integration/services/images/",
      token
    });

    yield put(setMarketplaceIcons(data));
  } catch (error) {
    logErrors(error);
    yield put(getPaginatedWebhooksLogsError(error));
  }
}

export function* marketplaceWorker() {
  yield takeEvery(getIntegrations, fetchMarketplaceIntegrationsWorker);
  yield takeEvery(connectKombo, connectKomboWorker);
  yield takeEvery(activateKomboConnect, activateKomboConnectWorker);
  yield takeEvery(generateNewKey, generateNewKeyWorker);
  yield takeEvery(populateMarketplaceData, fetchMarketplaceDataWorker);
  yield takeEvery(getWebhooksLogs, fetchWebhooksLogsWorker);
  yield takeEvery(deleteWebhookLog, deleteWebhookLogWorker);
  yield takeEvery(getPaginatedWebhooksLogs, fetchPaginatedWebhooksLogsWorker);
  yield takeEvery(fetchMarketplaceIcons, fetchMarketplaceIconsWorker);
}

export default marketplaceWorker;
