/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";

import PageWrapper from "hoc/PageWrapper";
import withDocumentTitle from "hoc/withDocumentTitle";
import { getIsStorageSupported, getQueryParams } from "mixins/helperCandidate";

import Offshoot from "views/Candidate/Video/Create/Offshoot";

import MessageUploadVideo from "components/Common/MessageUpload/Video";
import { store } from "store";
import { getQuestionsForCandidate } from "store/modules/сandidates/selectors";

import "../styles.scss";
import "../../../../components/Video/Upload/styles.scss";
import Axios from "axios";

let axiosCancelToken;

const IdvCompleted = ({
  t,
  match: {
    params: { userId = "", jobId = "", questionId = "", step }
  },
  location: { search },
  history,
  saveAnswer,
  saveIsAllAnswerSaved,
  error
}) => {
  if (getIsStorageSupported()) window.sessionStorage.setItem("isIdvCompleted", userId);

  const [uploadProgress, setUploadProgress] = useState(0);

  const saveAllAnswers = async () => {
    saveIsAllAnswerSaved({ userId, isAllAnsweredSaved: true });
  };

  const callbackFnc = ({
    hasNoNextQuestion, hasNextQuestion, nextQuestionKey
  }) => {
    if ((hasNoNextQuestion)) {
      saveAllAnswers().then(() => {
        history.push({ pathname: `/candidate/video-questions/preview/${userId}` });
      });
    }

    if ((hasNextQuestion)) {
      history.push({ pathname: `/candidate/video-questions/create/${userId}/${jobId}/${nextQuestionKey}` });
    }
  };

  const uploadAnswer = () => {
    if (typeof axiosCancelToken !== typeof undefined) {
      axiosCancelToken.cancel("Operation canceled due to new request.");
    }

    axiosCancelToken = Axios.CancelToken.source();

    const [redirect, nextQuestionKey, idvSkipped] = getQueryParams(search);

    const isIdvSkipped = idvSkipped === 1 || idvSkipped === "1";
    const hasNextQuestion = nextQuestionKey !== null && nextQuestionKey !== "null";
    const hasNoNextQuestion = nextQuestionKey === null || nextQuestionKey === "null" || !nextQuestionKey?.length;

    const onUploadProgress = percentage => setUploadProgress(percentage);

    if (redirect === "preview") {
      saveAnswer({
        jobId,
        userId,
        questionId,
        is_finished: true,
        is_skipped: isIdvSkipped || undefined,
        shouldSkipUploadingPage: true,
        cancelToken: axiosCancelToken,
        onUploadProgress,
        callback: () => callbackFnc({
          hasNoNextQuestion, hasNextQuestion, nextQuestionKey
        })
      });
    }

    if (redirect === "shareCode") {
      if (idvSkipped === 0 || idvSkipped === "0") history.push({ pathname: `/candidate/video-questions/create/${userId}/${jobId}/${questionId}/share-code` });

      if (isIdvSkipped) {
        saveAnswer({
          jobId,
          userId,
          questionId,
          is_finished: true,
          is_skipped: isIdvSkipped,
          shouldSkipUploadingPage: true,
          cancelToken: axiosCancelToken,
          onUploadProgress,
          callback: () => callbackFnc({
            hasNoNextQuestion, hasNextQuestion, nextQuestionKey
          })
        });
      }
    }

    if (redirect === "nextQuestion" && (hasNextQuestion)) {
      saveAnswer({
        jobId,
        userId,
        questionId,
        is_finished: true,
        is_skipped: isIdvSkipped || undefined,
        shouldSkipUploadingPage: true,
        cancelToken: axiosCancelToken,
        onUploadProgress,
        callback: () => callbackFnc({
          hasNoNextQuestion, hasNextQuestion, nextQuestionKey
        })
      });
    }
  };

  useEffect(() => {
    if (search && questionId !== "" && userId !== "" && jobId !== "") {
      uploadAnswer();
    }
  }, [search, userId, questionId, jobId]);

  const questionsForCandidate = getQuestionsForCandidate(store.getState());

  const questionNumber = useMemo(
    () =>
      Number(step) ||
      questionsForCandidate.findIndex(item => item.key === questionId),
    [questionId, questionsForCandidate, step]
  );

  const handleReturn = () => {
    history.goBack();
    setUploadProgress(0);
  };

  const { i18n } = useTranslation();
  let translatedErrorMessage = error;

  if (error && i18n.exists(error)) translatedErrorMessage = t(translatedErrorMessage);
  if (translatedErrorMessage?.includes("timeout of")) translatedErrorMessage = t("Network Error");

  return (
    <PageWrapper>
      <Container className="idv-checkout-session__container">
        <Row>
          <Col>
            <Offshoot userId={userId} isRecording={false} isShareCode={false} />
            <div className="questions-upload__wrapper">
              <div className="questions-upload">
                {error ? (
                  <MessageUploadVideo message={translatedErrorMessage} error />
                  ) : (
                    <div className="questions-upload__spacing">
                      <progress
                        value={uploadProgress}
                        max="100"
                        className="questions-upload__progress"
                      />
                      <p className="questions-upload__text-line n-text-right">
                        {t("candidate.videoCreate.progress", { value: uploadProgress })}
                      </p>
                    </div>
                  )}

                <div className="questions-upload__text">
                  <h3 className="questions-upload__text-title">
                    {`
                        ${t("candidate.videoCreate.upload")} 
                        ${questionNumber} 
                        ${t("candidate.videoCreate.of")} 
                        ${questionsForCandidate?.length}
                      `}
                  </h3>
                  <p className="questions-upload__text-line">
                    {t("candidate.videoCreate.please")}
                  </p>

                  <div className="questions-upload__text-line">
                    {t("candidate.videoCreate.problem")}
                    <button
                      onClick={uploadAnswer}
                      className="questions-upload__button"
                      type="button"
                      data-testid="InterviewUploadingPage-Btn-Retry_Btn"
                      aria-label="Retry uploading"
                    >
                      {t("link.retry")}
                    </button>

                    {
                      error?.length ? (
                        <>
                          {t("candidate.videoCreate.or")}

                          <button
                            onClick={handleReturn}
                            className="questions-upload__button"
                            type="button"
                            data-testid="InterviewUploadingPage-Btn-Return_Btn"
                            aria-label="Go back to question"
                          >
                            {t("link.return")}
                          </button>
                        </>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

IdvCompleted.defaultProps = {
  match: {},
  location: {},
  error: null
};

IdvCompleted.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      jobId: PropTypes.string,
      questionId: PropTypes.string,
      step: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired,
  saveAnswer: PropTypes.func.isRequired,
  saveIsAllAnswerSaved: PropTypes.func.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withDocumentTitle(IdvCompleted));
