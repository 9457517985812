import React from "react";

export const ArrowRight = () => (
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6022 11.0673L3.60204 0.386349C3.07151 -0.128784 2.2114 -0.128784 1.68093 0.386349L0.397938 1.63211C-0.131682 2.14637 -0.132702 2.97983 0.395674 3.49529L9.11345 12L0.395618 20.5047C-0.132757 21.0202 -0.13174 21.8536 0.397881 22.3679L1.68087 23.6136C2.2114 24.1288 3.07151 24.1288 3.60198 23.6136L14.6021 12.9327C15.1326 12.4176 15.1326 11.5824 14.6022 11.0673Z" fill="#768493" />
  </svg>

);

export const ArrowLeft = () => (
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.397845 12.9327L11.398 23.6137C11.9285 24.1288 12.7886 24.1288 13.3191 23.6137L14.6021 22.3679C15.1317 21.8536 15.1327 21.0202 14.6043 20.5047L5.88655 12L14.6044 3.49529C15.1328 2.97983 15.1317 2.14637 14.6021 1.63211L13.3191 0.38635C12.7886 -0.128783 11.9285 -0.128783 11.398 0.38635L0.397902 11.0673C-0.132625 11.5824 -0.132625 12.4176 0.397845 12.9327Z" fill="#768493"/>
  </svg>
);
