import { Down } from "components/Job/QuestionsComponent/svgIcons";
import { CHECKBOXES_TYPE, IDV_TYPE, INITIAL_TYPE, MULTIPLE_CHOICE_TYPE } from "configs/jobs/constants";
import { getQuestionTypes } from "mixins/helpers";
import { nanoid } from "nanoid";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const INITIAL_DATA = {
  answer_type: "video",
  html_text: "",
  idv_country_of_employment_iso_code: null,
  idv_max_checks: null,
  max_characters: null,
  max_duration: 60,
  max_retakes: null,
  max_words: 500,
  order: 1,
  text: "",
  thinking_time: 0,
  thinking_hours: 0,
  thinking_minutes: 0
};

const AddQuestion = ({
  t,
  sortedQuestions,
  isEditableQuestions,
  append,
  watch,
  handleShowModal
}) => {
  const questions = watch("questions");
  const questionTypes = getQuestionTypes(questions);

  const addQuestions = (type = INITIAL_TYPE) => {
    const idvData = {
      html_text: "<p>Identity Verification</p>",
      text: "Identity Verification"
    };

    if ([MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(type)) {
      append({
        ...INITIAL_DATA,
        answer_type: type,
        key: nanoid(),
        type_specific_data: {
          answer_options: [
            {
              key: nanoid(),
              text: null,
              autofocus: true,
              is_correct: false,
              is_touched: false
            }
          ]
        }
      });
    } else if (type === IDV_TYPE) {
      append({
        ...INITIAL_DATA,
        answer_type: type,
        key: nanoid(),
        ...idvData
      });
    } else {
      append({
        ...INITIAL_DATA,
        answer_type: type,
        key: nanoid()
      });
    }


    if (type === IDV_TYPE) handleShowModal(true, questions?.length);
  };

  return (
    <>
      {sortedQuestions.length < 20 && isEditableQuestions && (
        <button
          type="button"
          className="question__add-btn button__without-styles"
          onClick={() => addQuestions()}
        >
          <span className="n-font-large n-font-medium-weight">
            {t("button.createQuestion")}
            <Dropdown
              onToggle={(isOpen, event) => event?.stopPropagation && event?.stopPropagation()}
            >
              <Dropdown.Toggle id="dropdown-basic" as="div">
                <Down />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight bsPrefix="dropdown-menu">
                {questionTypes.map(({ value, titleKey, icon, reactIcon }) => (
                  <Dropdown.Item
                    key={`dropdown-item-${nanoid()}`}
                    onClick={() => addQuestions(value)}
                    type="button"
                  >
                    <i className={`fa xsm mr6 ${icon}`}>
                      {reactIcon}
                    </i>
                    {t(titleKey)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </button>
      )}
    </>
  );
};

AddQuestion.defaultProps = {
  t: undefined,
  sortedQuestions: undefined,
  isEditableQuestions: undefined,
  append: undefined,
  watch: undefined,
  handleShowModal: undefined
};

AddQuestion.propTypes = {
  t: PropTypes.func,
  sortedQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  isEditableQuestions: PropTypes.bool,
  append: PropTypes.func,
  watch: PropTypes.func,
  handleShowModal: PropTypes.func
};

export default withTranslation()(AddQuestion);
