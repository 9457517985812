import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Info, Close } from "mixins/svgIcons";

const Notification = ({
  type,
  message,
  onClick,
  animation,
  withCloseIcon = true,
  style,
  buttonText,
  buttonOnClick,
  className
}) => (
  <div
    className={classNames({
      "notification-custom": true,
      [type]: true,
      [className]: true
    })}
    onClick={type === "alert" || (!withCloseIcon && onClick) ? onClick : null}
    onKeyPress={null}
    role="button"
    tabIndex={0}
    style={{ animation, ...style }}
  >
    <div className="notification-custom-message">
      <p className="notification-message">
        <Info
          svgIconClass="notification-icon"
          width="17px"
          height="17px"
          fill={type === "trial" ? "#595959" : "#FFFFFF"}
        />
        {message}
      </p>
      {type !== "alert" && withCloseIcon && (
        <div className="notification-icon-close" onClick={onClick} role="presentation">
          <Close width="12px" height="12px" fill="#FFFFFF" />
        </div>
      )}
      {buttonText ? (
        <button
          type="button"
          onClick={buttonOnClick}
          className="button__without-styles notification-button"
        >
          {buttonText}
        </button>
      ) : null}
    </div>
  </div>
);

Notification.defaultProps = {
  onClick: undefined,
  type: undefined,
  message: undefined,
  style: undefined,
  animation: undefined,
  withCloseIcon: undefined,
  buttonText: "",
  buttonOnClick: null,
  className: ""
};

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({})
  ]),
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  animation: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  withCloseIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  className: PropTypes.string
};

export default Notification;
