import moment from "moment";
import * as Yup from "yup";

import { DATE_FORMATS } from "configs/dates";
import { isNaN } from "lodash";

export const handlePaymentDuration = (billingPeriodStart = "", billingPeriodEnd = "") => {
  const isDateValid = moment(billingPeriodStart).isValid();
  const isDateEndValid = moment(billingPeriodEnd).isValid();

  if (isDateValid) {
    const daysIntoMonth = moment(billingPeriodStart).daysInMonth();

    const startPeriodDate = moment(billingPeriodStart).format(
      DATE_FORMATS.SPACE_SEPARATOR_DAY_MONTH_YEAR
    );
    const endPeriodDate = isDateEndValid
      ? moment(billingPeriodEnd)
        .format(DATE_FORMATS.SPACE_SEPARATOR_DAY_MONTH_YEAR)
      : moment(billingPeriodStart)
        .add(daysIntoMonth, "days")
        .format(DATE_FORMATS.SPACE_SEPARATOR_DAY_MONTH_YEAR);

    return {
      fullDuration: `${startPeriodDate} - ${endPeriodDate}`,
      startDate: startPeriodDate,
      endDate: endPeriodDate
    };
  }

  return "N/A";
};

export const setInitialInfo = (data = {}) => ({
  email: data.email || "",
  phone: data.phone || "",
  agency: data.agency_name || "",
  errors: {},
  touched: {}
});

export const setProfileInfo = ({
  email,
  phone,
  agencyName,
  integration_key: integrationKey
}) => [
  {
    id: "info-1",
    icon: "MailBlack",
    text: email,
    key: "email"
  },
  {
    id: "info-2",
    icon: "Phone",
    text: phone,
    key: "phone"
  },
  {
    id: "info-4",
    icon: "Agency",
    text: agencyName,
    key: "agencyName"
  },
  {
    id: "info-5",
    icon: "KeyRotate",
    text: integrationKey,
    key: "integrationKey"
  }
];

export const editFormSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("errors.validEmal")
      .required("errors.required"),
    phone: Yup.number()
      .typeError("errors.validPhone")
      .required("errors.required")
  });

export const editFormOwnerSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("errors.validEmal")
      .required("errors.required"),
    phone: Yup.number()
      .typeError("errors.validPhone")
      .required("errors.required"),
    agency: Yup.string().required("errors.required")
  });

export const getTrialPeriodEnd = (endDate, t) => {
  const given = moment(endDate, "YYYY/MM/DD");
  const current = moment().startOf("day");
  const daysCount = moment.duration(given.diff(current, "days"), "days").asDays();

  if (daysCount === 0) {
    return t("days.todaySmall");
  }

  if (daysCount === 1) {
    return t("days.tomorrow");
  }

  return t("days.inXDays", { count: isNaN(daysCount) ? 0 : daysCount });
};
