/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";

import { Check, Questionmark } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";

import "./styles.scss";
import { IDV_OMITTED, IDV_SKIPPED } from "configs/jobs/constants";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";

const CandidateIdvOverview = ({
  t,
  question,
  questionNumber,
  size,
  textSize,
  questionTextProps,
  thinkingTime,
  spentTime,
  idvCheckData
}) => {
  const ref = useRef(null);

  const handleSelectText = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStartBefore(ref.current);
    range.setEndAfter(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  countries.registerLocale(english);
  const alpha2code = countries.alpha3ToAlpha2(
    idvCheckData?.issuing_country,
    "en"
  );

  const isIdvSkipped =
    idvCheckData?.is_idv_skipped ||
    idvCheckData?.overall_status === IDV_SKIPPED;

  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={question?.text}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading}
      >
        <div className="text__answer-scroll" onDoubleClick={handleSelectText}>
          <div className={`text__answer ${size}`} ref={ref}>
            <div className="idv-check__container">
              <div className="idv-check__container-text-wrapper">
                {isIdvSkipped ? (
                  <Questionmark />
                ) : (
                  <Check height={15} width={20} />
                )}
                <div className="idv-check__container-title-wrapper">
                  {isIdvSkipped ? (
                    <p className="idv-check__container-title">
                      {t("job.candidate.overview.idv.document.skipped")}
                    </p>
                  ) : idvCheckData?.document_type && alpha2code ? (
                    <>
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.document.title")}
                      </p>
                      <p>{`${idvCheckData?.document_type}: ${countries.getName(alpha2code, "en")}`}</p>
                    </>
                  ) : (
                    <p className="idv-check__container-title">
                      {t("job.candidate.overview.idv.document.title")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="idv-check__container">
              <div className="idv-check__container-text-wrapper">
                {isIdvSkipped ? (
                  <Questionmark />
                ) : (
                  <Check height={15} width={20} />
                )}
                <div className="idv-check__container-title-wrapper">
                  {isIdvSkipped ? (
                    <>
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.check.skipped", {
                          status: IDV_OMITTED
                        })}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.check.title")}
                      </p>
                      <p>{t("job.candidate.overview.idv.check.subtitle")}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            {
              // eslint-disable-next-line no-nested-ternary
              idvCheckData?.share_code ? (
                <div className="idv-check__container">
                  <div className="idv-check__container-text-wrapper">
                    <Check height={15} width={20} />
                    <div className="idv-check__container-title-wrapper">
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.shareCode.title")}
                      </p>
                      {idvCheckData?.share_code ? (
                        <p>{idvCheckData?.share_code}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : idvCheckData?.share_code_skipped ? (
                <div className="idv-check__container">
                  <div className="idv-check__container-text-wrapper">
                    <Check height={15} width={20} />
                    <div className="idv-check__container-title-wrapper">
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.shareCode.title")}
                      </p>
                      {t("job.candidate.overview.idv.shareCode.skipped")}
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>
      </SkeletonWrapper>
    </div>
  );
};

CandidateIdvOverview.defaultProps = {
  history: null,
  question: {},
  questionNumber: "",
  size: "",
  textSize: "",
  answer: {},
  questionTextProps: null,
  memorizedCompanyInfo: {},
  thinkingTime: 0,
  spentTime: 0
};

CandidateIdvOverview.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  size: PropTypes.string,
  textSize: PropTypes.string,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    text: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  idvCheckData: PropTypes.shape({
    document_type: PropTypes.string,
    issuing_country: PropTypes.string,
    share_code: PropTypes.string,
    share_code_skipped: PropTypes.bool,
    is_idv_skipped: PropTypes.bool,
    overall_status: PropTypes.string
  }).isRequired
};

export default withTranslation()(CandidateIdvOverview);
