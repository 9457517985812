/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import PageWrapper from "hoc/PageWrapper";
import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";
import QuestionTitle from "components/Common/QuestionTitle";
import StepsQuestions from "components/Steps/Questions";
import { Col, Container, Row } from "react-bootstrap";
import ErrorModal from "components/Common/Error/ErrorModal";
import { isMobileOnly, isTablet } from "react-device-detect";
import { DBS_TYPE, IDV_TYPE, RTW_COUNTRIES } from "configs/jobs/constants";
import { useObject } from "react-firebase-hooks/database";
import { ref, update } from "firebase/database";
import StartFirebase from "configs/firebase";
import { store } from "store";
import { initQuestion } from "store/modules/сandidates/actions";
import Spinner from "components/Common/Spinner";
import { isEmpty } from "lodash";
import { getIsStorageSupported, storageIsIdvCompleted, storageSessionId, storageSessionToken, storageSessionUserId } from "mixins/helperCandidate";
import Offshoot from "../Video/Create/Offshoot";

import "./styles.scss";

const MINUTE_MS = 60000;

const LOCALHOST = "localhost";

const IDVComponent = ({
  match: {
    params: { userId = "", jobId = "", questionId = "", step }
  },
  jobInfoCompany,
  questionsForCandidate = [],
  previousQuestionId,
  history,
  savedAnswers,
  saveCurrentQuestion,
  savePreviousQuestion,
  setModal,
  clearPreviousQuestion,
  isAllAnsweredSaved,
  retakeAnswer,
  currentCandidateId,
  t,
  getIdvSession,
  idvSession,
  token,
  saveIsAllAnswerSaved
}) => {
  const [sessionId, setSessionId] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [, setCashedElapsingTime] = useState(null);
  const [db, setDB] = useState();
  const [snapshot, loading] = useObject((db && userId) ? ref(db, `Candidates/${userId}`) : null);

  const isUserIdExists = !isEmpty(userId) && userId !== "null";

  const currentQuestion = useMemo(
    () => questionsForCandidate.find(item => item.key === questionId),
    [questionId, questionsForCandidate, step]
  );

  const savedAnswer = savedAnswers.find(answer => answer?.question?.key === questionId) || [];

  const hasNoShareCode =
    [DBS_TYPE, IDV_TYPE]?.includes(currentQuestion?.answer_type) ? true : (
    // Employment & Citizenship UK | Rol
    (RTW_COUNTRIES.includes(
      currentQuestion?.idv_country_of_employment_iso_code
    ) && RTW_COUNTRIES.includes(savedAnswer?.idv_citizenship_iso_code)) ||
    // Employment not UK | Rol
    !RTW_COUNTRIES.includes(currentQuestion?.idv_country_of_employment_iso_code)
  );

  const isLastQuestion = useMemo(() => userId === currentCandidateId &&
    savedAnswers.length > 0 &&
    savedAnswers.length >= questionsForCandidate.length &&
    savedAnswers.every(item => item?.is_finished), [savedAnswers]);

  const questionNumber = useMemo(
    () =>
      Number(step) ||
        questionsForCandidate.findIndex(item => item.key === questionId),
    [questionId, questionsForCandidate, step]
  );

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (jobId && questionId !== "null" && questionId !== "" && !questionId?.includes("null") && isUserIdExists && isMounted) {
      isMounted = false;

      store.dispatch(initQuestion({
        userId,
        questionId,
        jobId,
        shouldSkipRedirectToIDV: true
      }));
    }

    return () => {
      isMounted = true;
    };
  }, [jobId, questionId, userId]);

  useEffect(() => {
    if (db && isUserIdExists) {
      update(ref(db, `Candidates/${userId}`), {
        device: navigator.userAgent,
        jobId,
        questionId,
        token
      });
    }
  }, [userId, db]);

  useEffect(() => {
    if (!loading && snapshot?.val()?.device && snapshot?.val()?.device !== navigator.userAgent) {
      history.push(`/candidate/transferred/${userId}?isShareCode=false`);
    }
  }, [snapshot?.val()?.device, loading]);

  const handleIDVSessionData = ({ response }) => {
    if (getIsStorageSupported()) {
      window.sessionStorage.setItem("sessionId", response.session_id);
      window.sessionStorage.setItem("sessionToken", response.session_token);
      window.sessionStorage.setItem("sessionExpiry", response.session_token_ttl);
      window.sessionStorage.setItem("sessionUserId", userId);
    }

    setSessionId(response.session_id);
    setSessionToken(response.session_token);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;

      if (userId === storageSessionUserId) {
        setSessionId(storageSessionId);
        setSessionToken(storageSessionToken);
      } else {
        const nextQuestionNumber = questionNumber + 1;
        const nextQuestionByNumber = questionsForCandidate[nextQuestionNumber];
        const nextQuestionNotFinished = questionsForCandidate
          .find(({ key: questionKey }) =>
            questionKey !== questionId &&
          savedAnswers.every(({ question }) => question.key !== questionKey));
        const nextQuestion = nextQuestionByNumber || nextQuestionNotFinished;

        let successUrl = `/candidate/video-questions/create/${userId}/${jobId}/${currentQuestion?.key}/${currentQuestion?.order}/idv/completed/?redirect=shareCode&nextQuestionKey=null`;

        if ((hasNoShareCode && !nextQuestion) || (hasNoShareCode && isLastQuestion)) {
          successUrl = `/candidate/video-questions/create/${userId}/${jobId}/${currentQuestion?.key}/${currentQuestion?.order}/idv/completed/?redirect=preview&nextQuestionKey=null`;
        }

        if (hasNoShareCode && nextQuestion) {
          successUrl = `/candidate/video-questions/create/${userId}/${jobId}/${currentQuestion?.key}/${currentQuestion?.order}/idv/completed/?redirect=nextQuestion&nextQuestionKey=${nextQuestion?.key}`;
        }

        if (!hasNoShareCode && nextQuestion) {
          successUrl = `/candidate/video-questions/create/${userId}/${jobId}/${currentQuestion?.key}/${currentQuestion?.order}/idv/completed/?redirect=shareCode&nextQuestionKey=${nextQuestion?.key}`;
        }

        getIdvSession({
          questionKey: currentQuestion?.key || null,
          successUrl,
          userId,
          successCallback: handleIDVSessionData
        });
      }
    }

    return () => {
      isMounted = true;
    };
  }, [userId]);

  useEffect(() => {
    if (idvSession?.error) {
      setModal({
        type: "idvSessionExpired"
      });
    }
  }, [idvSession]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (idvSession?.error) {
        setModal({
          type: "idvSessionExpired"
        });
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLastQuestion && typeof saveIsAllAnswerSaved === "function" && hasNoShareCode) {
      saveIsAllAnswerSaved({ userId, isAllAnsweredSaved: true });
      history.push({
        pathname: `/candidate/video-questions/preview/${userId}`,
        state: {
          jobId
        }
      });
    }
  }, [isLastQuestion, history, userId, jobId, saveIsAllAnswerSaved]);

  const memorizedCompanyInfo = useMemo(() => jobInfoCompany, [jobInfoCompany]);

  const isSandboxUrl = window.location.hostname === process.env.REACT_APP_IDV_SANDBOX_URL ||
    window.location.hostname === LOCALHOST ? "sandbox/" : "";

  const iframeUrl = `https://api.yoti.com/${isSandboxUrl}idverify/v1/web/index.html?sessionID=${sessionId}&sessionToken=${sessionToken}`;

  const navigateToNextStep = () => {
    if (!hasNoShareCode && storageIsIdvCompleted === userId && !savedAnswer?.is_finished) {
      history.push({
        pathname: `/candidate/video-questions/create/${userId}/${jobId}/${currentQuestion.key}/share-code`
      });
    } else if (!isLastQuestion) {
      const nextQuestionNumber = questionNumber + 1;
      const nextQuestionByNumber = questionsForCandidate[nextQuestionNumber];
      const nextQuestionNotFinished = questionsForCandidate
        .find(({ key: questionKey }) =>
          questionKey !== questionId &&
          savedAnswers.every(({ question }) => question.key !== questionKey));
      const nextQuestion = nextQuestionByNumber || nextQuestionNotFinished;

      if (nextQuestion) {
        saveCurrentQuestion({ userId, questionCurrentCashed: null });

        history.push({
          pathname: `/candidate/video-questions/create/${userId}/${jobId}/${nextQuestion.key}`
        });

        setCashedElapsingTime(null);
      }
    } else {
      history.push({
        pathname: `/candidate/video-questions/preview/${userId}`
      });
    }
  };

  let pageContent = (
    <Container bsPrefix="container question-create">
      <Offshoot userId={userId} isRecording={false} isShareCode={false} />
      <Row>
        <Col md={12}>
          <StepsQuestions
            jobId={jobId}
            userId={userId}
            history={history}
            isAllAnsweredSaved={isAllAnsweredSaved}
            savedAnswers={savedAnswers}
            questions={questionsForCandidate}
            currentStepActive={questionId}
            previousQuestionId={previousQuestionId}
            savePreviousQuestion={savePreviousQuestion}
            setModal={setModal}
            clearPreviousQuestion={clearPreviousQuestion}
            retakeAnswer={retakeAnswer}
            isRecording={false}
            saveCurrentQuestion={saveCurrentQuestion}
            setCashedElapsingTime={setCashedElapsingTime}
            isShowDelayMessage={false}
            isIdvQuestion
          />
        </Col>
      </Row>
      <div className="idv-iframe-wrapper">
        <iframe
          id="idv-iframe"
          style={{ border: "none" }}
          title="idv-check"
          width="100%"
          height="100%"
          allow="camera"
          src={iframeUrl}
          allowFullScreen
        />
      </div>
    </Container>
  );

  if ((!sessionId && !sessionToken) || (sessionId === "" && sessionToken === "")) {
    pageContent = <Spinner />;
  }

  // if already answered and has share code, redirect to share code, otherwise to next page
  if (savedAnswer?.is_finished || storageIsIdvCompleted === userId) {
    pageContent = (
      <Container bsPrefix="container question-create">
        <Offshoot userId={userId} isRecording={false} isShareCode={false} />
        <Row>
          <Col md={12}>
            <StepsQuestions
              jobId={jobId}
              userId={userId}
              history={history}
              isAllAnsweredSaved={isAllAnsweredSaved}
              savedAnswers={savedAnswers}
              questions={questionsForCandidate}
              currentStepActive={questionId}
              previousQuestionId={previousQuestionId}
              savePreviousQuestion={savePreviousQuestion}
              setModal={setModal}
              clearPreviousQuestion={clearPreviousQuestion}
              retakeAnswer={retakeAnswer}
              isRecording={false}
              saveCurrentQuestion={saveCurrentQuestion}
              setCashedElapsingTime={setCashedElapsingTime}
              isShowDelayMessage={false}
              // isIdvQuestion
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <QuestionTitle
              questionNumber={questionNumber}
              thinkingTime={currentQuestion?.thinking_time}
              spentTime={0}
              isIdv
            />
          </Col>
          <Col md={12}>
            {t("job.candidate.idv.alreadyAnswered")}
          </Col>
          <Col md={{ offset: 8, span: 4 }} xs={{ span: 12 }}>
            <div className="button__wrapper upload">
              <button
                className="n-fluid n-button__large n-bg-purple-100 n-white"
                style={
                  memorizedCompanyInfo && {
                    background: memorizedCompanyInfo.button_color
                  }
                }
                type="button"
                onClick={navigateToNextStep}
              >
                {t("button.saveContinue")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <PageWrapper
      header={(
        <HeaderCandidateJob
          company={memorizedCompanyInfo}
          className={isMobileOnly || isTablet ? "header-absolute" : ""}
        />
      )}
      footer={(
        <Footer
          company={memorizedCompanyInfo}
          isPublic
        />
      )}
      overflowVisible
    >
      {pageContent}
      <ErrorModal
        show={showErrorModal}
        hide={() => setShowErrorModal(false)}
        t={t}
      />
    </PageWrapper>
  );
};

export default IDVComponent;

IDVComponent.defaultProps = {
  isAllAnsweredSaved: undefined,
  match: undefined,
  history: undefined,
  jobInfoCompany: {},
  jobInfo: {},
  savedAnswers: [],
  questionsForCandidate: [],
  questionCurrentCashed: {},
  previousQuestionId: undefined,
  saveCurrentQuestion: undefined,
  savePreviousQuestion: undefined,
  setModal: undefined,
  clearPreviousQuestion: undefined,
  idvSession: {},
  token: ""
};

IDVComponent.propTypes = {
  isAllAnsweredSaved: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      jobId: PropTypes.string,
      questionId: PropTypes.string,
      step: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    action: PropTypes.string,
    push: PropTypes.func
  }),
  jobInfoCompany: PropTypes.shape({
    layout_color: PropTypes.string,
    logo: PropTypes.string
  }),
  jobInfo: PropTypes.shape({
    key: PropTypes.string
  }),
  questionsForCandidate: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ),
  questionCurrentCashed: PropTypes.shape({
    blob: PropTypes.object,
    userId: PropTypes.string,
    questionId: PropTypes.string,
    questionNumber: PropTypes.number
  }),
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  previousQuestionId: PropTypes.string,
  saveCurrentQuestion: PropTypes.func,
  savePreviousQuestion: PropTypes.func,
  setModal: PropTypes.func,
  clearPreviousQuestion: PropTypes.func,
  retakeAnswer: PropTypes.func.isRequired,
  currentCandidateId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  getIdvSession: PropTypes.func.isRequired,
  idvSession: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    sessionId: PropTypes.string,
    sessionToken: PropTypes.string,
    sessionExpiry: PropTypes.number
  }),
  token: PropTypes.string,
  saveIsAllAnswerSaved: PropTypes.func.isRequired
};
