import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Question } from "mixins/svgIcons";

import _ from "lodash";
import { IDV_OMITTED, IDV_SKIPPED, IDV_TYPE } from "configs/jobs/constants";
import { IDVCheckAnswer } from "../svgIcons";

const IDV = ({
  t,
  questionNumber,
  questionText,
  isCurrent,
  setCurrentQuestion,
  buttonColor,
  idvCheckData,
  question
}) => {
  const isIdvSkipped =
    idvCheckData?.is_idv_skipped ||
    idvCheckData?.overall_status === IDV_SKIPPED;
  const overAllStatus = isIdvSkipped
    ? IDV_OMITTED
    : idvCheckData?.overall_status;

  return (
    <div
      role="presentation"
      className={`preview__list-item ${isCurrent && "current"}`}
      onClick={setCurrentQuestion}
      style={
        isCurrent && buttonColor ? { background: `${buttonColor}20` } : null
      }
    >
      <div className="preview__list-item-type idv">
        <IDVCheckAnswer />
        <p
          className="preview__list-item-type-name n-grey n-font-medium text"
          title={t("job.candidate.overview.idv.answer.idvCheck")}
        >
          {t("job.candidate.overview.idv.answer.idvCheck")}
        </p>
        <p
          className={`people__table-status-title n-font-extra-small n-font-regular ${_.capitalize(
            overAllStatus
          )}`}
        >
          {_.capitalize(overAllStatus)}
        </p>
      </div>
      <div className="preview__list-item-info">
        <p className="preview__list-item-info-subtitle n-grey-100 n-font-small">
          <Question svgIconClass="preview__list-item-info-icon" />
          <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
        </p>
        <p
          className="preview__list-item-info-title n-grey-300 n-font-small"
          title={questionText}
        >
          {question?.answer_type === IDV_TYPE
            ? t("job.candidate.overview.idv.answer.thumbnail.title")
            : t(
                `job.candidate.overview.idv.answer.thumbnail.${question?.answer_type}`
              )}
        </p>
      </div>
    </div>
  );
};

IDV.defaultProps = {
  idvCheckData: {},
  buttonColor: ""
};

IDV.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  question: PropTypes.shape({
    answer_type: PropTypes.string
  }).isRequired,
  idvCheckData: PropTypes.shape({
    represent_as: PropTypes.string,
    status: PropTypes.string,
    overall_status: PropTypes.string,
    check_type: PropTypes.string,
    right_to_work: PropTypes.shape({
      requirements_met: PropTypes.string,
      share_code_skipped: PropTypes.bool
    }),
    is_idv_skipped: PropTypes.bool
  })
};

export default withTranslation()(IDV);
