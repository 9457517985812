/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { Formik } from "formik";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";

import { handleClassName } from "mixins/helpers";

import {
  initialStateForPersonalizedInvite,
  schemaForPersonalizedInvite
} from "mixins/helperJobInvites";

const phoneParser = (setFieldValue, e) => {
  const beginning = e?.length >= 3 ? e.slice(0, 3) : e;

  if (beginning === "+00") {
    const value = e.replace("+00", "+");

    setFieldValue("phone", value);
    return;
  }

  setFieldValue("phone", e);
};

const PersonalizedInvites = ({ t, addJobInvite }) => (
  <Formik
    enableReinitialize
    initialValues={initialStateForPersonalizedInvite}
    validationSchema={schemaForPersonalizedInvite()}
    onSubmit={(values, { resetForm }) => {
      const { name, phone, email } = values;

      addJobInvite({ key: uuidv4(), name, phone, email });
      resetForm(initialStateForPersonalizedInvite);
    }}
  >
    {({
      values,
      errors,
      touched,
      isValid,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue
    }) => (
      <form onSubmit={handleSubmit}>
        <div className="form-candidates__block">
          <Row>
            <Col md={12}>
              <div className="form-candidates__divider">
                <span className="form-candidates__divider-left-line" />
                <span className="form-candidates__divider-text">{t("job.invite.or")}</span>
                <span className="form-candidates__divider-right-line" />
              </div>
            </Col>

            <Col md={12}>
              <h2 className="form-candidates__title">
                {t("job.invite.inviteFew.title")}
              </h2>
              <p className="form-candidates__text">
                {t("job.invite.inviteFew.text")}
              </p>
            </Col>

            <Col md={4}>
              <div className="input">
                <div className="input-wrapper">
                  <label htmlFor="candidate-name" className="label">
                    {t("input.fullNameLabel")}
                  </label>

                  <input
                    id="candidate-name"
                    type="text"
                    name="name"
                    placeholder={t("input.fullNamePlaceholder")}
                    className={`${handleClassName(
                      errors.name,
                      touched.name
                    )} without-icon`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />

                  {errors.name && touched.name && (
                    <p className="input-error-message">{t(errors.name)}</p>
                  )}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="input">
                <div className="input-wrapper">
                  <label htmlFor="candidate-email" className="label">
                    {t("input.emailLabel")}
                  </label>

                  <input
                    id="candidate-email"
                    type="text"
                    name="email"
                    placeholder={t("input.emailPlaceholder")}
                    className={`${handleClassName(
                      errors.email,
                      touched.email
                    )} without-icon`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />

                  {errors.email && touched.email && (
                    <p className="input-error-message">{t(errors.email)}</p>
                  )}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="input">
                <div className="input-wrapper">
                  <label htmlFor="candidate-phone" className="label">
                    {t("input.jobPhoneTitle")}
                    <span className="label__secondary">
                      {t("input.inputLabelOptional")}
                    </span>
                  </label>

                  <PhoneInput
                    id="candidate-phone"
                    name="phone"
                    placeholder="Enter Phone number"
                    defaultCountry="GB"
                    international
                    className={`${handleClassName(
                      errors.phone,
                      touched.phone
                    )} without-icon`}
                    onBlur={handleBlur}
                    onChange={event => phoneParser(setFieldValue, event)}
                    value={values.phone}
                  />

                  {errors.phone && touched.phone && (
                    <p className="input-error-message">{t(errors.phone)}</p>
                  )}
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="candidate-add-btn-wrapper">
                <button
                  disabled={!isValid}
                  type="submit"
                  className="
                    n-button__large
                    n-bg-purple-100
                    n-white
                  "
                >
                  {t("button.add")}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    )}
  </Formik>
);

PersonalizedInvites.defaultProps = {
  addJobInvite: undefined
};

PersonalizedInvites.propTypes = {
  t: PropTypes.func.isRequired,
  addJobInvite: PropTypes.func
};

export default withTranslation()(PersonalizedInvites);
