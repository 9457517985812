/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "lodash/debounce";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Table from "components/Common/Table";
import TooltipInfo from "components/Common/Tooltip/Info";
import Switcher from "components/Switcher";
import CopyButton from "components/Tables/Jobs/Buttons/Copy";
import DeleteButton from "components/Tables/Jobs/Buttons/Delete";
import DuplicateButton from "components/Tables/Jobs/Buttons/Duplicate";
import { DATE_FORMATS } from "configs/dates";
import { JOB_STATUSES } from "configs/jobs/constants";
import withLoader from "hoc/withLoader";
import { copyToClipboard, removeDuplicates } from "mixins/helpers";
import { Edit } from "mixins/svgIcons";

import "./styles.scss";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { getJobsMaximum } from "store/modules/users/selectors";

const checkIsEllipsisActive = el => {
  const firstChild = el?.firstChild;
  const lastChild = el?.lastChild;
  if (!lastChild || !firstChild) return;

  const c1 = firstChild.cloneNode(true);
  const c2 = lastChild.cloneNode(true);
  c1.style.display = "inline";
  c1.style.width = "auto";
  c1.style.visibility = "hidden";
  c2.style.display = "inline";
  c2.style.width = "auto";
  c2.style.visibility = "hidden";
  document.body.appendChild(c1);
  document.body.appendChild(c2);
  const truncated =
    c1.offsetWidth >= firstChild.clientWidth ||
    c2.offsetWidth >= lastChild.clientWidth;
  c1.remove();
  c2.remove();

  // eslint-disable-next-line consistent-return
  return truncated;
};

const Jobs = ({
  t,
  isAdmin,
  currentUser,
  fetchJobs,
  fetchLatestJobs,
  clearSetupState,
  cashCurrentJob,
  duplicateJob,
  clearStatuses,
  dataJobs,
  dataJobsPagination,
  setModal,
  isLatestJobsLoading,
  filter,
  deleteJob,
  restoreJob,
  clearFilter,
  updated,
  setUpdated,
  isFree,
  profileInfo,
  maximumJobs,
  onboarding
}) => {
  const [copied, setCopied] = useState("");
  const firstUpdate = useRef(true);
  const itemsRef = useRef([]);
  const [lastFilter, setlastFilter] = useState(filter);

  const isMaximumJobs = maximumJobs <= onboarding?.agency_jobs_amount;

  const fetchData = (currentUserKey, newFilter) => {
    const withFilterUpdate =
      lastFilter.title === newFilter.title &&
      (lastFilter.companies !== filter.companies ||
        lastFilter.owners !== filter.owners ||
        lastFilter.statuses !== filter.statuses);

    if (currentUserKey) {
      fetchJobs({ key: currentUserKey, filter: newFilter, withFilterUpdate });
      setlastFilter(newFilter);
    }
  };
  const fetchDataDebounce = debounce((currentUserKey, newFilter) => {
    fetchData(currentUserKey, newFilter);
  }, 500);

  useEffect(() => {
    clearSetupState();
    clearFilter();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      fetchData(currentUser.key, filter);
      return;
    }
    if (updated) {
      setUpdated(false);
    } else {
      fetchDataDebounce(currentUser.key, filter);
    }
  }, [currentUser.key, filter]);

  const handleDuplicate = useCallback(
    value =>
      duplicateJob({
        value,
        currentUserKey: currentUser.key,
        filter
      }),
    [currentUser.key, filter]
  );

  const handleDelete = useCallback(value => {
    setModal({
      type: "deleteJobConfirmation",
      jobConfirmation: { jobId: value, queryParam: "delete" }
    });
  });

  const handleCurrentJob = useCallback(key => {
    if (key && Number(dataJobs?.length) > 0) {
      const currentJob = dataJobs.find(job => job.key === key);
      cashCurrentJob(currentJob);
    }
  });

  const handleCopy = (id, inviteLink) => {
    setCopied(id);
    copyToClipboard(inviteLink);

    setTimeout(() => {
      setCopied("");
    }, 3000);
  };

  const { next, count } = dataJobsPagination;

  return (
    <Table
      t={t}
      next={next}
      fetchLatestItems={fetchLatestJobs}
      isLoading={isLatestJobsLoading}
      count={count}
      header={[
        {
          Header: t("job.table.interview"),
          accessor: "title",
          headerClassName: "left-align-column",
          width: 230,
          Cell: ({
            original: { title, key, status, company_name: companyName },
            index,
            column
          }) => (
            <div className="jobs__table-vertical-center">
              <TooltipInfo
                prefix="tooltip__icon-duplicate without-styles align-center md"
                position="right"
                message={`${title}\n${companyName}`}
                disabled={
                  !checkIsEllipsisActive(
                    itemsRef.current[`${column.id}${index}`]
                  )
                }
                element={
                  <Link
                    className="jobs__table-link"
                    to={
                      status === JOB_STATUSES.DRAFT
                        ? `/jobs/edit/${key}`
                        : `/jobs/overview/${key}`
                    }
                    onClick={() => handleCurrentJob(key)}
                  >
                    <div
                      className={
                        status === JOB_STATUSES.CLOSED
                          ? "table-cell_disabled"
                          : null
                      }
                      // eslint-disable-next-line no-return-assign
                      ref={el =>
                        (itemsRef.current[`${column.id}${index}`] = el)
                      }
                    >
                      <p className="jobs__table-text n-font-small n-font-medium-weight n-grey-60">
                        {title}
                        {status === JOB_STATUSES.DRAFT ? <Edit /> : null}
                      </p>
                      <p className="jobs__table-text n-font-extra-small n-font-regular n-black-50 without-hover">
                        {companyName}
                      </p>
                    </div>
                  </Link>
                }
              />
            </div>
          ),
          style: { lineHeight: 26 }
        },
        {
          Header: t("job.table.owner"),
          accessor: "company_name",
          headerClassName: "left-align-column",
          width: 220,
          Cell: ({
            original: {
              created_by_name: createdByName,
              created_by_email: createdByEmail
            },
            index,
            column
          }) => (
            <div className="jobs__table-vertical-center">
              <TooltipInfo
                prefix="tooltip__icon-duplicate without-styles align-center md"
                position="right"
                message={`${createdByName}\n${createdByEmail}`}
                disabled={
                  !checkIsEllipsisActive(
                    itemsRef.current[`${column.id}${index}`]
                  )
                }
                element={
                  <div
                    // eslint-disable-next-line no-return-assign
                    ref={el => (itemsRef.current[`${column.id}${index}`] = el)}
                  >
                    <p className="jobs__table-text n-font-small n-font-medium-weight n-grey-60">
                      {createdByName}
                    </p>
                    <p className="jobs__table-text n-font-extra-small n-font-regular n-black-50">
                      {createdByEmail}
                    </p>
                  </div>
                }
              />
            </div>
          ),
          style: { lineHeight: 26 }
        },
        {
          Header: t("job.table.endDate"),
          accessor: "deadline",
          headerClassName: "left-align-column",
          width: 150,
          Cell: ({ original: { deadline } }) => (
            <p className="jobs__table-vertical-center jobs__table-text n-font-small n-font-medium-weight n-grey-60">
              {deadline
                ? moment(deadline).format(
                    DATE_FORMATS.SLASH_SEPARATOR_LONG_YEAR
                  )
                : ""}
            </p>
          )
        },
        {
          Header: t("job.table.responses"),
          accessor: "candidates_cnt",
          headerClassName: "left-align-column",
          width: 150,
          Cell: ({
            original: {
              candidates_cnt: candidates = 0,
              new_candidates_cnt: newCandidates = 0,
              accepted_cnt: accepted = 0,
              rejected_cnt: rejected = 0,
              key,
              status
            }
          }) =>
            (status === JOB_STATUSES.DRAFT ? null : (
              <div className="jobs__table-vertical-center">
                <TooltipInfo
                  prefix="tooltip__icon-duplicate without-styles align-center md"
                  position="left"
                  message={`${t(
                    "widgets.progressSend.accepted"
                  )}: ${accepted} ${t(
                    "widgets.progressSend.rejected"
                  )}: ${rejected}`}
                  element={
                    <Link
                      to={`/jobs/overview/${key}`}
                      onClick={() => handleCurrentJob(key)}
                    >
                      <span className="table-cell_response n-font-regular n-grey-60">
                        {candidates}
                        {newCandidates ? (
                          <span className="n-purple-100">
                            {t("link.new", { newCandidates })}
                          </span>
                        ) : null}
                      </span>
                    </Link>
                  }
                />
              </div>
            ))
        },
        {
          Header: t("job.table.status"),
          accessor: "status",
          headerClassName: "left-align-column",
          minWidth: 150,
          maxWidth: 200,
          style: { display: "flex", justifyContent: "flex-start" },
          Cell: ({ original: { status, ...rest }, column, index }) => {
            const atsJobs =
              rest?.ats?.map(a => {
                const list = removeDuplicates(
                  a.callbacks,
                  "remote_interview_id"
                );

                return {
                  ...a,
                  count: list?.length
                };
              }) ?? [];

            return (
              <div className="jobs__table-status-wrapper">
                <div className="jobs__table-status">
                  <p
                    className={`jobs__table-status-title n-font-extra-small n-font-regular ${status}`}
                  >
                    {t(`job.table.${status}`)}
                  </p>
                </div>
                {atsJobs?.map((ats, i) => (
                  <TooltipInfo
                    key={String(i)}
                    prefix="tooltip__icon-duplicate without-styles align-center md"
                    position="right"
                    // eslint-disable-next-line no-nested-ternary
                    message={
                      ats?.count > 1
                        ? `Connected to ${ats?.count} jobs`
                        : ats?.count === 1
                        ? `Connected to ${ats.callbacks?.[0]?.remote_interview_name}`
                        : ""
                    }
                    element={
                      <div
                        // eslint-disable-next-line no-return-assign
                        ref={el =>
                          (itemsRef.current[`${column.id}${index}`] = el)
                        }
                      >
                        <div className="ats-logo">
                          <img src={ats.image_url} alt="ats logo" />
                        </div>
                      </div>
                    }
                  />
                ))}
                {!rest?.ats && rest.has_ats_connection && (
                  <ClipLoader
                    color="#BBC2C9"
                    size={20}
                    margin={1}
                    loading
                    style={{
                      marginRight: 5
                    }}
                  />
                )}
              </div>
            );
          }
        },
        {
          accessor: false,
          Cell: ({
            original: {
              key,
              invite_link: inviteLink,
              created_by_email: createdBy = "",
              status
            }
          }) =>
            (status === JOB_STATUSES.CLOSED ? (
              <p className="candidate__button-switcher table" style={{ padding: 0 }}>
                <span className="candidate__button-switcher-main">
                  <Switcher
                    checked={status === JOB_STATUSES.LIVE}
                    handleOn={() => deleteJob({ jobId: key })}
                    handleOff={() => restoreJob(key)}
                    disabled={status !== JOB_STATUSES.LIVE && isMaximumJobs}
                  />
                </span>
              </p>
            ) : (
              <div className="tooltip__group">
                {status === JOB_STATUSES.DRAFT ? null : (
                  <TooltipInfo
                    prefix={`tooltip__icon-duplicate without-styles align-center md tooltip__icon-with-message ${copied ===
                      key && "tooltip__success"}`}
                    position="left"
                    message={
                      isMobile || copied === key
                        ? t("job.table.copy_success")
                        : t("job.table.copy")
                    }
                    isShow={copied === key}
                    element={
                      <CopyButton
                        isCopied={copied === key}
                        handleCopy={() => handleCopy(key, inviteLink)}
                      />
                    }
                  />
                )}
                {isAdmin || profileInfo?.email === createdBy ? (
                  <TooltipInfo
                    prefix="tooltip__icon-duplicate without-styles align-center md"
                    position="left"
                    message={t("job.table.edit")}
                    element={
                      <Link
                        to={`/jobs/edit/${key}`}
                        onClick={clearStatuses}
                        className="link__edit"
                      >
                        <Edit />
                      </Link>
                    }
                  />
                ) : null}
              </div>
            )),
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 0 },
          minWidth: 155
        },
        {
          accessor: false,
          Cell: ({ original: { key } }) => (
            <>
              {(!isFree || !isMaximumJobs) ? (
                <TooltipInfo
                  prefix="tooltip__icon-duplicate tooltip__icon-margin without-styles align-center md"
                  position="left"
                  message={t("job.table.duplicate")}
                  element={
                    <DuplicateButton
                      handleDuplicate={() => handleDuplicate(key)}
                    />
                  }
                />
              ) : null}
            </>
          ),
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 0 },
          width: (isFree || isMaximumJobs) ? 0 : 30
        },
        {
          accessor: false,
          Cell: ({ original: { key, created_by_email: createdBy = "" } }) => {
            if (isAdmin || profileInfo?.email === createdBy) {
              return <DeleteButton handleDelete={() => handleDelete(key)} />;
            }
            return null;
          },
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 10 },
          width: 30,
          sortable: false
        }
      ]}
      body={dataJobs || []}
    />
  );
};

Jobs.defaultProps = {
  filter: { title: "", company: null, companies: [], statuses: [], owners: [] },
  profileInfo: undefined,
  notifications: undefined,
  isLatestJobsLoading: undefined,
  column: undefined,
  index: undefined,
  original: undefined,
  onboarding: undefined
};

Jobs.propTypes = {
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.shape({
      email: PropTypes.string
    })
  }).isRequired,
  original: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    created_by: PropTypes.string,
    deadline: PropTypes.string,
    status: PropTypes.string,
    ats: PropTypes.shape({}),
    created_by_email: PropTypes.string,
    created_by_name: PropTypes.string,
    candidates_cnt: PropTypes.string,
    new_candidates_cnt: PropTypes.string,
    accepted_cnt: PropTypes.string,
    rejected_cnt: PropTypes.string,
    invite_link: PropTypes.string,
    company_name: PropTypes.string
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  index: PropTypes.number,
  column: PropTypes.shape({
    id: PropTypes.string
  }),
  dataJobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataJobsPagination: PropTypes.shape({
    next: PropTypes.string,
    count: PropTypes.number
  }).isRequired,
  cashCurrentJob: PropTypes.func.isRequired,
  fetchJobs: PropTypes.func.isRequired,
  fetchLatestJobs: PropTypes.func.isRequired,
  duplicateJob: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  clearStatuses: PropTypes.func.isRequired,
  isLatestJobsLoading: PropTypes.bool,
  filter: PropTypes.shape({
    title: PropTypes.string,
    company: PropTypes.string,
    companies: PropTypes.array.isRequired,
    owners: PropTypes.array,
    statuses: PropTypes.array
  }),
  clearSetupState: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  restoreJob: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  setUpdated: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  notifications: PropTypes.shape({
    is_trial_period: PropTypes.bool.isRequired
  }),
  profileInfo: PropTypes.shape({
    email: PropTypes.string
  }),
  onboarding: PropTypes.shape({
    agency_jobs_amount: PropTypes.number
  }),
  maximumJobs: PropTypes.number.isRequired

};

const mapStateToProps = state => ({
  maximumJobs: getJobsMaximum(state),
  onboarding: state.dashboard?.onboarding
});
export default connect(mapStateToProps)(withTranslation()(withLoader(Jobs)));
