import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { LinkedIn } from "react-linkedin-login-oauth2";
import { GoogleLogin } from "@react-oauth/google";
import { LinkedInIcon } from "mixins/svgIcons";
import { getLanguage } from "store/modules/language/selectors";
import { store } from "store";

const { Rewardful } = window;

const handleFailureLinkedIn = err => {
  console.error(err);
};

const handleFailureGoogle = err => {
  console.error("[handleFailure]", err);
};

const SocialsLogin = ({ t, signInWithLinkedIn, signInWithGoogle, code }) => {
  const currentLanguage = getLanguage(store.getState());

  const handleSuccessLinkedIn = data => {
    signInWithLinkedIn({
      provider: "linkedin",
      access_token: data.code,
      referral: Rewardful?.referral,
      promo_code: code
    });
  };

  const handleSuccessGoogle = data => {
    signInWithGoogle({
      provider: "google",
      access_token: data?.credential,
      referral: Rewardful?.referral,
      promo_code: code
    });
  };

  return (
    <div className="social-login">
      <LinkedIn
        type="linkedin"
        scope="r_liteprofile r_emailaddress w_member_social"
        clientId={process.env.REACT_APP_LINKED_CLIENT_ID}
        onSuccess={handleSuccessLinkedIn}
        onFailure={handleFailureLinkedIn}
        className={classNames(
          "button",
          "button__social",
          "button__social_blue",
          "main-link",
          "main-link__without-decoration"
        )}
        redirectUri={`${process.env.REACT_APP_FRONTEND_URL}/linked`}
      >
        <span
          className={classNames(
            "button__social_icon",
            "button__social_icon_blue"
          )}
        >
          <LinkedInIcon />
        </span>
        <span className="button__social_text">{t("button.linked")}</span>
      </LinkedIn>

      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={handleSuccessGoogle}
        onFailure={handleFailureGoogle}
        redirectUri={process.env.REACT_APP_FRONTEND_URL}
        size="large"
        text="continue_with"
        shape="square"
        width={350}
        locale={currentLanguage}
        type="standard"
      />

    </div>
  );
};

SocialsLogin.defaultProps = {
  signInWithLinkedIn: undefined,
  signInWithGoogle: undefined,
  code: undefined
};

SocialsLogin.propTypes = {
  t: PropTypes.func.isRequired,
  signInWithLinkedIn: PropTypes.func,
  signInWithGoogle: PropTypes.func,
  code: PropTypes.string
};

export default withTranslation()(SocialsLogin);
