/* eslint-disable no-nested-ternary */
import ArrowButtonLeft from "components/Common/ArrowButtons/ArrowButtonLeft";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import React from "react";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import ArrowButtonRight from "components/Common/ArrowButtons/ArrowButtonRight";
import PropTypes from "prop-types";
import { Avatar } from "mixins/svgIcons";
import PublicCandidateContact from "../Item/PublicCandidateContact";

import "./styles.scss";

const CandidateHeader = ({
  t,
  selected,
  setSelected,
  currentIndex,
  nextIndex,
  items,
  company: {
    layout_color: layoutColor
  } = {},
  company,
  isLoading,
  candidate
}) => (
  <div
    className="flex flex-row justify-between w-full public-candidate__header-wrapper"
    style={{
      backgroundColor: layoutColor && layoutColor !== "#ffffff" ? `${layoutColor}25` : "#f4f3fa"
    }}
  >
    <div
      className="public-candidate__header flex flex-row justify-center w-full"
      style={{
        overflow: "hidden"
      }}
    >
      <div
        className="floating-background"
        style={{
          backgroundColor: layoutColor && layoutColor !== "#ffffff" ? layoutColor : "#5a2af1"
        }}
      />

      <div className="flex justify-center items-center arrow">
        {!isMobile && window.innerWidth >= 912 && selected && (
          <ArrowButtonLeft
            currentIndex={currentIndex}
            nextIndex={nextIndex}
            items={items}
            setSelected={setSelected}
          />
        )}
      </div>

      <div className="public-candidate__header-item container">
        <div className="public-candidate__header-info">
          <SkeletonWrapper
            skeletonProps={{
              circle: true,
              width:
                window.innerWidth > 1024
                  ? 152
                  : window.innerWidth > 768
                    ? 120
                    : 80,
              height:
                window.innerWidth > 1024
                  ? 152
                  : window.innerWidth > 768
                    ? 120
                    : 80,
              enableAnimation: !candidate?.anonymized_at
            }}
            skeletonWrapperProps={{
              style: {
                paddingRight: 12
              }
            }}
            anonymizedDate={candidate?.anonymized_at}
            isLoading={isLoading}
          >
            {candidate.avatar_image_remote_link ? (
              <img
                src={candidate.avatar_image_remote_link}
                className="public-candidate__item-avatar"
                alt="avatar"
              />
            ) : (
              <div className="public-candidate__item-avatar public-candidate__item-avatar-empty">
                <Avatar />
              </div>
            )}
          </SkeletonWrapper>
          <div className="public-candidate__item-name-container">
            <SkeletonWrapper
              skeletonProps={{
                count: 1,
                height: 16,
                width: 180,
                enableAnimation: !candidate?.anonymized_at
              }}
              anonymizedDate={candidate?.anonymized_at}
              isLoading={isLoading}
            >
              <p className="public-candidate__item-name n-black-80 n-font-semi-bold">
                {candidate.name}
              </p>
            </SkeletonWrapper>
            <SkeletonWrapper
              skeletonProps={{
                count: 1,
                height: 16,
                width: 150,
                enableAnimation: !candidate?.anonymized_at
              }}
              anonymizedDate={candidate?.anonymized_at}
              isLoading={isLoading}
            >
              {selected ? (
                <p className="n-grey-100 n-font-medium">
                  {t("candidate.common.participant", {
                    current:
                      items.findIndex(
                        ({ key }) => key === selected
                      ) + 1,
                    total: items.length
                  })}
                </p>
              ) : null}
            </SkeletonWrapper>
          </div>
        </div>
        {candidate?.showcase_contact_info && (
          <PublicCandidateContact
            contactInfo={candidate?.showcase_contact_info}
            isLoading={isLoading}
            company={company}
          />
        )}
      </div>

      <div className="flex justify-center items-center arrow">
        {!isMobile && window.innerWidth >= 912 && selected && (
          <ArrowButtonRight
            currentIndex={currentIndex}
            nextIndex={nextIndex}
            items={items}
            setSelected={setSelected}
          />
        )}
      </div>
    </div>
  </div>
  );

CandidateHeader.propTypes = {
  t: PropTypes.func.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func.isRequired,
  currentIndex: PropTypes.number,
  nextIndex: PropTypes.number,
  items: PropTypes.shape([]),
  company: PropTypes.shape({
    layout_color: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  candidate: PropTypes.shape({
    avatar_image_remote_link: PropTypes.string,
    name: PropTypes.string,
    anonymized_at: PropTypes.string,
    showcase_contact_info: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
      utc_minutes_delta: PropTypes.number,
      location: PropTypes.shape({
        city: PropTypes.string,
        country_name: PropTypes.string,
        country_code: PropTypes.string,
        google_map_link: PropTypes.string
      }),
      status: PropTypes.string,
      anonymized_at: PropTypes.string
    })
  })
};

CandidateHeader.defaultProps = {
  selected: "",
  items: [],
  company: {},
  isLoading: true,
  candidate: {},
  nextIndex: null,
  currentIndex: null
};

export default (withTranslation()(CandidateHeader));
