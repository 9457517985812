import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Check } from "mixins/svgIcons";

const InputName = ({
  name,
  mediaExtension,
  onSave
}: {
  name: string;
  mediaExtension: string;
  onSave: (name: string) => void;
}) => {
  const [newName, setName] = useState(name);
  const isDisabled = newName?.length > 128;
  const handleSave = () => {
    const newValue = newName?.trim() || name;

    onSave(newValue.length > 128 ? newValue.slice(0, 128) : newValue);
  };
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.focus();
    ref.current.select();
  }, []);

  return (
    <form onSubmit={handleSave} className="dzu-previewFileNameIcon">
      <input
        ref={ref}
        placeholder="File name"
        className="dzu-inputFileName"
        onChange={e => setName(e.target.value)}
        value={newName}
      />
      <span className="dzu-previewFileName extension">{`.${mediaExtension}`}</span>
      <button
        type="submit"
        className="button__delete"
        disabled={isDisabled}
      >
        <Check fill="#595959" width="18" height="14" />
      </button>
    </form>
  );
};

InputName.propTypes = {
  name: PropTypes.string.isRequired,
  mediaExtension: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired
};

export default InputName;
