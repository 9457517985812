import { getRatingIcon, getRatingToString } from "mixins/helperCandidate";
import PropTypes from "prop-types";
import React from "react";
import "./styles.scss";
import { withTranslation } from "react-i18next";

function hexToRgb(hex) {
  if (!hex) return null;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16
  )}, ${parseInt(result[3], 16)}, 0.3)`;
}

const InterviewScorecardPill = ({
  t,
  score,
  total,
  fontSize = 14,
  fontColor = null,
  iconHeight = undefined,
  iconWidth = undefined,
  buttonHeight = undefined,
  iconOnly = false
}) => {
  const rating = getRatingToString({
    t,
    rating: score,
    isActive: true
  });

  return (
    <div
      className="rating_icon"
      style={{
        color: fontColor ?? rating?.color,
        backgroundColor: hexToRgb(rating?.color),
        minHeight: buttonHeight
      }}
    >
      <div>
        {
          getRatingIcon({
            rating: rating?.untranslated_label,
            isActive: true,
            height: iconHeight,
            width: iconWidth
          })?.icon
        }
      </div>
      <div style={{ fontSize }}>
        {`${!iconOnly && rating?.label ? rating?.label : ""}${
          total ? ` (${total})` : ""
        }`}
      </div>
    </div>
  );
};

InterviewScorecardPill.defaultProps = {
  total: 0,
  fontSize: 14,
  fontColor: null,
  iconHeight: undefined,
  iconWidth: undefined,
  score: 0,
  buttonHeight: undefined,
  iconOnly: false
};

InterviewScorecardPill.propTypes = {
  t: PropTypes.func.isRequired,
  buttonHeight: PropTypes.number,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconOnly: PropTypes.bool,
  iconWidth: PropTypes.string,
  score: PropTypes.number,
  total: PropTypes.number
};

export default withTranslation()(InterviewScorecardPill);
