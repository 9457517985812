/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Confetti from "react-confetti";
import { ButtonGroup, Button, DropdownButton, Dropdown } from "react-bootstrap";

import CANDIDATE_STATUSES from "configs/candidates";
import TooltipInfo from "components/Common/Tooltip/Info";
import {
  shareModalConfirmation,
  rejectDetailsConfirmation
} from "utils/confirm";
import { getRatingToString, handleStatus } from "mixins/helperCandidate";

import "./styles.scss";
import { size } from "lodash";
import { getCandidateScores } from "store/modules/scorecards/selectors";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Accept, Reject, Share, Remind, Delete, Down } from "./svgIcons";
import InterviewScorecardPill from "./InterviewScorecardPill";

const Buttons = ({
  t,
  userId,
  jobId,
  jobCandidateInfo: {
    name = "",
    email = "",
    status = "",
    allow_reminder_sending: allowReminderSending
  },
  currentUser: { key: agency },
  jobCurrent,
  isAdmin,
  profileInfo,
  sharableLink = "",
  showcaseLinkOpenedAmount,
  isShowcaseLinkEnabled,
  answers,
  shareCandidatePublic,
  clearSharableLink,
  updateCandidateStatus,
  sendReminderCandidate,
  setAvailableAnswer,
  setAvailableLink,
  deleteCandidate,
  moveToNext,
  contentRef,
  hasReminder,
  handleMoveToNextAfterAccept,
  candidateScores,
  handleGoToScorecard
}) => {
  const scorecardTotal = candidateScores.find(a => a.is_total) ?? {};
  const scores = candidateScores?.filter(a => !a.is_total);
  const rating = getRatingToString({
    t,
    rating: Math.floor(scorecardTotal?.general_score),
    isActive: true
  });

  const [isDeleteButtonVisible, setDeleteButtonVisible] = useState(true);
  const [run, setRun] = useState(false);
  const candidateStatus = handleStatus(status);
  const handleAvailableAnswer = params =>
    setAvailableAnswer({
      ...params,
      jobId,
      userId
    });
  const handleAvailableLink = available =>
    setAvailableLink({
      available,
      jobId,
      userId
    });

  useEffect(() => {
    if (sharableLink) {
      shareModalConfirmation(name, {
        sharableLink,
        clearSharableLink,
        setAvailableAnswer: handleAvailableAnswer,
        setAvailableLink: handleAvailableLink,
        answers,
        showcaseLinkOpenedAmount,
        isShowcaseLinkEnabled
      });
    }
  }, [clearSharableLink, name, sharableLink]);
  const callback = () => setRun(true);
  const handleReject = useCallback(async () => {
    const reason = await rejectDetailsConfirmation();
    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.REJECTED,
      agency,
      jobId,
      ...reason
    });
  }, [updateCandidateStatus, name, email, userId, agency]);

  const rejectAndMove = async () => {
    const reason = await rejectDetailsConfirmation();
    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.REJECTED,
      agency,
      jobId,
      callback: handleMoveToNextAfterAccept,
      ...reason
    });
  };

  const handleAccept = useCallback(
    async (withCallback = true) => {
      await updateCandidateStatus({
        name,
        email,
        userId,
        status: CANDIDATE_STATUSES.ACCEPTED,
        isAccept: true,
        agency,
        jobId,
        callback: withCallback ? callback : null
      });
    },
    [updateCandidateStatus, name, email, userId, agency]
  );
  const acceptAndMove = async () => {
    await updateCandidateStatus({
      name,
      email,
      userId,
      status: CANDIDATE_STATUSES.ACCEPTED,
      isAccept: true,
      agency,
      jobId,
      callback: handleMoveToNextAfterAccept
    });
  };

  const shareCandidateHandler = () => shareCandidatePublic({ userId, jobId });
  const handleSendReminder = () => {
    sendReminderCandidate({ userId, jobId });
  };
  const handleHideDelete = useCallback(() => {
    setDeleteButtonVisible(false);
    setTimeout(() => setDeleteButtonVisible(true), 5000);
  }, []);
  const handleDelete = useCallback(
    () => deleteCandidate({ userId, jobId, agency }),
    [deleteCandidate, userId, jobId, agency]
  );

  return (
    <div className="button__candidate_wrapper">
      {status === CANDIDATE_STATUSES.INVITED ? (
        <>
          <TooltipInfo
            element={null}
            position="bottom"
            message={t(
              jobCurrent?.status === "closed"
                ? "job.overview.reminderDisabled"
                : hasReminder
                  ? "job.overview.reminderTooltip"
                  : "job.overview.firstReminderTooltip"
            )}
            disabled={allowReminderSending}
          >
            <div className="wrap-button">
              <button
                className={`n-border-black-5 n-bg-black-5 n-black-50 n-font-small share ${
                  !allowReminderSending ? "disabled" : ""
                }`}
                type="button"
                onClick={allowReminderSending && handleSendReminder}
                disabled={!allowReminderSending}
              >
                <Remind />
                <span>{t("button.sendReminder")}</span>
              </button>
            </div>
          </TooltipInfo>
          {isAdmin || profileInfo.email === jobCurrent.created_by_email ? (
            <button
              className="n-border-red-200 n-bg-red-200 n-white n-font-small reject"
              type="button"
              onClick={isDeleteButtonVisible ? handleHideDelete : handleDelete}
            >
              <Delete />
              <span>
                {t(
                  isDeleteButtonVisible
                    ? "button.delete"
                    : "job.candidate.really"
                )}
              </span>
            </button>
          ) : null}
        </>
      ) : (
        <>
          {(size(scores) > 0) ? (
            <div
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() => handleGoToScorecard?.()}
            >
              <TooltipInfo
                prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                position="left"
                message={
                      rating?.untranslated_label
                        ? t(`scorecard.people.rating.${rating?.untranslated_label ?? ""}`)
                        : null
                    }
                disabled={!rating?.untranslated_label}
              >
                <div className="">
                  <div className="people__table-rating-stars">
                    <InterviewScorecardPill
                      score={scorecardTotal?.general_score}
                      fontSize={12}
                      total={size(scores)}
                      iconWidth={16}
                      iconHeight={16}
                      buttonHeight={36}
                      iconOnly={isMobile}
                    />
                  </div>
                </div>
              </TooltipInfo>
            </div>
              ) : null}
          {(isAdmin || profileInfo.email === jobCurrent.created_by_email) && (
            <>
              <ButtonGroup
                className={`button-dropdown ${moveToNext ? "" : "single"}`}
              >
                <TooltipInfo
                  element={null}
                  position="bottom"
                  message={t("job.overview.acceptTooltip")}
                >
                  <Button
                    className={
                      candidateStatus === "accepted"
                        ? "n-green-100 n-border-green-100 n-bg-white n-font-small"
                        : "n-white n-border-green-100 n-bg-green-100 n-font-small accept"
                    }
                    type="button"
                    onClick={handleAccept}
                    variant={false}
                  >
                    <Accept />
                    <span>
                      {t(
                        candidateStatus === "accepted"
                          ? "button.accepted"
                          : "button.accept"
                      )}
                    </span>
                  </Button>
                </TooltipInfo>

                {moveToNext ? (
                  <DropdownButton
                    as={ButtonGroup}
                    title={<Down />}
                    id="bg-nested-dropdown"
                    className={
                      candidateStatus === "accepted"
                        ? "n-green-100 n-border-green-100 n-bg-white"
                        : "accept"
                    }
                    alignRight
                  >
                    <Dropdown.Item onClick={acceptAndMove} type="button">
                      {t("button.acceptAndMove")}
                    </Dropdown.Item>
                  </DropdownButton>
                ) : null}
              </ButtonGroup>

              <ButtonGroup
                className={`button-dropdown ${moveToNext ? "" : "single"}`}
              >
                <TooltipInfo
                  element={null}
                  position="bottom"
                  message={t("job.overview.acceptTooltip")}
                >
                  <Button
                    className={
                      candidateStatus === "rejected"
                        ? "n-border-red-200 n-bg-white n-red-200 n-font-small rejected"
                        : "n-bg-red-200 n-font-small reject"
                    }
                    type="button"
                    onClick={handleReject}
                    variant={false}
                  >
                    <Reject />
                    <span>
                      {t(
                        candidateStatus === "rejected"
                          ? "button.rejected"
                          : "button.reject"
                      )}
                    </span>
                  </Button>
                </TooltipInfo>
                {moveToNext ? (
                  <DropdownButton
                    as={ButtonGroup}
                    title={<Down />}
                    id="bg-nested-dropdown"
                    className={
                      candidateStatus === "rejected"
                        ? "n-border-red-200 n-bg-white n-red-200 n-font-small rejected"
                        : "reject"
                    }
                    alignRight
                  >
                    <Dropdown.Item onClick={rejectAndMove} type="button">
                      {t("button.rejectAndMove")}
                    </Dropdown.Item>
                  </DropdownButton>
                ) : null}
              </ButtonGroup>
            </>
          )}
          <button
            className="n-border-black-5 n-bg-black-5 n-black-50 n-font-small share"
            type="button"
            onClick={shareCandidateHandler}
          >
            <Share />
            <span>{t("button.share")}</span>
          </button>
        </>
      )}
      {run ? (
        <Confetti
          numberOfPieces={500}
          width={contentRef.current?.offsetWidth || window.innerWidth}
          height={contentRef.current?.offsetHeight || window.innerHeight}
          recycle={false}
          onConfettiComplete={() => setRun(false)}
        />
      ) : null}
    </div>
  );
};

Buttons.defaultProps = {
  moveToNext: undefined,
  isShowcaseLinkEnabled: undefined,
  deleteCandidate: undefined
};

Buttons.propTypes = {
  t: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  jobCandidateInfo: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.string,
    rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    allow_reminder_sending: PropTypes.bool,
    avatar_image_remote_link: PropTypes.string
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  profileInfo: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  sharableLink: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.shape({}),
      remote_link: PropTypes.string
    })
  ).isRequired,
  jobCurrent: PropTypes.shape({
    created_by_email: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    }),
    status: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  shareCandidatePublic: PropTypes.func.isRequired,
  clearSharableLink: PropTypes.func.isRequired,
  updateCandidateStatus: PropTypes.func.isRequired,
  setAvailableAnswer: PropTypes.func.isRequired,
  handleMoveToNextAfterAccept: PropTypes.func.isRequired,
  setAvailableLink: PropTypes.func.isRequired,
  sendReminderCandidate: PropTypes.func.isRequired,
  showcaseLinkOpenedAmount: PropTypes.number.isRequired,
  isShowcaseLinkEnabled: PropTypes.bool,
  deleteCandidate: PropTypes.func,
  moveToNext: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  contentRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) })
    .isRequired,
  hasReminder: PropTypes.bool.isRequired,
  candidateScores: PropTypes.bool.isRequired,
  handleGoToScorecard: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  candidateScores: getCandidateScores(state)
});

export default connect(mapStateToProps)(withTranslation()(Buttons));
