import pick from "lodash/pick";

export const getIsJobsLoading = state => state.jobs.isLoading;
export const getIsLatestJobsLoading = state => state.jobs.isLatestJobsLoading;

export const getJobsData = state => state?.jobs?.tablesData?.results || [];
export const getJobsDataPagination = state =>
  pick(state.jobs.tablesData, ["count", "next", "previous"]) || {};

export const getJobsSuccess = state => state.jobs.success;
export const getJobsError = state => state.jobs.error;

export const getPreviewError = state => state.jobsPreview.error;
export const getJobsErrorWithFields = state => state?.jobsPreview?.errorWithFields || [];
export const getSuggestions = state => state.jobsQuestions.suggestions;

export const getJobInvitesData = state => state.jobs.jobInvitesData;
export const getJobInvitesError = state => state.jobs.error;
export const getJobInvitesIsLoading = state => state.jobs.isLoading;
export const getJobInvitesParseData = state => state.jobs.jobInvitesParseData;
export const getParseError = state => state.jobs.parseError;

export const getIsCandidateStatsLoading = state =>
  state?.jobsOverview?.isCandidatesStatsLoading;
export const getIsJobStatsLoading = state =>
  state.jobsOverview.isJobStatsLoading;

export const getJobInvitesSending = state =>
  state.jobs.isSendingUploading || false;

export const getJobCurrent = state => state.jobs.jobCurrent || {};
export const getJobCompany = state => getJobCurrent(state).company || {};

export const getUserCurrentJob = state =>
  state.jobsCandidate.currentCandidateJob || {};

export const getUserCurrentInterviewersList = state =>
  state?.jobsOverview?.interviewersByStatusList?.results || [];

export const getUserCurrentInterviewersPagination = state =>
  state?.jobsOverview?.interviewersByStatusList?.next || undefined;

export const getSelectedCandidates = state =>
  state.jobsOverview.selectedCandidates || [];

export const getHasSelectAllStatus = state =>
  state.jobsOverview.selectAll || {};

export const getTotalSelectedByStatus = state =>
  state.jobsOverview.totalSelectedCount || {};

export const getExcludedCandidates = state =>
  state.jobsOverview.excludedCandidates || [];

export const getSelectedCandidatesLoading = state =>
  state.jobsOverview.isSelectedCandidatesLoading;

export const getCandidateAnswers = state =>
  state.jobsCandidate.candidateAnswers || [];

export const getCandidateHistory = state =>
  state.jobsCandidate.candidateHistory || [];

export const getCandidateHistoryLoading = state =>
  state?.jobsCandidate?.candidateHistoryLoading || false;

export const getComments = state => getUserCurrentJob(state).comments || [];

export const getInterviewers = state =>
  state.jobsOverview.interviewersList || {};

export const getNextCandidates = state => state.jobsOverview.nextCandidates;

export const getInterviewersProgress = state =>
  state.jobsOverview.interviewersProgress || {};

export const getInterviewersFilter = state =>
  state.jobsOverview.interviewersFilter || {};

export const getIsJobLoading = state => state.jobs.isLoading;

export const getJobInvitesSend = state => state.jobs.isJobInvitesSend;

// Options for select
export const getCompaniesOptionsForSelect = ({
  jobsSetup: { companiesOptionsResults = [] }
}) => {
  const updatedOptionsForSelect = companiesOptionsResults.map(
    ({ key, name, owner_full_name: fullName, is_owner: isOwner }) => ({
      label: `${name}${isOwner ? "" : ` - ${fullName}`}`,
      value: key
    })
  );

  return updatedOptionsForSelect;
};

export const getJobSetupValid = state => state.jobStatus.isJobSetupValid;
export const getJobQuestionsValid = state =>
  state.jobStatus.isJobQuestionsValid;

export const getJobUsers = state => state.jobsCandidate.jobUsers || [];
export const getUsersCanSeeCount = state =>
  state.jobsCandidate.usersCanSeeCount || [];
export const getJobUsersModal = state =>
  state.jobsCandidate.jobUsersModal || [];
export const getNextJobUsers = state => state.jobsCandidate.nextJobUsers;

export const getJobsFilters = state => state.jobs?.tablesFilters || {};
export const getJobsFiltersLoading = state => state.jobs?.isFiltersLoading;

export const getLanguagesOptions = state => state.jobs?.languagesOptions || [];

export const getCandidateIdvResponse = state =>
  state.jobsCandidate.idvResponse || {};

export const getCurrentQuestions = state =>
  state?.jobs?.jobCurrent?.questions || [];

export const getCurrentIdvActivationRedirect = state =>
  state?.jobsQuestions?.redirectUrl || null;
export const getCurrentQuestionLoading = state =>
  state?.jobsQuestions?.isLoading || false;

export const getCurrentActiveStep = state =>
  state?.jobsQuestions?.activeStep ?? "setup";

export const getIsUpgradeComplete = state =>
  state?.jobsQuestions?.isUpgradeComplete ?? null;

export const getJobAts = state =>
  state?.jobs?.jobAtsDetails || { isLoading: true };

export const getUserScoreDraftKey = state =>
  state?.jobsCandidate?.currentCandidateJob?.draft_score_key ?? "";

export const getIsCurrentCandidateScored = state =>
  state?.jobsCandidate?.currentCandidateJob?.is_scored ?? false;

export const getCanSeeCandidateScores = state =>
  state?.jobsCandidate?.currentCandidateJob?.can_see_scores ?? false;

export const getCandidatesExportLink = state => state?.jobs?.candidatesExportLink || "";

export const getIsJobCandidateLoading = state =>
  state?.jobsCandidate?.isJobCandidateLoading ?? false;
