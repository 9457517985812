import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Info } from "mixins/svgIcons";

const TooltipInfo = ({
  position,
  message,
  element = <Info />,
  prefix = " ",
  tooltipPrefix = " ",
  children = "",
  isShow,
  trigger,
  close,
  disabled,
  delay = {},
  popperConfig = {}
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) return;
    if (isShow) {
      ref.current.show();
    } else {
      ref.current.hide();
    }
  }, [isShow]);
  return disabled ? (
    children || <span className={`tooltip__icon ${prefix}`}>{element}</span>
  ) : (
    <OverlayTrigger
      ref={ref}
      key={position}
      trigger={trigger}
      placement={position}
      delay={delay}
      overlay={
        <Tooltip
          className={`tooltip__popup ${tooltipPrefix}`}
          onClick={() => close && ref.current.hide()}
        >
          {message}
        </Tooltip>
      }
      popperConfig={popperConfig}
    >
      {children || <span className={`tooltip__icon ${prefix}`}>{element}</span>}
    </OverlayTrigger>
  );
};

TooltipInfo.defaultProps = {
  position: undefined,
  message: undefined,
  element: undefined,
  children: undefined,
  prefix: undefined,
  tooltipPrefix: undefined,
  isShow: undefined,
  trigger: ["click", "hover", "focus"],
  close: false,
  disabled: false,
  delay: undefined,
  popperConfig: {}
};

TooltipInfo.propTypes = {
  position: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  element: PropTypes.element,
  children: PropTypes.element,
  prefix: PropTypes.string,
  tooltipPrefix: PropTypes.string,
  isShow: PropTypes.bool,
  trigger: PropTypes.arrayOf(PropTypes.string),
  close: PropTypes.bool,
  disabled: PropTypes.bool,
  delay: PropTypes.objectOf(PropTypes.string),
  popperConfig: PropTypes.shape({})
};

export default TooltipInfo;
