/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { handleQueryParams } from "mixins/helpers";

import { signUpConfig, signUpFormSchema } from "mixins/helperSignUp";

export default WrappedComponent => {
  const Wrapped = props => {
    const {
      match: {
        params: { code }
      },
      location: { search },
      signUp,
      confirmEmail,
      clearErrors,
      setLoadingScreen,
      ...rest
    } = props;

    const [signUpInfo, setSignUpInfo] = useState({});
    const [passwordFields, setPasswordFields] = useState({
      bar: false,
      description: false
    });
    const [signUpPredefindConfig, setSignUpPredefindConfig] = useState(null);
    const [isEmailLocked, setisEmailLocked] = useState(false);
    const [recaptchaCloseListener, setRecaptchaCloseListener] = useState(false);

    const refRecaptcha = useRef(null);

    const decodeEmail = useCallback(() => {
      const queryParams = handleQueryParams(search);
      return queryParams.email;
    }, [search]);

    const addPredefinedEmail = useCallback(() => {
      const email = decodeEmail();

      if (email) {
        const predefinedConfig = signUpConfig({ email });
        setSignUpPredefindConfig(predefinedConfig);
        setisEmailLocked(true);
      }
    }, [decodeEmail]);

    function getCookie(name) {
      function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1"); }
      const match = document?.cookie?.match(RegExp(`(?:^|;\\s*)${ escape(name) }=([^;]*)`));
      return match ? match[1] : null;
    }

    const onClose = () => {
      setLoadingScreen(false);
      setRecaptchaCloseListener(false);
      refRecaptcha.current.reset();
    };

    const initListener = () => {
      HTMLCollection.prototype.find = Array.prototype.find;
      const recaptchaWindow = document
        .getElementsByTagName("iframe")
        .find(x => x.src?.includes("google.com/recaptcha/api2/bframe"))
        ?.parentNode.parentNode;

      if (recaptchaWindow) {
        new MutationObserver(() => recaptchaWindow.style.opacity === 0 && onClose())
          .observe(recaptchaWindow, { attributes: true, attributeFilter: ["style"] });

        setRecaptchaCloseListener(true);
      }
    };

    const recaptchaChangedHandler = () => {
      setLoadingScreen(true);
      refRecaptcha.current.execute();
      if (!recaptchaCloseListener) initListener();
    };

    const onSubmitHandler = recaptchaToken => {
      try {
        refRecaptcha.current.reset();
      } finally {
        setLoadingScreen(true);
        signUp({
          full_name: signUpInfo.fullName,
          email: signUpInfo.email.toLowerCase(),
          password: signUpInfo.password.value,
          recaptcha: recaptchaToken,
          referral: signUpInfo.referral,
          promo_code: code,
          hubspotutk: getCookie("hubspotutk")
        });
      }
    };

    const showHideFields = () => {
      setPasswordFields({
        bar: true,
        description: true
      });
    };

    const findPasswordInput = () => {
      const CLASS_NAME = ".input-element-nice-password";
      const ID = "#password";
      return document.querySelector(`${CLASS_NAME} ${ID}`);
    };

    const addAutoComplete = useCallback(() => {
      const inputElement = findPasswordInput();
      inputElement.setAttribute("autocomplete", "new-password");
    }, []);

    const onTabListener = e => {
      if (e.keyCode === 9) {
        showHideFields();
      }
    };

    const showPassword = () => {
      const ATTR_NAME = "type";

      const inputElement = findPasswordInput();
      const inputAttribute = inputElement.getAttribute(ATTR_NAME);
      const changedValue = inputAttribute === "password" ? "text" : "password";

      return inputElement.setAttribute(ATTR_NAME, changedValue);
    };

    useEffect(() => {
      clearErrors();

      return () => clearErrors();
    }, [clearErrors]);

    useEffect(() => {
      addPredefinedEmail();
      addAutoComplete();
    }, [addPredefinedEmail, addAutoComplete, clearErrors]);

    return (
      <WrappedComponent
        isEmailLocked={isEmailLocked}
        signUpConfig={signUpConfig}
        signUpPredefindConfig={signUpPredefindConfig}
        signUpFormSchema={signUpFormSchema}
        recaptchaChangedHandler={recaptchaChangedHandler}
        onTabListener={onTabListener}
        onSubmitHandler={onSubmitHandler}
        setSignUpInfo={setSignUpInfo}
        showHideFields={showHideFields}
        showPassword={showPassword}
        passwordFields={passwordFields}
        refRecaptcha={refRecaptcha}
        code={code}
        {...rest}
      />
    );
  };

  Wrapped.defaultProps = {
    match: undefined,
    location: undefined,
    confirmEmail: PropTypes.func,
    clearErrors: PropTypes.func,
    isLoading: undefined,
    error: undefined,
    setLoadingScreen: undefined
  };

  Wrapped.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        code: PropTypes.string
      })
    }),
    location: PropTypes.shape({
      search: PropTypes.string
    }),
    signUp: PropTypes.func.isRequired,
    setLoadingScreen: PropTypes.func,
    confirmEmail: PropTypes.func,
    clearErrors: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.string
  };

  return Wrapped;
};
