import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { SelectableGroup } from "react-selectable";
import moment from "moment";

import { formDatesRange, formCalendarData, formDataTime } from "mixins/helperCandidate";
import { DATE_FORMATS } from "configs/dates";
import { MIN_TIME, MAX_TIME, TOTAL_DAYS } from "configs/calendar";
import { Clock } from "mixins/svgIcons";

import { nanoid } from "nanoid";
import Row from "./Row";
import "./styles.scss";

const Calendar = ({
  t,
  userId,
  updateCalendarDates,
  chosenCalendarDates,
  isOverview,
  candidateName,
  candidateEmail,
  calendarTitle,
  utcMinutesDelta
}) => {
  const datesRange = useMemo(() => formDatesRange(TOTAL_DAYS, isOverview), [isOverview]);
  const timeData = useMemo(() => formDataTime(MIN_TIME, MAX_TIME, isOverview), [isOverview]);
  const rowsData = useMemo(() => formCalendarData(timeData, datesRange), [timeData, datesRange]);

  const handleSelection = newSelected => {
    if (updateCalendarDates) {
      updateCalendarDates({ userId, newDates: newSelected });
    }
  };

  return (
    <SelectableGroup
      onEndSelection={handleSelection}
      component="div"
      className="selectable-group"
      enabled={!isOverview}
    >
      <div className="calendar__container">
        <table className="calendar">
          <thead className={`calendar__head ${isOverview && "interview"}`}>
            <tr>
              <th className={isOverview ? "n-font-extra-small n-font-medium-weight" : ""}>
                {isOverview
                  ? `${t(`month.${moment(isOverview).format(DATE_FORMATS.MONTH)}`)} ${moment(isOverview).format(DATE_FORMATS.YEAR)}`
                  : <Clock />
                }
              </th>
              {datesRange.map(({ dateFormatted }, id) => (
                <th
                  key={`calendar-header-${String(id)}`}
                  className={isOverview
                    ? "n-font-extra-small n-font-medium-weight n-uppercase"
                    : "n-font-small n-font-light"}
                >
                  {!dateFormatted?.includes("Invalid date") ? t(`daysOfWeek.${dateFormatted}`) : null }
                </th>
              ))}
            </tr>
            {isOverview ? (
              <tr>
                <th className="days">
                  {null}
                </th>
                {datesRange.map(({ id, dateISO }) => (
                  <th key={id} className="n-font-extra-small n-font-regular days">
                    <span>
                      {moment(dateISO).format(DATE_FORMATS.DAY)}
                    </span>
                  </th>
                ))}
              </tr>
            ) : null}
          </thead>

          <tbody className={`calendar__body ${isOverview && "interview"}`}>
            {rowsData.map(rowData => (
              <Row
                data={rowData}
                key={`calendar-item-${nanoid()}`}
                handleSelection={handleSelection}
                chosenCalendarDates={chosenCalendarDates}
                isOverview={isOverview}
                candidateName={candidateName}
                candidateEmail={candidateEmail}
                calendarTitle={calendarTitle}
                utcMinutesDelta={utcMinutesDelta}
              />
            ))}
          </tbody>
        </table>
      </div>
    </SelectableGroup>
  );
};

Calendar.defaultProps = {
  utcMinutesDelta: undefined
};

Calendar.propTypes = {
  userId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  updateCalendarDates: PropTypes.func.isRequired,
  chosenCalendarDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOverview: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  utcMinutesDelta: PropTypes.bool
};

export default withTranslation()(Calendar);
