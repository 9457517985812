/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import PropTypes from "prop-types";
import React, { useRef, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { getCandidateJobInfoCompany } from "store/modules/сandidates/selectors";
import { connect } from "react-redux";

import Cross from "assets/images/icons/Cross.png";
import { Warning } from "mixins/svgIcons";
import RetakeOverlayDeviceSelect from "./RetakeOverlayDeviceSelect";

const RetakeOverlay = ({
  open,
  handleRetake,
  videoDeviceId,
  audioDeviceId,
  handleClose,
  audioDevices,
  videoDevices,
  onlyAudio,
  companyInfo,
  showAttempt,
  userId
}) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  const startVideoPreview = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDeviceId },
        audio: { deviceId: audioDeviceId } // Removed the 'exact' property
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      streamRef.current = stream;
    } catch (error) {
      console.error("Error starting video preview:", error);
    }
  }, [audioDeviceId, videoDeviceId]);

  const stopVideoPreview = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach(track => track.stop());
    }
  };

  useEffect(() => {
    if (open) {
      startVideoPreview();
    }
    return () => {
      stopVideoPreview();
    };
  }, [open, startVideoPreview, audioDeviceId, videoDeviceId]);

  return (
    <Modal
      dialogClassName="rekake-overlay"
      show={open}
      centered
      onHide={() => {
      //
    }}
    >
      <div
        style={{
          position: "relative"
        }}
      >
        {!showAttempt ? (
          <div
            className="retake-overlay__header"
            style={{
            marginTop: 20
          }}
          >
            {t("candidate.videoCreate.ready")}
            ?
          </div>
        ) : (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E83B3B0D",
              paddingTop: 20,
              paddingBottom: 10
            }}
          >
            <Warning width={27} height={24} />
            <div className="final-attempt__header">Final Attempt</div>
            <div className="final-attempt__subheader">
              This is your final opportunity to answer this question
            </div>
          </div>
        )}

        <div
          style={{
            marginTop: 16,
            padding: "0px 10px 20px 10px"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 20,
              border: "2px solid #9398a5",
              borderRadius: 5000,
              height: 25,
              width: 25,
              right: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            }}
            onClick={handleClose}
          >
            <img src={Cross} alt="no-audio" />
          </div>
          {!onlyAudio && (
            <div
              style={{
                height: 150,
                width: 251,
                position: "relative",
                margin: "auto"
              }}
            >
              <video ref={videoRef} autoPlay playsInline muted />
            </div>
          )}
          <RetakeOverlayDeviceSelect
            audioDevices={audioDevices}
            onlyAudio={onlyAudio}
            videoDevices={videoDevices}
            userId={userId}
          />
          <button
            onClick={handleRetake}
            className="retake-overlay__rerecord"
            disabled={typeof EBML === "undefined"}
          >
            {t("button.recordStartJob")}
          </button>
          <button
            onClick={handleClose}
            className="retake-overlay__back"
            style={{
              border: `1px solid ${companyInfo?.button_color}`,
              color: companyInfo?.button_color
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

RetakeOverlay.propTypes = {
  audioDeviceId: PropTypes.string.isRequired,
  handleRetake: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  videoDeviceId: PropTypes.string.isRequired,
  audioDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  showAttempt: PropTypes.bool.isRequired,
  companyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }).isRequired,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  companyInfo: getCandidateJobInfoCompany(state)
});

export default connect(mapStateToProps)(RetakeOverlay);
