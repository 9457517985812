/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isMobileSafari, isMobile, isIOS, isChrome } from "react-device-detect";

import VideoPlayer from "components/Video/Player";
import AnswerHeader from "components/Common/AnswerHeader";
import { Reload } from "mixins/svgIcons";
import srcPoster from "assets/images/video/poster.jpg";
import { ANSWER_STATUS } from "configs/jobs/constants";
import { BeatLoader } from "react-spinners";

import "./styles.scss";
import { isNull } from "lodash";
import { isCandidateRoute } from "mixins/helperCandidate";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";

const Item = ({
  t,
  history,
  jobId = "",
  userId = "",
  question = {},
  questionText = "",
  questionNumber = "",
  withButton = true,
  withDownload,
  withError,
  mediaExtension,
  size = "",
  showRetake,
  questionTextProps,
  sources,
  downloadSource,
  memorizedCompanyInfo,
  onReady,
  onlyAudio,
  isCandidate,
  settings,
  setSettings,
  currentTime,
  setCurrentTime,
  autoplay,
  thinkingTime,
  spentTime,
  handleNextQuestion,
  currentQuestion,
  status,
  videoErrors = [],
  handleGoToScorecard = () => {},
  setVideoErrors = () => {},
  showScorecardButton = false,
  updateFirebaseData = () => {},
  isFirebaseInitialized = false
}) => {
  const returnCreateQuestion = useCallback(() => {
    history.push({
      pathname: `/candidate/video-questions/create/${userId}/${jobId}/${question.key}`
    });
  }, []);

  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());

  const videoJsOptions = {
    autoplay,
    playbackRates: isCandidateRoute ? [0.5, 1, 1.5, 2] : [0.8, 1, 1.2, 1.5, 1.75, 2],
    fluid: !isCandidate,
    controls: true,
    controlsList:
      withDownload && status === ANSWER_STATUS.processed && !isIOS
        ? ""
        : "nodownload",
    // eslint-disable-next-line no-nested-ternary
    poster: currentQuestion?.thumbnail_remote_link?.length
      ? currentQuestion?.thumbnail_remote_link
      : (isMobileSafari || (isChrome && isIOS))
        ? srcPoster : undefined,
    className: `video__preview-item-player ${withDownload ? "small" : ""}`,
    sources,
    withDownload: withDownload && status === ANSWER_STATUS.processed && !isIOS,
    questionNumber,
    questionKey: question.key,
    playsinline: true,
    inactivityTimeout: 0,
    onReady,
    withError,
    settings,
    setSettings: isMobile ? null : setSettings,
    currentTime,
    setCurrentTime,
    mediaExtension,
    withNext: t("candidate.common.nextAnswer"),
    cancelButton: t("button.cancel"),
    handleNextQuestion,
    ...(onlyAudio
      ? null
      : {
          crossOrigin: "use-credentials"
        })
  };

  const isProcessing = [ANSWER_STATUS.processing].includes(status);
  const isFailed = [ANSWER_STATUS.failed].includes(status);
  const isUploadInterrupted = isNull(mediaExtension);
  const allowedExtensionsOnIOS = ["mp4", "m4v", "mov", "wav", "mp3"].includes(
    mediaExtension?.toLowerCase()
  );

  let shouldShowProcessing = false;

  if (isIOS) {
    shouldShowProcessing = ["wav", "mov"].includes(
      mediaExtension?.toLowerCase()
    )
      ? false
      : !allowedExtensionsOnIOS || isProcessing;
  }

  if (isFailed) {
    shouldShowProcessing = false;
  }

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={size}
        questionTextProps={questionTextProps}
        questionText={questionText}
        currentQuestion={currentQuestion}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading}
      >
        {shouldShowProcessing || isUploadInterrupted ? (
          <div className="processing-wrapper">
            <span className="processing-wrapper__text">
              {
                isUploadInterrupted ? t("errors.interruptedVideoUpload.0") : "Media still processing."
              }
              <br />
              {
                isUploadInterrupted ? t("errors.interruptedVideoUpload.1") : "Please check back soon"
              }
            </span>
            { !isUploadInterrupted && <BeatLoader color="#4a4a4a" /> }
          </div>
        ) : (
          // eslint-disable-next-line max-len
          <VideoPlayer
            handleGoToScorecard={handleGoToScorecard}
            downloadSource={downloadSource}
            {...videoJsOptions}
            currentQuestion={currentQuestion}
            onlyAudio={onlyAudio}
            isCandidate={isCandidate}
            videoErrors={videoErrors}
            setVideoErrors={setVideoErrors}
            showScorecardButton={showScorecardButton}
            updateFirebaseData={updateFirebaseData}
            isFirebaseInitialized={isFirebaseInitialized}
          />
        )}
      </SkeletonWrapper>

      {showRetake && withButton && (
        <div className="video__preview-button">
          <button
            type="button"
            className="
              n-button__large-border
              n-border-purple-100
              n-purple-100
            "
            onClick={returnCreateQuestion}
            style={
              memorizedCompanyInfo && {
                borderColor: memorizedCompanyInfo.button_color,
                color: memorizedCompanyInfo.button_color
              }
            }
            data-test-id="Preview-Btn-Retake_Btn"
            aria-label="Retake answer"
          >
            <Reload
              svgIconClass="n-icon__large right"
              fill={memorizedCompanyInfo?.button_color}
            />
            {t("button.reTake")}
          </button>
        </div>
      )}
    </div>
  );
};

Item.defaultProps = {
  history: undefined,
  jobId: undefined,
  userId: undefined,
  question: undefined,
  questionText: undefined,
  questionNumber: undefined,
  withButton: true,
  withDownload: true,
  size: undefined,
  showRetake: undefined,
  questionTextProps: undefined,
  memorizedCompanyInfo: undefined,
  withError: "",
  mediaExtension: "",
  isCandidate: false,
  settings: {},
  setSettings: null,
  currentTime: 0,
  setCurrentTime: null,
  autoplay: false,
  thinkingTime: 0,
  spentTime: 0,
  handleNextQuestion: null,
  status: undefined,
  sources: undefined,
  videoErrors: undefined,
  setVideoErrors: undefined,
  downloadSource: undefined,
  showScorecardButton: undefined,
  handleGoToScorecard: undefined,
  onReady: undefined,
  updateFirebaseData: undefined,
  isFirebaseInitialized: false
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  jobId: PropTypes.string,
  userId: PropTypes.string,
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string
  }),
  questionText: PropTypes.string,
  questionNumber: PropTypes.number,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  withButton: PropTypes.bool,
  withDownload: PropTypes.bool,
  size: PropTypes.string,
  showRetake: PropTypes.bool,
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  onReady: PropTypes.func,
  withError: PropTypes.bool,
  mediaExtension: PropTypes.string,
  onlyAudio: PropTypes.bool.isRequired,
  isCandidate: PropTypes.bool,
  settings: PropTypes.number,
  setSettings: PropTypes.func,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  autoplay: PropTypes.bool,
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  handleNextQuestion: PropTypes.func,
  status: PropTypes.string,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    }),
    thumbnail_remote_link: PropTypes.string
  }).isRequired,
  videoErrors: PropTypes.arrayOf(PropTypes.shape({})),
  setVideoErrors: PropTypes.func,
  showScorecardButton: PropTypes.bool,
  handleGoToScorecard: PropTypes.func,
  downloadSource: PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  updateFirebaseData: PropTypes.func,
  isFirebaseInitialized: PropTypes.bool
};

export default withTranslation()(Item);
