import PropTypes from "prop-types";
import React from "react";

const ShortLink = ({
  location: { pathname = "" }
}) => (
  <meta httpEquiv="Refresh" content={`0; ${process.env.REACT_APP_BACKEND_URL}${pathname}`} />
  );

ShortLink.defaultProps = {
  location: undefined
};

ShortLink.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default ShortLink;
