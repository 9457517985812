/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

export const Accept = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 6L9 17L4 12" stroke="#59CC4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Reject = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Share = ({ fill = "#9095A3", height = "24px", width = "24px" }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.58984 13.5098L15.4198 17.4898" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4098 6.50977L8.58984 10.4898" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ShareLink = ({ fill = "#5A2AF1", height = "16px", width = "17px" }) => (
  <>
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1717 0.771668C13.0708 0.666389 12.9194 0.635051 12.7879 0.692384C12.6564 0.749717 12.5707 0.884457 12.5707 1.03378V3.0065C10.6671 3.08593 9.71393 3.91372 9.56828 4.05135C8.60791 4.87812 7.9522 6.03027 7.71403 7.30922C7.59495 7.92717 7.57361 8.56144 7.65112 9.18662L7.65139 9.18878C7.65208 9.19427 7.65291 9.19991 7.65373 9.20539L7.72862 9.65698C7.75382 9.80905 7.86657 9.92877 8.01126 9.95708C8.15608 9.98538 8.30228 9.91621 8.378 9.78378L8.6024 9.3914C9.29184 8.18885 10.151 7.39168 11.1564 7.02212C11.6099 6.85387 12.0903 6.78008 12.5707 6.80463V8.84782C12.5707 8.99743 12.6567 9.13246 12.7886 9.18951C12.9206 9.2467 13.0723 9.21464 13.173 9.10849L16.8973 5.18183C17.0345 5.03713 17.034 4.80303 16.896 4.65905L13.1717 0.771668Z" fill={fill} />
      <path d="M15.7087 11.5524C15.5142 11.5524 15.3563 11.7179 15.3563 11.9221V13.4845C15.3557 14.0969 14.8827 14.5929 14.299 14.5937H2.76215C2.17844 14.5929 1.70555 14.0969 1.70486 13.4845V4.67664C1.70555 4.06432 2.17844 3.56825 2.76215 3.56753H5.19955C5.39421 3.56753 5.55198 3.40203 5.55198 3.19783C5.55198 2.99363 5.39421 2.82812 5.19955 2.82812H2.76215C1.78939 2.82928 1.0011 3.6562 1 4.67664V13.4845C1.0011 14.505 1.78939 15.3319 2.76215 15.3331H14.299C15.2717 15.3319 16.06 14.505 16.0611 13.4845V11.9221C16.0611 11.7179 15.9033 11.5524 15.7087 11.5524Z" fill={fill} stroke={fill} />
    </svg>
  </>
);

export const Remind = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.6 2H14.4C15.2837 2 16 2.67155 16 3.5V12.5C16 13.3284 15.2837 14 14.4 14H1.6C0.716344 14 0 13.3284 0 12.5V3.5C0 2.67155 0.716344 2 1.6 2ZM8.0001 7.24988L14.4001 3.49986H1.6001L8.0001 7.24988ZM1.6001 12.5001H14.4001V5.27758L8.0001 9.01905L1.6001 5.27758V12.5001Z" fill="#9095A3" />
  </svg>
);

export const Delete = () => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.50192 0.82424H11.9915C12.2724 0.82424 12.5 1.05048 12.5 1.32956V2.91694H0.5V1.32952C0.5 1.05044 0.727623 0.824201 1.00846 0.824201H4.49812V0.168624C4.49812 0.0755184 4.57407 0 4.66776 0H8.33223C8.42596 0 8.50192 0.0755184 8.50192 0.168624V0.82424ZM1.90867 15.2285C1.92685 15.6597 2.28395 16.0001 2.71822 16.0001H10.2824C10.7167 16.0001 11.0738 15.6597 11.092 15.2285L11.6322 3.89616H1.36849L1.90867 15.2285ZM8.21119 6.71081C8.21119 6.53024 8.35853 6.38379 8.54031 6.38379H9.0667C9.24836 6.38379 9.39582 6.5302 9.39582 6.71081V13.1852C9.39582 13.3658 9.24848 13.5123 9.0667 13.5123H8.54031C8.35861 13.5123 8.21119 13.3659 8.21119 13.1852V6.71081ZM6.23684 6.38379C6.05509 6.38379 5.90776 6.53024 5.90776 6.71081V13.1852C5.90776 13.3659 6.05513 13.5123 6.23684 13.5123H6.76323C6.94501 13.5123 7.09231 13.3658 7.09231 13.1852V6.71081C7.09231 6.5302 6.94489 6.38379 6.76323 6.38379H6.23684ZM3.60465 6.71081C3.60465 6.53024 3.75199 6.38379 3.93373 6.38379H4.46016C4.64187 6.38379 4.78924 6.5302 4.78924 6.71081V13.1852C4.78924 13.3658 4.64191 13.5123 4.46016 13.5123H3.93373C3.75203 13.5123 3.60465 13.3659 3.60465 13.1852V6.71081Z" fill="white" />
  </svg>
);

export const Clock = ({ fill = "#9095A3" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Clock" fillRule="evenodd" clipRule="evenodd" d="M0 8.99985C0 4.02955 4.02943 0 8.99985 0C13.9703 0 18 4.02955 18 8.99985C18 13.9702 13.9703 18 8.99985 18C4.02943 18 0 13.9702 0 8.99985ZM9.07477 10.712H12.8904C13.2341 10.712 13.5126 10.4333 13.5126 10.0896C13.5126 9.74585 13.2339 9.46714 12.8901 9.46714H9.62186V3.73543C9.62186 3.39168 9.34315 3.11298 8.9994 3.11298C8.65566 3.11298 8.37695 3.39168 8.37695 3.73543V10.0896C8.37695 10.4333 8.65566 10.712 8.9994 10.712C9.0066 10.712 9.01348 10.7111 9.02037 10.7102C9.02588 10.7095 9.0314 10.7087 9.03708 10.7084C9.04295 10.7087 9.04864 10.7094 9.05434 10.7102C9.06106 10.7111 9.06778 10.712 9.07477 10.712Z" fill={fill} />
  </svg>
);

export const Location = ({ fill = "#9095A3" }) => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 6.51889C0 2.92437 3.14018 0 7 0C10.8598 0 13.9999 2.92437 14 6.51889C14 10.9798 7.73569 17.5287 7.46898 17.8053C7.21892 18.0647 6.78154 18.0651 6.53102 17.8053C6.26431 17.5287 0 10.9798 0 6.51889ZM3.47935 6.52004C3.47935 8.32855 5.05922 9.79987 7.00119 9.79987C8.94312 9.79987 10.523 8.32858 10.523 6.52007C10.523 4.71156 8.94312 3.24023 7.00119 3.24023C5.05926 3.24023 3.47935 4.71153 3.47935 6.52004Z" fill={fill} />
  </svg>
);

export const Mail = ({ fill = "#9095A3" }) => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.9999 2.46905V12.2726C17.9999 13.6245 16.8972 14.7272 15.5453 14.7272H2.45453C1.10267 14.7272 0 13.6245 0 12.2726V2.45453C0 1.10267 1.10267 0 2.45453 0H15.5453C16.8907 0 17.9892 1.09208 17.9998 2.43503C18 2.44638 18.0001 2.45772 17.9999 2.46905ZM1.72354 2.08959C1.85885 1.82202 2.1371 1.63635 2.45453 1.63635H15.5453C15.8628 1.63635 16.1411 1.82205 16.2764 2.08967L9 7.18311L1.72354 2.08959ZM1.63635 4.02598V12.2726C1.63635 12.7208 2.0064 13.0908 2.45453 13.0908H15.5453C15.9935 13.0908 16.3635 12.7208 16.3635 12.2726V4.02608L9.46919 8.8521C9.18748 9.0493 8.81252 9.0493 8.53081 8.8521L1.63635 4.02598Z" fill={fill} />
  </svg>
);

export const Phone = ({ fill = "#9095A3" }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.71516 0.699219C3.01163 0.699219 2.30906 0.965533 1.7766 1.49793L1.12587 2.14859C0.20584 3.06851 -0.170688 4.39326 0.0718398 5.67851C0.598071 8.46167 2.18863 11.4321 4.72828 13.9715C7.26793 16.5108 10.2387 18.1012 13.0222 18.6274C14.3076 18.8699 15.6325 18.4934 16.5525 17.5735L17.2013 16.9248C18.2662 15.86 18.2662 14.1109 17.2013 13.0461L16.0323 11.8753C15.1619 11.005 13.7263 11.0043 12.8566 11.8753L11.9565 12.7773C11.948 12.7858 11.948 12.7799 11.9643 12.7871C10.726 12.2402 9.49384 11.4117 8.39109 10.3091C7.28849 9.20662 6.46077 7.97633 5.9148 6.73826V6.73632C5.92197 6.75256 5.91611 6.75254 5.92454 6.74411L6.74088 5.92786C7.65962 5.00923 7.69673 3.53875 6.8266 2.66873L5.65567 1.49793C5.12322 0.965533 4.4187 0.699219 3.71516 0.699219ZM3.71516 2.1856C4.0326 2.1856 4.35113 2.30915 4.59775 2.55574L5.76868 3.72653C6.06765 4.02548 6.10289 4.45016 5.68295 4.87006L4.86661 5.68435V5.6863C4.44212 6.11075 4.29058 6.76537 4.54514 7.34217C5.16828 8.75475 6.10195 10.1358 7.33316 11.3669C8.56463 12.5982 9.9452 13.5324 11.3584 14.1565C11.9352 14.4111 12.5899 14.2596 13.0144 13.8351H13.0164L13.9165 12.9332C14.214 12.6352 14.6758 12.6345 14.9745 12.9332L16.1434 14.1039C16.6367 14.5971 16.6367 15.3738 16.1434 15.867L15.4946 16.5157C14.9363 17.0739 14.1283 17.3131 13.2989 17.1566C10.8693 16.6973 8.13246 15.2597 5.78621 12.9137C3.43996 10.5677 2.00214 7.83121 1.54281 5.40188C1.38632 4.57261 1.62549 3.76464 2.1838 3.2064L2.83453 2.55574C3.08114 2.30915 3.39773 2.1856 3.71516 2.1856Z" fill={fill} />
  </svg>
);

export const Down = () => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.88862 6.16822L10.339 1.58483C10.5537 1.36378 10.5537 1.0054 10.339 0.78437L9.81995 0.249792C9.60568 0.0291167 9.25841 0.028692 9.04363 0.248848L5.49999 3.88125L1.95637 0.248825C1.74159 0.0286687 1.39432 0.0290928 1.18005 0.249768L0.660979 0.784347C0.44634 1.0054 0.44634 1.36378 0.660979 1.58481L5.11138 6.16819C5.326 6.38924 5.67398 6.38924 5.88862 6.16822Z" fill="white" />
  </svg>
);

export const Moon = ({ svgIconClass }) => (
  <svg className={svgIconClass} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3097:7492)">
      <path d="M8.72314 16C11.1843 16 13.4413 14.8811 14.937 13.0383C15.8205 11.9498 14.8571 10.3595 13.4917 10.6195C10.9184 11.1096 8.54574 9.14237 8.54574 6.53225C8.54574 5.019 9.36011 3.64812 10.6531 2.91237C11.8638 2.22331 11.5593 0.38775 10.1836 0.133656C9.70186 0.0448171 9.21302 7.98848e-05 8.72314 0C4.30564 0 0.723145 3.58047 0.723145 8C0.723145 12.4175 4.30364 16 8.72314 16ZM8.72314 1.5C9.12893 1.5 9.52593 1.53753 9.91114 1.60869C8.19989 2.58253 7.04574 4.4225 7.04574 6.53225C7.04574 10.09 10.2845 12.7573 13.7724 12.093C12.5806 13.5614 10.7616 14.5 8.72314 14.5C5.1333 14.5 2.22314 11.5898 2.22314 8C2.22314 4.41016 5.1333 1.5 8.72314 1.5Z" fill="#768493" />
    </g>
    <defs>
      <clipPath id="clip0_3097:7492">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Moon.propTypes = {
  svgIconClass: PropTypes.string.isRequired
};
