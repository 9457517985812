import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { withTranslation } from "react-i18next";

import animationData from "assets/images/candidate/error.json";
import AnswerHeader from "components/Common/AnswerHeader";

import "./styles.scss";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { store } from "store";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const ContentExpired = ({
  t,
  questionText,
  questionNumber,
  size,
  textSize,
  questionTextProps,
  thinkingTime,
  currentQuestion,
  spentTime,
  noHeader = false
}) => {
  const isJobCandidateLoading = getIsJobCandidateLoading(store.getState());

  return (
    <div className="video__preview-item">
      {!noHeader && (
        <AnswerHeader
          currentQuestion={currentQuestion}
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={questionText}
        />
      )}

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading}
      >
        <div className={`video__expired ${size}`}>
          <div className="video__expired-image">
            <p className={`n-font-giant n-font-semi-bold n-purple-100 ${size}`}>
              {t("job.candidate.contentExpired")}
            </p>
            <Lottie
              options={defaultOptions}
              height={size === "big" ? 320 : 200}
              width={size === "big" ? 450 : 200}
            />
          </div>
          <p className={`n-font-extra-small n-black-100 ${size}`}>
            {t("job.candidate.followingAccount")}
          </p>
        </div>
      </SkeletonWrapper>
    </div>
  );
};

ContentExpired.defaultProps = {
  questionText: "",
  questionNumber: "",
  size: "",
  textSize: "",
  thinkingTime: 0,
  spentTime: 0,
  noHeader: false
};

ContentExpired.propTypes = {
  t: PropTypes.func.isRequired,
  questionText: PropTypes.string,
  questionNumber: PropTypes.number,
  questionTextProps: PropTypes.shape({}).isRequired,
  size: PropTypes.string,
  textSize: PropTypes.string,
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired,
  noHeader: PropTypes.bool
};

export default withTranslation()(ContentExpired);
