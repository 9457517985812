/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { select, call, put, takeEvery } from "redux-saga/effects";

import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";

import {
  getIdvBundles,
  getIdvBundlesError,
  getIdvBundlesLoading,
  getIdvCheckoutLink,
  getIdvCheckoutLinkError,
  getClearPaymentStatus,
  getIdvPaymentStatus,
  getIdvPaymentStatusError,
  getIdvPaymentStatusLoading,
  isIdvCheckoutLinkLoading,
  setIdvBundles,
  setIdvPaymentStatus,
  setAgencyIdvPurchased,
  getAgencyIdvPurchasedError,
  getAgencyIdvPurchased,
  getAgencyIdvPurchasedLoading,
  getAgencyIdvUsageLoading,
  setAgencyIdvUsage,
  getAgencyIdvUsageError,
  getAgencyIdvUsage,
  getIdvPaygPriceLoading,
  setIdvPaygPrice,
  getIdvPaygPriceError,
  getIdvPaygPrice,
  setIsPricesDataFetched,
  fetchIdvPrices
} from "store/modules/idv/actions";
import { logErrors } from "mixins/helpers";
import { getRequestWithToken, postRequestWithToken } from "./api";


function* getIdvBundlesWorker() {
  try {
    yield put(getIdvBundlesLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield call(getRequestWithToken, {
      url: `/api/stripe/agency/${agencyKey}/idv-bundles/prices/`,
      token
    });

    if (response.status === 200) {
      yield put(setIdvBundles(response.data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getIdvBundlesError(error?.message));
  } finally {
    yield put(getIdvBundlesLoading(false));
  }
}

export function* getIdvPaygPriceWorker({ payload: { cancelToken = null } }) {
  try {
    yield put(getIdvPaygPriceLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield call(getRequestWithToken, {
      url: `/api/stripe/agency/${agencyKey}/payg/price/`,
      token,
      cancelToken
    });

    if (response.status === 200) {
      yield put(setIdvPaygPrice(response.data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getIdvPaygPriceError(error?.message));
  } finally {
    yield put(getIdvPaygPriceLoading(false));
  }
}

export function* getIdvPricesWorker({ payload }) {
  try {
    yield put(getIdvBundlesLoading(true));

    yield call(getIdvBundlesWorker);
    yield call(getIdvPaygPriceWorker, { payload });
  } catch (error) {
    logErrors(error);
    yield put(getIdvBundlesError(error?.message));
  } finally {
    yield put(getIdvBundlesLoading(false));
    yield put(setIsPricesDataFetched(true));
  }
}

function* getAgencyIdvPurchasedWorker() {
  try {
    yield put(getAgencyIdvPurchasedLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield call(getRequestWithToken, {
      url: `/api/idv/agency/${agencyKey}/bundles/`,
      token
    });

    if (response.status === 200) {
      yield put(setAgencyIdvPurchased(response.data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getAgencyIdvPurchasedError(error?.message));
  } finally {
    yield put(getAgencyIdvPurchasedLoading(false));
  }
}

export function* getAgencyIdvUsageWorker() {
  try {
    yield put(getAgencyIdvUsageLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield call(getRequestWithToken, {
      url: `/api/idv/agency/${agencyKey}/stats/`,
      token
    });

    if (response.status === 200) {
      yield put(setAgencyIdvUsage(response.data));
    }
  } catch (error) {
    logErrors(error);
    yield put(getAgencyIdvUsageError(error.message));
  } finally {
    yield put(getAgencyIdvUsageLoading(false));
  }
}

export function* getCheckoutLinkForIdvWorker({ payload: {
  bundleKey = null,
  isActivated = false
} = {} }) {
  try {
    yield put(getClearPaymentStatus());
    yield put(isIdvCheckoutLinkLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const url = isActivated ? "/api/idv/buy-bundle/" : "/api/idv/enable/";

    const {
      data: { url: checkoutLink }
    } = yield postRequestWithToken({
      url,
      token,
      payload: {
        agency: agencyKey,
        bundle: bundleKey
      }
    });

    window.location.href = checkoutLink;
  } catch (error) {
    logErrors(error);
    yield put(getIdvCheckoutLinkError(error?.message));
  } finally {
    yield put(isIdvCheckoutLinkLoading(false));
  }
}

function* getIdvPaymentStatusWorker({ payload: {
  id,
  type
} = {} }) {
  try {
    yield put(getIdvPaymentStatusLoading(true));
    const token = yield select(getToken);

    const url = type === "checkout" ? `/api/idv/session/${id}/` : `/api/idv/payment-intent/${id}/`;

    const response = yield call(getRequestWithToken, {
      url,
      token
    });

    if (response.status === 200) {
      yield put(setIdvPaymentStatus(response.data));

      if ((response.data.payment_intent_type === "purchase_bundle" && response.data.status === "succeeded") ||
      (response.data.payment_intent_type === "pre_authorisation" && response.data.status === "requires_capture")) {
        window.location.href = "/connect";
      }
    }
  } catch (error) {
    logErrors(error);

    yield put(getIdvPaymentStatusError(error?.message));
  } finally {
    yield put(getIdvPaymentStatusLoading(false));
  }
}

export function* idvWorker() {
  yield takeEvery(getIdvBundles, getIdvBundlesWorker);
  yield takeEvery(getIdvCheckoutLink, getCheckoutLinkForIdvWorker);
  yield takeEvery(getIdvPaymentStatus, getIdvPaymentStatusWorker);
  yield takeEvery(getAgencyIdvPurchased, getAgencyIdvPurchasedWorker);
  yield takeEvery(getAgencyIdvUsage, getAgencyIdvUsageWorker);
  yield takeEvery(getIdvPaygPrice, getIdvPaygPriceWorker);
  yield takeEvery(fetchIdvPrices, getIdvPricesWorker);
}

export default idvWorker;
