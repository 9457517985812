import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import { ArrowRight, ArrowLeft } from "./svgIcons";

const ArrowButtons = ({
  currentIndex,
  nextIndex,
  items,
  setSelected,
  isShowCase = false
}) => {
  const prevIndex = Math.max(currentIndex - 1, 0);

  const handleKeyDown = debounce(e => {
    if (!items.length) return;
    if (e.keyCode === 37) {
      setSelected(items[prevIndex]?.key);
    } else if (e.keyCode === 39) {
      setSelected(items[nextIndex]?.key);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <>
      <button
        type="button"
        className="button__without-styles arrow-button left"
        style={isShowCase
          ? {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 30,
            zIndex: 4
          } : {}
        }
        onClick={() => setSelected(items[prevIndex].key)}
        disabled={[0, -1].includes(currentIndex)}
        title={currentIndex === 0 ? null : "prev"}
      >
        <ArrowLeft />
      </button>
      <button
        type="button"
        className="button__without-styles arrow-button right"
        style={isShowCase
          ? {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 30,
            zIndex: 1
          } : {}
        }
        onClick={() => setSelected(items[nextIndex].key)}
        disabled={[-1, items.length - 1].includes(currentIndex)}
        title={currentIndex === items.length - 1 ? null : "next"}
      >
        <ArrowRight />
      </button>
    </>
  );
};

ArrowButtons.defaultProps = {
  items: [],
  setSelected: null,
  isShowCase: false
};

ArrowButtons.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nextIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  setSelected: PropTypes.func,
  isShowCase: PropTypes.bool
};

export default ArrowButtons;
