/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import AudioWave from "assets/images/icons/audio-wave.gif";

const AudioAnimation = ({ showAnimation }) => {
  if (process.env.REACT_APP_DETECT_MUTE === "false") return null;
  return (
    <div className="audio-animation">
      <div className="audio-animation__container">
        <img
          src={AudioWave}
          style={{
            visibility: showAnimation ? "visible" : "hidden"
          }}
        />
        <div style={{
          visibility: showAnimation ? "hidden" : "visible"
        }}
        >
          ......

        </div>
      </div>
    </div>
  );
};

export default AudioAnimation;
