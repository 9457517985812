/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import { store } from "store";
import { getCurrentUser } from "store/modules/users/selectors";
import { components } from "react-select";
import Select from "components/Forms/Select";
import {
  COUNTRIES,
  DBS_RTW_TYPE,
  DBS_TYPE,
  IDV_TYPES,
  IDV_TYPE_LABELS,
  RTW_COUNTRY_OPTIONS,
  RTW_TYPE
} from "configs/jobs/constants";
import TooltipInfo from "components/Common/Tooltip/Info";
import { FileInfo } from "mixins/svgIcons";
import Input from "components/Common/Input";
import PropTypes from "prop-types";
import countries from "i18n-iso-countries";
import { Controller, useFormContext } from "react-hook-form";
import { FaGlobe } from "react-icons/fa";

const IdvCountryAndChecks = ({
  t,
  isEditable,
  isIdvLocked,
  control,
  order,
  idvType,
  setValue
}) => {
  const { role } = getCurrentUser(store.getState());
  const isAdmin = role === ROLE_ADMIN;
  const getCountryLabel = useCallback(({ country }) => country, []);
  const getTypeLabel = useCallback(({ title }) => t(title), [t]);

  const { formState: { errors } = {}, setError } = useFormContext();

  const SingleValueCountry = props => (
    <components.SingleValue {...props}>
      <i className="fa xsm mr4 ml4 grey fa-globe">
        <FaGlobe />
      </i>
      {props.data.country}
    </components.SingleValue>
  );
  const SingleValueType = props => (
    <components.SingleValue {...props}>
      <i className="fa xsm mr4 ml4 grey fa-globe">
        <FaGlobe />
      </i>
      {props?.data?.title ? t(props.data.title) : ""}
    </components.SingleValue>
  );

  const CustomOption = props => (
    <div
      {...props.innerProps}
      ref={props.innerRef}
      role="presentation"
      {...props}
      style={{
        backgroundColor:
          props?.isFocused || props?.isfocused ? "#F4F4F5" : "inherit",
        borderRadius: 10,
        padding: 10
      }}
      onClick={() => {
        if (props.data.isDisabled && isAdmin) {
          window.open(
            "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_response_length",
            "_blank"
          );
        }
      }}
    >
      <components.Option {...props} />
    </div>
  );

  return (
    <>
      <Controller
        control={control}
        name={`questions[${order - 1}].idv_type`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t("job.questions.idv.checkType.title")}
            placeholder={t("job.questions.idv.checkType.placeholder")}
            value={IDV_TYPES?.find(a => a.value === value)}
            options={IDV_TYPES}
            changed={val => {
              const text = IDV_TYPE_LABELS[val.value] ?? IDV_TYPE_LABELS.idv;
              setValue(`questions[${order - 1}].html_text`, `<p>${text}</p>`);
              setValue(`questions[${order - 1}].text`, text);
              onChange(val.value);
              setValue(
                `questions[${order - 1}].idv_country_of_employment_iso_code`,
                null
              );
            }}
            getOptionLabel={getTypeLabel}
            components={{
              SingleValue: SingleValueType,
              Option: CustomOption
            }}
            isSearchable={false}
            InfoElement={
              <TooltipInfo
                position="top"
                message={
                  <>{t("job.questions.idv.tooltip.countryOfEmployment")}</>
                }
                trigger={["hover"]}
                element={<FileInfo width="13" height="13" fill="#5A2AF1" />}
                close
              />
            }
            disabled={!isEditable || isIdvLocked}
            tooltipMessage={t("job.questions.disabledTooltip")}
            isShowTooltip={!isEditable}
            onMenuClose={() => {
              if (!value || value.trim() === "") {
                setError(`questions[${order - 1}].idv_type`, {
                  type: "required",
                  message: t("errors.required")
                });
              }
            }}
            error={errors?.questions?.[order - 1]?.idv_type?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={`questions[${order - 1}].idv_country_of_employment_iso_code`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          const country2Code = countries.alpha3ToAlpha2(value, "en") || value;

          return (
            <Select
              label={t("job.questions.idv.title")}
              placeholder={t("job.candidate.citizenship.placeholder")}
              value={
                COUNTRIES.find(option => option.iso === country2Code) || null
              }
              options={
                [RTW_TYPE, DBS_TYPE, DBS_RTW_TYPE].includes(idvType)
                  ? RTW_COUNTRY_OPTIONS
                  : COUNTRIES
              }
              changed={val => {
                onChange(val.iso);
              }}
              getOptionLabel={getCountryLabel}
              components={{
                SingleValue: SingleValueCountry,
                Option: CustomOption
              }}
              isSearchable
              disabled={!isEditable || isIdvLocked}
              tooltipMessage={t("job.questions.disabledTooltip")}
              isShowTooltip={!isEditable}
              onMenuClose={() => {
                if (!value || value.trim() === "") {
                  setError(`questions[${order - 1}].idv_country_of_employment_iso_code`, {
                    type: "required",
                    message: t("errors.required")
                  });
                }
              }}
              error={errors?.questions?.[order - 1]?.idv_country_of_employment_iso_code?.message}
            />
          );
        }}
      />

      <Controller
        control={control}
        name={`questions[${order - 1}].idv_max_checks`}
        render={({ field: { onChange, value } }) => (
          <Input
            labelClassName="n-font-extra-small n-black-100"
            name="idvMaxChecks"
            formElement={{
              value: value || "",
              icon: "Check",
              label: "idvMaxChecks",
              elementConfig: {
                placeholder: "enterCredits",
                type: "text"
              },
              InfoElement: (
                <TooltipInfo
                  position="top"
                  message={<>{t("job.questions.idv.tooltip.maxChecks")}</>}
                  trigger={["hover"]}
                  element={<FileInfo width="13" height="13" fill="#5A2AF1" />}
                  close
                />
              ),
              inputClass: "maxChecks-label"
            }}
            inputChangedHandler={e => {
              const numberValue = e.target.value.replace(/\D/g, "");
              onChange(numberValue);
            }}
            disabled={isIdvLocked}
          />
        )}
      />
    </>
  );
};

IdvCountryAndChecks.defaultProps = {
  t: undefined,
  isEditable: undefined,
  isIdvLocked: undefined
};

IdvCountryAndChecks.propTypes = {
  t: PropTypes.func,
  isEditable: PropTypes.bool,
  isIdvLocked: PropTypes.bool
};

export default withTranslation()(IdvCountryAndChecks);
