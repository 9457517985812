/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { withTranslation } from "react-i18next";
import RecordRTC, { getSeekableBlob } from "recordrtc";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import getBlobDuration from "get-blob-duration";
import { isMobile, isSafari } from "react-device-detect";

import Message from "components/Common/Message";
import DesktopPlayer from "components/Video/Recording/Desktop/JobPlayer";
import Buttons from "components/Video/Recording/JobButtons";
import {
  captureUserMedia,
  handleRecordVideoHTMLplayer,
  getVideoDeviceIds,
  formTimeString
} from "mixins/helperVideoRecording";
import Timer from "utils/timer";
import useInterval from "hooks/useInterval";

import Countdown from "assets/audio/countdown.wav";

import * as Sentry from "@sentry/browser";
import { retry } from "mixins/helpers";
import ErrorModal from "components/Common/Error/ErrorModal";
import { MAX_UPLOAD_VIDEO_SIZE } from "../../../../../constants";

const maxDuration = 180;
const headerHeight = 60;

const VideoRecording = ({
  t,
  videoBlob,
  videoLink,
  changeVideoBlob,
  videoError,
  clearErrors,
  btnsDisabled,
  setBtnsDisabled
}) => {
  const [isStartTimer, setStartTimer] = useState(false);
  const [cashedElapsingTime, setCashedElapsingTime] = useState(null);
  const [isShowDelayMessage, setShowDelayMessage] = useState(null);
  const [isMediaBlocked, setIsMediaBlocked] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const recorderTimeout = useRef(null);
  const btnSetterTimeout = useRef(null);
  const videoPlayer = useRef({});
  const streamRef = useRef(null);
  const videoInput = useRef();
  const audio = useMemo(() => new Audio(Countdown), []);
  const audioRef = useRef(audio);

  const [videoDeviceIds, setVideoDeviceIds] = useState([]);
  const [videoDeviceId, setVideoDeviceId] = useState(null);
  const [currentBlob, setCurrentBlob] = useState(null);
  const [hasEBML, setHasEBML] = useState(true);
  const [isEBMLCalled, setIsEBMLCalled] = useState(false);

  useEffect(() => {
    if (typeof EBML === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.webrtc-experiment.com/EBML.js";
      script.async = true;

      script.onload = () => {
        console.log("EBML is successfully loaded and ready to use!", typeof EBML !== "undefined");
        setHasEBML(typeof EBML !== "undefined");
      };

      script.onerror = () => {
        setHasEBML(false);
        setIsEBMLCalled(true);

        Sentry.captureException("Failed to load EBML.js");
      };

      document.body.appendChild(script);
    } else {
      setIsEBMLCalled(true);
      setHasEBML(true);
    }
  }, []);

  const onMediaError = errorMessage => {
    setIsMediaBlocked(errorMessage);
    console.log({ errorMessage });
  };

  useEffect(() => {
    setIsMediaBlocked(false);
    // Init video device list
    getVideoDeviceIds(devices => {
      setVideoDeviceIds(devices);

      if (!videoDeviceId) {
        setVideoDeviceId(devices?.[0]?.deviceId);
      }
    }, onMediaError);
  }, []);

  const setStatusVideoComplete = (ref, src, blob) => {
    handleRecordVideoHTMLplayer({
      ref,
      volume: 1,
      src
    });

    if (streamRef && streamRef.current) {
      const tracks = streamRef.current.getTracks();

      tracks.forEach(track => {
        track.stop();

        if (process.env.REACT_APP_CLOSE_ALL_STREAMS_AND_TRACKS === "true") streamRef.current.removeTrack(track);
      });
    }

    setIsRecording(false);
    setIsCompleted(true);
    changeVideoBlob(blob);
  };

  useEffect(() => {
    if (videoBlob || videoLink) {
      const blobUrl = videoBlob && URL.createObjectURL(videoBlob);
      setStatusVideoComplete(videoPlayer, blobUrl || videoLink, videoBlob);
    }
  }, []);

  const setWarning = message => {
    setIsRecording(false);
    setIsCompleted(false);
    setWarningMessage(message);
  };

  const uploadRecord = useCallback(async blob => {
    const isBigBlobSize = blob && blob.size > MAX_UPLOAD_VIDEO_SIZE;

    if (isBigBlobSize) {
      setWarning(t("errors.videoTooBig"));
      return;
    }

    const duration = blob && (await getBlobDuration(blob));

    if (duration > maxDuration) {
      setWarning(
        t("errors.videoTooLong", {
          answerTime: formTimeString(maxDuration * 1000)
        })
      );
      return;
    }

    setWarningMessage("");

    const blobUrl = blob && URL.createObjectURL(blob);

    changeVideoBlob(blob);

    if (blob) {
      setStatusVideoComplete(videoPlayer, blobUrl, blob);
    }
  }, []);

  useInterval(
    () => setShowDelayMessage(isShowDelayMessage - 1),
    isShowDelayMessage ? 1000 : null
  );

  const startVideoRecord = (stream, callback) => {
    setShowDelayMessage(3);
    audioRef.current.play();

    streamRef.current = stream;

    recorderTimeout.current = setTimeout(async () => {
      const videoRTCStream = await RecordRTC(stream, { type: "video" });

      handleRecordVideoHTMLplayer({
        ref: videoPlayer,
        muted: true,
        autoplay: true,
        stream,
        src: null,
        videoRTCStream
      });

      changeVideoBlob(null);
      setIsRecording(true);
      setIsCompleted(false);
      clearErrors();

      setShowDelayMessage(false);
      setCashedElapsingTime(maxDuration * 1000);
      setStartTimer(true);

      if (typeof callback === "function") {
        callback(stream);
      }

      btnSetterTimeout.current = setTimeout(() => {
        setBtnsDisabled(state => ({
          ...state,
          btnStart: true,
          btnStop: false
        }));
      }, 3000);
    }, 3000);
  };

  const errorStartVideoRecord = (error, callback) => {
    if (typeof callback === "function") {
      callback(error);
    }
  };

  const startRecord = (successCallback, errorCallback, errorMessage) => {
    if (isMobile) {
      const scrollY =
        videoPlayer.current.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight;

      window.scrollTo({ top: scrollY, behavior: "smooth" });
    }

    setBtnsDisabled(state => ({
      ...state,
      btnStart: true,
      btnStop: true
    }));

    setCashedElapsingTime(null);
    changeVideoBlob(null);
    if (videoPlayer.current && videoPlayer.current.src) {
      videoPlayer.current.src = null;
    }

    captureUserMedia(
      false,
      videoDeviceId,
      undefined,
      stream => startVideoRecord(stream, successCallback),
      error => errorStartVideoRecord(error, errorCallback),
      errorMessage
    );
  };

  const stopRecord = () => {
    try {
      return new Promise(() => {
        setBtnsDisabled(state => ({
          ...state,
          btnStart: false,
          btnStop: false
        }));

        if (videoPlayer.current && videoPlayer.current.videoRTC) {
          videoPlayer.current.videoRTC.stopRecording(async () => {
            if (videoPlayer.current.videoRTC) {
              const recordedBlob = await videoPlayer.current.videoRTC.getBlob();

              if (recordedBlob.size > 0) {
                setCurrentBlob(recordedBlob);
                const successCallback = seekableBlob => {
                  setStartTimer(false);

                  const src = URL.createObjectURL(seekableBlob || recordedBlob);

                  if (recordedBlob) {
                    setStatusVideoComplete(videoPlayer, src, recordedBlob);
                  }
                };

                if (isSafari) {
                  successCallback();
                  return;
                }

                getSeekableBlob(recordedBlob, successCallback);
              }
            } else {
              setStartTimer(false);
              setStatusVideoComplete(videoPlayer, "", null);
            }
          });
        }

        setBtnsDisabled(state => ({
          ...state,
          btnStart: false,
          btnStop: true
        }));
      });
    } catch (e) {
      console.log(`Failed to stop recording. Recorded blob: ${currentBlob}`);
      Sentry.captureException(e?.message || e);
      setCurrentBlob(null);
    }

    return false;
  };

  useEffect(() => {
    if (cashedElapsingTime === 0 || (cashedElapsingTime === 0 && isRecording)) {
      retry(stopRecord, showErrorModal);
    }
  }, [cashedElapsingTime, isRecording]);

  const onFileUpload = e => {
    const result = e.target.files[0];
    uploadRecord(result);
  };

  const handleClickUpload = () => {
    if (isRecording || isShowDelayMessage) {
      stopRecord();
      handleRecordVideoHTMLplayer({
        ref: videoPlayer
      });
      setShowDelayMessage(false);
      clearTimeout(btnSetterTimeout.current);
      clearTimeout(recorderTimeout.current);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    videoInput.current.click();
  };

  return (
    <>
      {isStartTimer && (
        <Timer
          stopRecord={stopRecord}
          setCashedElapsingTime={setCashedElapsingTime}
        />
      )}

      <Col md={{ span: 7 }} xs={{ span: 12 }} className="job__video-player">
        <DesktopPlayer
          isRecording={isRecording}
          cashedElapsingTime={cashedElapsingTime}
          videoPlayerRef={videoPlayer}
          videoInputRef={videoInput}
          onFileUpload={onFileUpload}
          videoBlob={videoBlob}
          videoLink={videoLink}
          videoDevices={videoDeviceIds}
          videoDeviceId={videoDeviceId}
          onSwitchCamera={setVideoDeviceId}
          hasEBML={hasEBML}
          isEBMLCalled={isEBMLCalled}
        />
        {isMediaBlocked && (
          <div className="danger job__video-blocked">{t(isMediaBlocked)}</div>
        )}
        {!isMediaBlocked && videoError && (
          <div className="danger job__video-blocked">{t(videoError)}</div>
        )}
        {isShowDelayMessage && (
          <div className="job__delay">{isShowDelayMessage}</div>
        )}
      </Col>

      <Col md={{ span: 5 }} xs={{ span: 12 }}>
        <p className="job__video-title n-font-large n-font-medium-weight n-grey-100">
          {t("job.preview.titleVideo")}
        </p>
        <p className="job__video-description n-font-medium n-grey-100">
          {t("job.preview.descriptionVideo")}
        </p>
        {warningMessage && <Message message={warningMessage} error />}

        <Buttons
          isRecording={isRecording}
          isCompleted={isCompleted}
          btnsDisabled={btnsDisabled}
          startRecord={startRecord}
          stopRecord={stopRecord}
          isShowDelayMessage={isShowDelayMessage}
          isMediaBlocked={isMediaBlocked}
          setIsMediaBlocked={setIsMediaBlocked}
          onClickUpload={handleClickUpload}
        />
      </Col>

      <ErrorModal
        show={showErrorModal}
        hide={() => setShowErrorModal(false)}
        t={t}
      />
    </>
  );
};

VideoRecording.propTypes = {
  t: PropTypes.func.isRequired,
  videoError: PropTypes.string.isRequired,
  videoBlob: PropTypes.shape.isRequired,
  btnsDisabled: PropTypes.shape.isRequired,
  videoLink: PropTypes.string.isRequired,
  changeVideoBlob: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setBtnsDisabled: PropTypes.func.isRequired
};

export default withTranslation()(VideoRecording);
