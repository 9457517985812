/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";

import InfoTooltip from "components/Common/Tooltip/Info";

import { connect } from "react-redux";
import {
  getExcludedCandidates,
  getHasSelectAllStatus,
  getInterviewersProgress,
  getSelectedCandidates,
} from "store/modules/jobs/selectors";
import { Accept, Reject, Share, Delete, Download } from "./svgIcons";
import CandidateActionErrorModal from "./CandidateActionErrorModal";

const CandidateActions = ({
  t,
  handleUpdate,
  handleDownload,
  handleShowcase,
  handleCancel,
  setModal,
  loading,
  selected,
  selectAll,
  excludedCandidates,
  totalCount,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const hasSelectedUsersInInvited = useMemo(() => {
    const invitedCount =
      selected.filter((a) => a.status === "Invited")?.length ?? 0;
    return invitedCount > 0;
  }, [selected]);
  const hasSelectedOtherColumnsWithInvited = useMemo(() => {
    const invitedCount =
      selected.filter((a) => a.status === "Invited")?.length ?? 0;
    const nonInvitedCount =
      selected.filter((a) => a.status !== "Invited")?.length ?? 0;

    return invitedCount > 0 && nonInvitedCount > 0;
  }, [selected]);

  const handleAccept = () => {
    if (hasSelectedUsersInInvited) {
      return setOpenModal(true);
    }
    handleUpdate("accept");
  };
  const handleReject = () => {
    if (hasSelectedUsersInInvited) {
      return setOpenModal(true);
    }
    handleUpdate("reject");
  };
  const handleDelete = () => {
    if (hasSelectedOtherColumnsWithInvited) {
      return setOpenModal(true);
    }

    setModal({
      type: "deleteInterviewConfirmation",
      handleConfirm: () => handleUpdate("delete"),
    });
  };

  const selectedCount = useMemo(() => {
    let acceptedCount =
      selected.filter((a) => a.status === "Accepted")?.length ?? 0;
    let rejectedCount =
      selected.filter((a) => a.status === "Rejected")?.length ?? 0;
    let reviewCount =
      selected.filter((a) => a.status === "Received")?.length ?? 0;
    let invitedCount =
      selected.filter((a) => a.status === "Invited")?.length ?? 0;

    const excludedAccepted = excludedCandidates.filter(
      (a) => a.status === "Accepted"
    )?.length;
    const excludedRejected = excludedCandidates.filter(
      (a) => a.status === "Rejected"
    )?.length;
    const excludedReview = excludedCandidates.filter(
      (a) => a.status === "Received"
    )?.length;
    const excludedInvited = excludedCandidates.filter(
      (a) => a.status === "Invited"
    )?.length;

    if (selectAll.accepted) {
      acceptedCount = parseInt(totalCount.accepted) - excludedAccepted;
    }
    if (selectAll.rejected) {
      rejectedCount = parseInt(totalCount.rejected) - excludedRejected;
    }
    if (selectAll.received) {
      reviewCount = parseInt(totalCount.to_review) - excludedReview;
    }

    if (selectAll.invited) {
      invitedCount = parseInt(totalCount.invited) - excludedInvited;
    }

    return acceptedCount + rejectedCount + reviewCount + invitedCount;
  }, [selected, selectAll, excludedCandidates, totalCount]);

  return (
    <Row className="row-search">
      {openModal && (
        <CandidateActionErrorModal
          handleCloseModal={() => setOpenModal(false)}
        />
      )}
      <label
        htmlFor="name-search"
        className={`n-font-small label__search ${
          loading ? "loader-search" : ""
        }`}
      >
        {loading ? (
          <BeatLoader color="#5a2af1" loading size="10" />
        ) : (
          t("job.candidate.selected", { selected: selectedCount })
        )}
      </label>
      <button
        type="button"
        className="button__cancel n-button__small n-button__small-border n-border-grey-100 n-font-small n-bg-white n-black-80"
        onClick={handleCancel}
      >
        {t("button.cancel")}
      </button>
      <div className="search-container">
        <Row>
          <button
            type="button"
            className="button__without-styles bulk__button"
            onClick={handleAccept}
          >
            <Accept />
            {t("button.accept")}
          </button>
          <InfoTooltip
            position="bottom"
            message={t("job.overview.acceptTooltip")}
          />

          <button
            type="button"
            className="button__without-styles bulk__button"
            onClick={handleReject}
          >
            <Reject />
            {t("button.reject")}
          </button>
          <InfoTooltip
            position="bottom"
            message={t("job.overview.acceptTooltip")}
          />

          <button
            type="button"
            className="button__without-styles bulk__button"
            onClick={() => {
              if (hasSelectedUsersInInvited) {
                return setOpenModal(true);
              }

              handleShowcase();
            }}
          >
            <Share />
            {t("button.share")}
          </button>
          <button
            type="button"
            className="button__without-styles bulk__button"
            onClick={handleDelete}
          >
            <Delete />
            {t("button.delete")}
          </button>
          <button
            type="button"
            className="button__without-styles bulk__button"
            onClick={() => {
              handleDownload();
            }}
          >
            <Download />
            {t("button.download")}
          </button>
        </Row>
      </div>
    </Row>
  );
};

CandidateActions.propTypes = {
  t: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleShowcase: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  selected: getSelectedCandidates(state),
  selectAll: getHasSelectAllStatus(state),
  excludedCandidates: getExcludedCandidates(state),
  totalCount: getInterviewersProgress(state),
});
export default connect(mapStateToProps)(withTranslation()(CandidateActions));
