import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import PageWrapper from "hoc/PageWrapper";
import NotFoundLogo from "assets/images/auth/404-logo.svg";

import "./styles.scss";
import { Link } from "react-router-dom";

const InvalidShortLink = ({ t }) => (
  <PageWrapper
    prefix="complete"
    header={<Header />}
    footer={<Footer isPublic />}
  >
    <div className="container invalid-link-container">
      <div className="interview-complete not-found-wrapper">
        <img src={NotFoundLogo} alt="not found" />

        <div className="interview-complete__text-box">
          <h3 className="interview-complete__title interview-invalid__title">
            {t("notFound.404.title")}
          </h3>

          <p className="interview-complete__text interview-invalid__text">
            {t("notFound.404.description")}
          </p>
        </div>
      </div>

      <div className="interview-invalid__wrapper-actions">
        <div className="action-container">
          <span className="title">{t("notFound.404.candidate.title")}</span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="description">{t("notFound.404.candidate.description.0")}</span>
            <span className="description">{t("notFound.404.candidate.description.1")}</span>
          </div>
        </div>

        <div className="action-container">
          <span className="title">{t("notFound.404.recruiter.title")}</span>
          <div className="action-items">
            <Link to="/sign-in" className="action-items-link">
              {t("notFound.404.recruiter.login")}
            </Link>

            <a href="https://www.willo.video/book-a-demo" target="_blank" rel="noopener noreferrer" className="action-items-link">
              {t("notFound.404.recruiter.scheduleADemo")}
            </a>

            <Link to="/sign-up" className="action-items-link">
              {t("notFound.404.recruiter.signup")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </PageWrapper>
);

InvalidShortLink.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(InvalidShortLink);
