/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";
import Steps from "components/Steps/Questions";
import VideoRecordingDesktop from "components/Video/Recording/Desktop";
import VideoRecordingMobile from "components/Video/Recording/Mobile";
import Notification from "components/Notifications/Notification";
import { isAndroid, isIOS } from "react-device-detect";
import PageWrapper from "hoc/PageWrapper";
import withDocumentTitle from "hoc/withDocumentTitle";
import { withTranslation } from "react-i18next";

import "./styles.scss";
import useDetectMute from "hooks/useDetectMute";
import { connect } from "react-redux";
import { getAudioDevice, getVideoDevice } from "store/modules/сandidates/selectors";

const Test = ({
  t,
  match: {
    params: { userId = "" }
  },
  location: { state: { jobId = "", questionId = "" } = {} },
  questionUploadKey,
  jobInfoCompany = {},
  questionsForCandidate = [],
  history,
  setModal,
  savedAnswers,
  saveVideoExtension,
  saveCurrentQuestion,
  savePreviousQuestion,
  clearPreviousQuestion,
  clearUploadInfo,
  clearErrors,
  currentCandidateId,
  audioDeviceId,
  videoDeviceId
}) => {
  const handleClearErrors = () => clearErrors({ userId });
  const [isRecording, setIsRecording] = useState(false);
  const { fullVideoHasAudio, hasAudio, displayAudioAnimation,
    audioContextSupported } = useDetectMute(isRecording, undefined, audioDeviceId);

  useEffect(() => () => handleClearErrors(), [questionId]);

  const memorizedCompanyInfo = useMemo(() => jobInfoCompany, [jobInfoCompany]);

  const isLastQuestion =
    userId === currentCandidateId &&
    savedAnswers.length >= questionsForCandidate.length &&
    savedAnswers.every(item => item?.is_finished);

  const questionNumber = questionsForCandidate.findIndex(
    item => item.key === questionId
  );

  const currentQuestion = questionsForCandidate.find(
    item => item.key === questionId
  );

  const [cashedElapsingTime, setCashedElapsingTime] = useState(null);
  const [btnsDisabled, setBtnsDisabled] = useState({});
  const [isStartTimer, setStartTimer] = useState(false);
  const [, setUploadProgress] = useState(0);
  const recorderTimeout = useRef(null);
  const [isShowDelayMessage, setShowDelayMessage] = useState(null);
  const btnSetterTimeout = useRef(null);

  const isMediaRecorderSupported = "MediaRecorder" in window;

  const handleClick = () => history.goBack();

  const isMobilePlayer = !["file"]?.includes(currentQuestion?.answer_type) && (isIOS || (isAndroid && !isMediaRecorderSupported));

  return (
    <Fragment>
      <Notification
        type="info"
        message={t("notifications.test_interview")}
        withCloseIcon={false}
        style={{ justifyContent: "center" }}
        onClick={handleClick}
      />
      <PageWrapper
        header={<HeaderCandidateJob company={memorizedCompanyInfo} />}
        footer={(
          <Footer company={memorizedCompanyInfo} isPublic />
        )}
      >
        <Container bsPrefix="container question-create test-page">
          <Row>
            <Col md={12}>
              <Steps
                jobId={jobId}
                userId={userId}
                history={history}
                setModal={setModal}
                questions={questionsForCandidate}
                savePreviousQuestion={savePreviousQuestion}
                clearPreviousQuestion={clearPreviousQuestion}
                currentStepActive={questionId}
                currentAction="test"
                isRecording={isRecording}
                saveCurrentQuestion={saveCurrentQuestion}
                setCashedElapsingTime={setCashedElapsingTime}
              />
            </Col>
          </Row>
          <Row bsPrefix="row video-recording">
            {isMobilePlayer ? (
              <VideoRecordingMobile
                jobId={jobId}
                userId={userId}
                isLastQuestion={isLastQuestion}
                questionUploadKey={questionUploadKey}
                questionNumber={questionNumber}
                question={currentQuestion}
                history={history}
                memorizedCompanyInfo={memorizedCompanyInfo}
                saveVideoExtension={saveVideoExtension}
                saveOnAmazonS3={false}
                saveCurrentQuestion={saveCurrentQuestion}
                savedAnswers={savedAnswers}
                clearUploadInfo={clearUploadInfo}
                clearErrors={handleClearErrors}
                isRecording={isRecording}
                setIsRecording={setIsRecording}
                setCashedElapsingTime={setCashedElapsingTime}
                cashedElapsingTime={cashedElapsingTime}
                setUploadProgress={setUploadProgress}
                isTestMode
                hasAudio={hasAudio}
                fullVideoHasAudio={fullVideoHasAudio}
                displayAudioAnimation={displayAudioAnimation}
                audioContextSupported={audioContextSupported}
                updateFirebaseData={() => {}}
                hasUploadedMedia={false}
                setBtnsDisabled={setBtnsDisabled}
              />
              ) : (
                <VideoRecordingDesktop
                  videoDeviceId={videoDeviceId}
                  audioDeviceId={audioDeviceId}
                  jobId={jobId}
                  userId={userId}
                  isLastQuestion={isLastQuestion}
                  questionUploadKey={questionUploadKey}
                  questionNumber={questionNumber}
                  question={currentQuestion}
                  history={history}
                  memorizedCompanyInfo={memorizedCompanyInfo}
                  saveVideoExtension={saveVideoExtension}
                  saveOnAmazonS3={false}
                  saveCurrentQuestion={saveCurrentQuestion}
                  savedAnswers={savedAnswers}
                  clearErrors={handleClearErrors}
                  isRecording={isRecording}
                  setIsRecording={setIsRecording}
                  setCashedElapsingTime={setCashedElapsingTime}
                  cashedElapsingTime={cashedElapsingTime}
                  isStartTimer={isStartTimer}
                  setStartTimer={setStartTimer}
                  btnsDisabled={btnsDisabled}
                  setBtnsDisabled={setBtnsDisabled}
                  setUploadProgress={setUploadProgress}
                  recorderTimeout={recorderTimeout}
                  isShowDelayMessage={isShowDelayMessage}
                  setShowDelayMessage={setShowDelayMessage}
                  btnSetterTimeout={btnSetterTimeout}
                  onlyAudio={false}
                  isTestMode
                  hasAudio={hasAudio}
                  fullVideoHasAudio={fullVideoHasAudio}
                  displayAudioAnimation={displayAudioAnimation}
                  audioContextSupported={audioContextSupported}
                  updateFirebaseData={() => {}}
                  hasUploadedMedia={false}
                />
              )}
          </Row>
        </Container>
      </PageWrapper>
    </Fragment>
  );
};

Test.defaultProps = {
  t: undefined,
  match: undefined,
  location: undefined,
  history: undefined,
  jobInfoCompany: {},
  jobInfo: {},
  questionUploadKey: undefined,
  questionCurrentCashed: {},
  questionsForCandidate: {},
  setModal: undefined,
  savePreviousQuestion: undefined,
  saveCurrentQuestion: undefined,
  saveVideoExtension: undefined,
  savedAnswers: [],
  clearPreviousQuestion: undefined,
  clearUploadInfo: undefined,
  clearErrors: undefined
};

Test.propTypes = {
  t: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      questionId: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      jobId: PropTypes.string,
      questionId: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    length: PropTypes.number,
    goBack: PropTypes.func
  }),
  jobInfoCompany: PropTypes.shape({
    layout_color: PropTypes.string,
    logo: PropTypes.string
  }),
  jobInfo: PropTypes.shape({
    key: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string
    }))
  }),
  questionUploadKey: PropTypes.string,
  questionsForCandidate: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ),
  questionCurrentCashed: PropTypes.shape({
    blob: PropTypes.object,
    userId: PropTypes.string,
    questionId: PropTypes.string,
    questionNumber: PropTypes.number
  }),
  setModal: PropTypes.func,
  savePreviousQuestion: PropTypes.func,
  saveCurrentQuestion: PropTypes.func,
  saveVideoExtension: PropTypes.func,
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  clearPreviousQuestion: PropTypes.func,
  clearUploadInfo: PropTypes.func,
  clearErrors: PropTypes.func,
  currentCandidateId: PropTypes.string.isRequired,
  audioDeviceId: PropTypes.string.isRequired,
  videoDeviceId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  audioDeviceId: getAudioDevice(state),
  videoDeviceId: getVideoDevice(state)
});

export default connect(mapStateToProps)(withTranslation()(withDocumentTitle(Test)));
