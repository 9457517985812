/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import Header from "containers/Common/HeaderContainer";
import PageWrapper from "hoc/PageWrapper";
import withDocumentTitle from "hoc/withDocumentTitle";

import animationData from "assets/images/candidate/error.json";

import "./styles.scss";
import PrivateChat from "components/PrivateChat";
import { getToken } from "store/modules/auth/selectors";
import PrivateChatCandidate from "components/PrivateChatCandidate";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const InvalidShowCaseLink = ({
  t
}) => {
  const isAuthenticated = Boolean(getToken);

  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
    }
  };

  return (
    <PageWrapper header={<Header />}>
      <div className="notaccess__wrapper">
        <div className="notaccess">
          <Lottie options={defaultOptions} height={200} width={300} />

          <div className="notaccess__text-box">
            <h3 className="notaccess__title">
              {t("modals.showCase.invalid.title")}
            </h3>

            <p className="notaccess__text">
              {t("modals.showCase.invalid.description")}
            </p>
            <div className="button__group_center notaccess__buttons">
              <p className="n-font-small n-text-center trial-completed__support">
                {t("trialPeriod.prefer")}
                <button
                  type="button"
                  onClick={openBeacon}
                  className="n-font-small trial-completed__support"
                >
                  {t("trialPeriod.team")}
                </button>
              </p>
            </div>
          </div>
        </div>
        { !isAuthenticated
          ? <PrivateChatCandidate /> : <PrivateChat /> }
      </div>
    </PageWrapper>
    );
};

InvalidShowCaseLink.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withTranslation()(withDocumentTitle(InvalidShowCaseLink));
