/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Question } from "mixins/svgIcons";

import { File } from "../svgIcons";

const Item = ({
  t,
  questionNumber,
  questionText,
  answer: {
    friendly_name: friendlyName,
    media_extension: mediaExtension
  },
  isCurrent,
  setCurrentQuestion,
  buttonColor,
  status
}) => (
  <div
    role="presentation"
    className={`preview__list-item ${isCurrent && "current"}`}
    onClick={setCurrentQuestion}
    style={isCurrent && buttonColor ? { background: `${buttonColor}20` } : null}
  >
    {
      status === "unsafe"
        ? (
          <div className="preview__list-item-type file">
            <p className="unsafe">
              {t("unsafeNotice.malicious_file")}
            </p>
          </div>
        ) : (
          <div className="preview__list-item-type file">
            <File />
            <p className="preview__list-item-type-name n-grey n-font-medium">
              {`${friendlyName}.${mediaExtension}`}
            </p>
          </div>
        )}
    <div className="preview__list-item-info">
      <p className="preview__list-item-info-subtitle n-grey-100 n-font-small">
        <Question svgIconClass="preview__list-item-info-icon" />
        <span>{`${t("candidate.common.question")} ${questionNumber}`}</span>
      </p>

      <p
        className="preview__list-item-info-title n-grey-300 n-font-small"
        title={questionText}
      >
        {questionText}
      </p>
    </div>
  </div>
);

Item.defaultProps = {
  buttonColor: undefined
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    friendly_name: PropTypes.string,
    media_extension: PropTypes.string
  }).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  status: PropTypes.string.isRequired
};

export default withTranslation()(Item);
